import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import PaginationClassic from "../../components/PaginationClassic";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import ThemeTable from "../../partials/themes/ThemeTable";
import BottomAppBar from "../../partials/ui/BottomAppBar";

export default function AllThemes() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
    console.log([...selectedItems]);
  };

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth?.user;

  const [themes, setThemes] = useState(Array(20).fill(""));
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [page, onChange] = useState(1);

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  // Render Stories
  const getThemes = async () => {
    try {
      setLoading(true);
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        console.log("Search String: " + debouncedSearchString);
        params = { search: debouncedSearchString };
      }

      params = { ...params, offset, limit };

      const res = await axiosPrivate.get(endpoints.THEMES_URL, {
        signal: controller.signal,
        params: params,
      });

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages);
      }

      setThemes(res?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getThemes();
  }, []);

  useEffect(() => {
    getThemes();
  }, [debouncedSearchString, offset, limit]);

  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          title="All Themes"
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <h1 className="text-2xl md:text-3xl text-black dark:text-white font-bold mb-8">
              {t("All Themes")} ✨
            </h1>
            <div className="sm:flex sm:justify-between sm:items-center sm:mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2">
                <Link
                  to="/allthemes/create"
                  className="btn group bg-emerald-500 hover:bg-emerald-600 dark:bg-emerald-600 dark:hover:bg-emerald-500 text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2 stroke-white-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                  {t("Create")}
                </Link>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between gap-2">
                {/* Pagination */}
                <PaginationClassic
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  setLimit={setLimit}
                  result={themes.length}
                  isLoading={isLoading}
                />
              </div>
            </div>

            {/* Table */}
            <ThemeTable
              themes={themes}
              selectedItems={selectedItems}
              handleSelectedItems={handleSelectedItems}
              isLoading={isLoading}
            />
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}
