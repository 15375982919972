import { axiosPublic } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { useTranslation } from "react-i18next";

const useAxiosPublic = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const { i18n } = useTranslation();

  useEffect(() => {
    const responseIntercept = axiosPublic.interceptors.response.use(
      (config) => {
        if (i18n.language) {
          config.headers["Accept-Language"] = i18n.language;
        }
        return config;
      },
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPublic.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosPublic;
};

export default useAxiosPublic;
