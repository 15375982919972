import React, { useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "../../partials/auths/AuthHeader";

// the hook
import { useTranslation } from "react-i18next";
import useAxiosPublic from "../../hooks/useAxiosPublic";
import endpoints from "../../api/endpoints";

function AIPracticeHome() {
  // i18n
  const { t, i18n } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPublic = useAxiosPublic();
  const controller = new AbortController();

  const [searchParams, setSearchParams] = useSearchParams();

  const submitHandler = async (values, { setErrors, resetForm }) => {
    try {
      setIsFetching(true);
      const res = await axiosPublic.post(endpoints.AI_URL, values, {
        signal: controller.signal,
      });

      navigate(`/practice/${res.data.id}`);
    } catch (error) {
      console.error(error);
      // Toast
      toast.error("Error Occured!");
    } finally {
      setIsFetching(false);
    }
  };

  const share = async () => {
    const url = `https://app.practtice.com/practice/ai`;
    try {
      await navigator.share({ url });
    } catch {
      navigator.clipboard.writeText(url);
    }

    toast.success(`${t("Copied to clipboard")}!`);
  };

  const formik = useFormik({
    initialValues: {
      topic: searchParams.get("topic") || "",
      numberOfQuestions: 4,
      language: "auto",
    },
    validationSchema: Yup.object({
      topic: Yup.string().trim().required(t("This field is required")),
      numberOfQuestions: Yup.number()
        .min(1, `${t("Must be at least")} 1`)
        .max(5, `${t("Must be not greater than")} 5`)
        .required(t("This field is required")),
      language: Yup.string().trim().required(t("This field is required")),
    }),
    onSubmit: submitHandler,
  });

  return (
    <>
      <Header title={t("Practtice AI Quiz Generator")} />

      <main>
        <div className="relative pt-20 ">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8">
            <div className="relative bg-gray-100 border border-gray-200 dark:border-gray-600 dark:bg-gray-800 rounded-2xl px-6 py-10 overflow-hidden shadow-xl sm:px-12 sm:py-20">
              <div className="relative space-y-4">
                <div className="text-2xl font-extrabold text-black dark:text-white tracking-tight">
                  {t("Practtice AI Quiz Generator")}
                </div>
                <form className="space-y-6" onSubmit={formik.handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-black dark:text-white"
                    >
                      {t("Topic")}
                    </label>
                    <div className="mt-1">
                      <input
                        id="topic"
                        name="topic"
                        value={formik.values.topic}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={
                          navigator.language.includes("zh-Hant")
                            ? "香港常識問答"
                            : "Hong Kong Q&A"
                        }
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-black transition-alldark:border-gray-600 dark:text-white"
                      />
                      <p className="text-amber-400 text-sm">
                        {formik.errors.topic && formik.touched.topic
                          ? `${t("Error")}: ${formik.errors.topic}`
                          : null}
                      </p>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium text-black dark:text-white"
                    >
                      {t("No. of Questions")}
                    </label>
                    <select
                      id="numberOfQuestions"
                      name="numberOfQuestions"
                      value={formik.values.numberOfQuestions}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-black transition-alldark:border-gray-600 dark:text-white"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                    <p className="text-amber-400 text-sm">
                      {formik.errors.numberOfQuestions &&
                      formik.touched.numberOfQuestions
                        ? `${t("Error")}: ${formik.errors.numberOfQuestions}`
                        : null}
                    </p>
                  </div>

                  <div>
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium text-black dark:text-white"
                    >
                      {t("Output Language")}
                    </label>
                    <select
                      id="language"
                      name="language"
                      value={formik.values.language}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-black transition-alldark:border-gray-600 dark:text-white"
                    >
                      <option value="auto">{t("Auto")}</option>
                      <option value="English">English</option>
                      <option value="繁體中文">繁體中文</option>
                    </select>
                    <p className="text-amber-400 text-sm">
                      {formik.errors.language && formik.touched.language
                        ? `${t("Error")}: ${formik.errors.language}`
                        : null}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    {/* Start Btn */}
                    {isFetching ? (
                      <span className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-black bg-gray-100 cursor-wait">
                        {t("Generate and Start Practice")}...
                      </span>
                    ) : (
                      <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-black bg-white hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white"
                      >
                        {t("Generate and Start Practice")}
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => share()}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-black bg-white hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white"
                    >
                      {t("Share")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AIPracticeHome;
