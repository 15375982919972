import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { ReactComponent as Logo } from "../../images/logo/LogoDark.svg";
import { useTranslation } from "react-i18next";
const QuizSwiper = ({ isLoading, quizzes }) => {
  const { t } = useTranslation();

  const renderBadge = (badge) => {
    switch (badge) {
      case "purchased":
        return t("Purchased");
      default:
        return "";
    }
  };

  return (
    <Swiper
      slidesPerView="auto"
      spaceBetween={15}
      loopedSlides={12}
      className="max-w-fit z-0 mx-0"
      modules={[Navigation, Autoplay]}
      navigation={{
        enabled: true,
        disabledClass: "hidden",
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      {!isLoading && quizzes?.length === 0 && (
        <div className="py-4 w-full text-center text-gray-500 dark:text-gray-400">
          {t("Empty")}
        </div>
      )}
      {isLoading &&
        Array.from(Array(10).keys()).map((idx) => (
          <SwiperSlide className="max-w-fit">
            <div className="animate-pulse p-4 h-48 group block aspect-square rounded-lg bg-gray-600 dark:bg-gray-800 overflow-hidden"></div>
            <div className="mt-1 h-6 w-36 rounded-full animate-pulse bg-gray-400 dark:bg-gray-600"></div>
            <div className="mt-1 h-5 w-20 rounded-full animate-pulse bg-gray-300 dark:bg-gray-500"></div>
          </SwiperSlide>
        ))}
      {!isLoading &&
        quizzes?.map((item, idx) => (
          <SwiperSlide key={idx} className="max-w-fit">
            <Link to={`/practice/${item.id}`}>
              {item.coverImg ? (
                <div className="relative">
                  <img
                    src={item.coverImg}
                    alt={item.title}
                    className="h-48 aspect-square rounded-lg object-cover"
                  />
                  {item.badge && (
                    <div className="absolute top-0 w-full bg-emerald-700 text-white font-medium text-center py-1 text-sm">
                      {renderBadge(item.badge)}
                    </div>
                  )}
                </div>
              ) : (
                <div className="p-4 h-48 group aspect-square rounded-lg bg-gradient-to-r from-indigo-500 to-purple-500 overflow-hidden flex items-center justify-center">
                  {/* Logo */}
                  {/* <Logo className="w-10 h-10" /> */}
                  <div className="text-white font-bold text-3xl">
                    {item.title}
                  </div>
                </div>
              )}
              <div className="mt-1 w-48 line-clamp-2 h5 text-black dark:text-white">
                {item.title}
              </div>
            </Link>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default QuizSwiper;
