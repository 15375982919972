import Register from "./pages/auth/Signup";
import MFA from "./pages/auth/MFA";
import Login from "./pages/auth/Signin";
import Signout from "./pages/auth/Signout";
import ResetPassword from "./pages/auth/ResetPassword";
import PageNotFound from "./pages/error/PageNotFound";
import Verify from "./pages/auth/Verify";
import Reset from "./pages/auth/Reset";

// Home
import Home from "./pages/home/Home";

// Search
import SearchHome from "./pages/search/SearchHome";

// Themes
import ThemeHome from "./pages/themes/ThemeHome";

// Redeem
import RedeemHome from "./pages/redeem/RedeemHome";

// Quizzes
import AllQuizzes from "./pages/quizzes/AllQuizzes";
import QuizHome from "./pages/quizzes/QuizHome";
import QuizDetail from "./pages/quizzes/QuizDetail";
import QuizMonetize from "./pages/quizzes/QuizMonetize";
import NewQuiz from "./pages/quizzes/NewQuiz";
import EditQuiz from "./pages/quizzes/EditQuiz";

// Library
import LibraryHome from "./pages/library/LibraryHome";
import FollowList from "./pages/library/FollowList";

// Profile
import ProfileHome from "./pages/profile/ProfileHome";
import ProfileFollowList from "./pages/profile/FollowList";

// Practice
import PracticeHome from "./pages/practice/PracticeHome";
import PracticeQuestion from "./pages/practice/PracticeQuestion";
import PracticeWrongQuestion from "./pages/practice/PracticeWrongQuestion";
import PracticeResults from "./pages/practice/PracticeResults";

// AI
import AIPracticeHome from "./pages/ai/AIPracticeHome";

// Users
import AllUsers from "./pages/users/AllUsers";
import EditUser from "./pages/users/EditUser";

// Coupons
import AllCoupons from "./pages/coupons/AllCoupons";

// Settings
import Settings from "./pages/settings/Settings";

// Banners
import AllBanners from "./pages/banners/AllBanners";
import CreateBanner from "./pages/banners/CreateBanner";
import EditBanner from "./pages/banners/EditBanner";

// Themes
import AllThemes from "./pages/themes/AllThemes";
import CreateTheme from "./pages/themes/CreateTheme";
import EditTheme from "./pages/themes/EditTheme";

// Pay
import Pay from "./pages/pay/Pay";

// Loading
import Loader from "./partials/Loader";

// For Reference Only
import Layout from "./components/Layout";
import Unauthorized from "./components/Unauthorized";
import LinkPage from "./components/LinkPage";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { Routes, Route, Navigate } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="signin" element={<Login />} />
        <Route path="signup" element={<Register />} />
        <Route path="mfa/:token" element={<MFA />} />
        <Route path="signout" element={<Signout />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="reset/:resetToken" element={<Reset />} />
        <Route path="linkpage" element={<LinkPage />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="verify/:verify_token" element={<Verify />} />
        <Route path="loading" element={<Loader />} />

        {/* Home Page */}
        <Route path="/" element={<Home />} />

        {/* Search Page */}
        <Route path="/search" element={<SearchHome />} />

        {/* Theme Home */}
        <Route path="/themes/:name" element={<ThemeHome />} />

        {/* Redeem Home */}
        <Route path="/redeem" element={<RedeemHome />} />
        <Route path="/redeem/:code" element={<RedeemHome />} />

        {/* Profile Page */}
        <Route path="/profile/:id" element={<ProfileHome />} />
        <Route path="/profile/:id/:state" element={<ProfileFollowList />} />

        {/* Practice Page */}
        <Route path="/practice/ai" element={<AIPracticeHome />} />
        <Route
          path="/practice/redo/:quizId/:setId/"
          element={<PracticeWrongQuestion />}
        />
        <Route path="/practice" element={<PracticeHome />} />
        <Route path="/practice/:quizId" element={<PracticeHome />} />
        <Route path="/practice/:quizId/:setId" element={<PracticeQuestion />} />
        <Route
          path="/practice/:quizId/:setId/results"
          element={<PracticeResults />}
        />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles="user" />}>
            <Route path="/" element={<Navigate replace to="/quizzes" />} />

            {/* Quiz Page */}
            <Route path="/quizzes" element={<QuizHome />} />
            <Route path="/quizzes/:id" element={<QuizDetail />} />
            <Route path="/quizzes/:id/monetize" element={<QuizMonetize />} />
            <Route path="/quizzes/:id/:setId/new" element={<NewQuiz />} />
            <Route
              path="/quizzes/:id/:setId/edit/:questionId"
              element={<EditQuiz />}
            />

            {/* Self Profile */}
            <Route path="/library" element={<LibraryHome />} />
            <Route path="/library/:state" element={<FollowList />} />

            {/* Settings */}
            <Route path="/settings/" element={<Settings />} />

            {/* Pay */}
            <Route path="/pay" element={<Pay />} />
          </Route>

          <Route element={<RequireAuth allowedRoles="admin" />}>
            {/* Users */}
            <Route path="/allusers" element={<AllUsers />} />
            <Route
              path="/allusers/:id/edit"
              element={<EditUser fallback="/allusers" />}
            />

            {/* Quizzes */}
            <Route path="/allquizzes" element={<AllQuizzes />} />

            {/* Coupons */}
            <Route path="/allcoupons" element={<AllCoupons />} />

            {/* Banners */}
            <Route path="/allbanners" element={<AllBanners />} />
            <Route
              path="/allbanners/create"
              element={<CreateBanner fallback="/allbanners" />}
            />
            <Route
              path="/allbanners/:id/edit"
              element={<EditBanner fallback="/allbanners" />}
            />

            {/* Themes */}
            <Route path="/allthemes" element={<AllThemes />} />
            <Route
              path="/allthemes/create"
              element={<CreateTheme fallback="/allthemes" />}
            />
            <Route
              path="/allthemes/:id/edit"
              element={<EditTheme fallback="/allthemes" />}
            />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
