import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "../../images/logo/Logo.svg";
import NotFoundImage from "../../images/404-illustration.svg";
import { useTranslation } from "react-i18next";

function PageNotFound(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      {/* Header */}
      <div>
        <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
          {/* Logo */}
          <Link className="block" to="/">
            <Logo className="h-8 w-8" />
          </Link>
        </div>
      </div>

      {/* Content area */}
      <div className="relative flex flex-col overflow-y-auto overflow-x-hidden bg-white dark:bg-black transition-alltext-black dark:text-white">
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto">
            <div className="max-w-3xl m-auto mt-16">
              <div className="text-center px-4">
                <div className="inline-flex mb-8 text-9xl font-extrabold">
                  404
                </div>
                <div className="mb-6">{t("Page not found")}</div>
                <button
                  onClick={() => navigate(props?.backPath || "/")}
                  className="btn px-10 py-2 bg-emerald-500 hover:bg-emerald-600 dark:bg-emerald-600 dark:hover:bg-emerald-700 text-white"
                >
                  {t("Back to home")}
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PageNotFound;
