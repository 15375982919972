import React from "react";
import { useTranslation } from "react-i18next";

import QuizTable from "../../partials/quizzes/QuizTable";

const QuizTab = ({ quizzes, isLoading, searchString }) => {
  const { t } = useTranslation();

  return (
    <div className="px-4">
      {/* Table */}
      <QuizTable quizzes={quizzes} isLoading={isLoading} />

      {!isLoading && quizzes.length === 0 && (
        <div className="text-center py-10">
          <h3 className="mt-2 text-xl font-medium text-black dark:text-white">
            {searchString ? t("Not found") : t("No quiz")}
          </h3>
        </div>
      )}
    </div>
  );
};

export default QuizTab;
