/* eslint-disable no-undef */
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BottomAppBar from "../../partials/ui/BottomAppBar";
import { Link } from "react-router-dom";

// the hook
import { useTranslation } from "react-i18next";
import SignInComponent from "../../partials/auths/SignInComponent";

function Signin() {
  // i18n
  const { t } = useTranslation();

  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header title="Sign In" />

        <main>
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 max-w-md mx-auto space-y-10 text-black dark:text-white">
            <SignInComponent />
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}

export default Signin;
