/* eslint-disable import/no-anonymous-default-export */
export default {
  // Auth
  LOGIN_URL: "/auth/login",
  GOOGLE_SSO_AUTH: "auth/sso/google",
  REGISTER_URL: "/auth/register",
  REFRESH_URL: "/auth/refresh",
  VERIFY_URL: "/auth/verify",
  RESENTVERIFY_URL: "/auth/resendVerify",
  FORGOTPW_URL: "/auth/forgot",
  RESETPW_URL: "/auth/reset",
  VERIFY_RESET_TOKEN_URL: "/auth/reset/verify",
  ERROR_404_URL: "/404",

  // Profile
  PROFILE_URL: "/profile",
  SELF_FOLLOWERS: "/profile/followers",
  SELF_FOLLOWING: "/profile/following",
  SELF_PURCHASED_QUIZZES: "/profile/purchased",
  SELF_BOOKMARKED_QUIZZES: "/profile/bookmarks",
  SELF_QUIZZES: "/profile/quizzes",

  CHANGEPW_URL: "/profile/changePassword",
  SENDEMAILVERIFICATION_URL: "/profile/emailVerification",

  // Quizs
  QUESTIONSETS_URL: "/quizzes",
  QUESTIONSETS_TRASH_URL: "/quizzes/movetotrash",

  // Questions
  QUESTIONS_URL: "/questions",

  // Practice
  PRACTICE_URL: "/practice",

  // Upload
  UPLOAD_AVATAR: "/upload/avatar",
  UPLOAD_QUESTIONS: "/upload/questions",
  UPLOAD_IMAGE: "/upload/image",
  UPLOAD_AUDIO: "/upload/audio",
  UPLOAD_IMAGE_S3: "/upload/aws/image",

  // Plan
  PLAN_URL: "/plans",

  // Region
  REGION_URL: "/countries",

  // Languages
  LANGS_URL: "/languages",

  // Feedbacks
  FEEDBACKS_URL: "/feedbacks",

  // Products:
  PRODUCTS_URL: "/products",

  // AP Typo
  AP_TYPO_URL: "/autopilot/typo",

  // W2V
  W2V_SEO: "/seo",
  W2V_SIMILAR: "/similar",

  // Search User
  SEARCH_GLOBAL_URL: "/search/global",
  SEARCH_QUIZZES_URL: "/search/quizzes",
  SEARCH_USER_URL: "/search/users",

  // Users
  USERS_URL: "/users",

  // AI
  AI_URL: "/ai",

  // Explore
  EXPLORE_URL: "/explore",

  // Banners
  BANNERS_URL: "/banners",

  // Banners
  THEMES_URL: "/themes",

  // Analytics
  ANALYTICS_URL: "/analytics",

  // TRENDING_URL
  TRENDING_URL: "/recommendations/trending",

  // COUPONS_URL
  COUPONS_URL: "/coupons",

  // Redeem
  REDEEM_URL: "/coupons/redeem",
};
