import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPublic from "../../hooks/useAxiosPublic";

const QuestionMain = ({
  quiz,
  question,
  userAnswers,
  submitted,
  setIsSubmitted,
  setSelectedAnswer,
  setQuestionListModalOpen,
  isSubmittionCorect,
  wrongCount,
}) => {
  // i18n
  const { t } = useTranslation();

  // Helper Functions
  const toAlphabet = (idx) => {
    return String.fromCharCode(65 + idx);
  };

  const userAnswerHandler = (userAnswer) => {
    // Select Answer
    setSelectedAnswer(userAnswer);

    if (question.hasMultiAnswers === false) {
      // Submit Answer
      setIsSubmitted(true);
    }
  };

  const [isFetching, setIsFetching] = useState(false);
  const [stream, setStream] = useState("");
  const axiosPublic = useAxiosPublic();

  const getAnswerCss = (submiited, selected, isCorrect) => {
    if (submiited) {
      if (selected) {
        if (isCorrect) {
          // BG fill
          return "bg-emerald-500 dark:bg-emerald-600 hover:bg-emerald-500 dark:hover:bg-emerald-600";
        } else {
          return "bg-rose-500 dark:bg-rose-600 hover:bg-rose-500 dark:hover:bg-rose-600";
        }
      } else {
        if (isCorrect) {
          // Border Fill
          return "border-2 border-emerald-500 dark:border-emerald-600";
        } else {
          return "border dark:border-gray-600 bg-white border-gray-200 dark:bg-gray-800";
        }
      }
    } else {
      if (selected) {
        // BG Fill Sky
        return "border-sky-200 dark:border-sky-600 hover:bg-sky-500 dark:hover:bg-sky-900 bg-sky-400 dark:bg-sky-800";
      } else {
        // BG Fill Gray
        return "border dark:border-gray-600 bg-white border-gray-200 dark:bg-gray-800";
      }
    }
  };

  const renderAnswer = (answer, idx) => {
    const isUserSelected = userAnswers.map((ans) => ans.id).includes(answer.id);

    return (
      <li key={answer.id}>
        <button
          onClick={() => {
            // Only one answer
            userAnswerHandler(answer);
          }}
          disabled={submitted}
          className={`w-full rounded-lg border border-gray-200 dark:border-gray-600 hover:bg-gray-50 dark:bg-gray-800
                        ${getAnswerCss(
                          submitted,
                          isUserSelected,
                          answer.isCorrect
                        )}
                        `}
        >
          <div className="px-4 py-4 flex items-center sm:px-6">
            <p className="font-medium text-black dark:text-gray-100 text-left">
              {toAlphabet(idx)}. {answer.title}
            </p>
          </div>
        </button>
      </li>
    );
  };

  useEffect(() => {
    if (question.id) {
      // Reset Stream
      setStream("");
    }
  }, [question]);

  const askChatGPT = async () => {
    try {
      setIsFetching(true);
      const instruction = t(
        "Which one is the correct answer? Can you give a short explanation?"
      );
      const prompt = `${quiz.title}: ${question.title}\n${question.answers
        .map(
          (answer) =>
            `${toAlphabet(question.answers.indexOf(answer))}. ${answer.title}`
        )
        .join("\n")}\n\n${instruction}`;

      let finalStream = "";
      await axiosPublic.post(
        "https://chatgpt-stream.edge.articue.io",
        { messages: [{ role: "user", content: prompt }] },
        {
          withCredentials: false,
          onDownloadProgress: (progressEvent) => {
            finalStream = "";

            const dataChunk = progressEvent.target.response;

            let concatedPayload = dataChunk.split("data: ");

            // Filter out empty strings
            concatedPayload = concatedPayload.filter(
              (str) => str.trim() !== ""
            );

            for (const line of concatedPayload) {
              const jsonString = line;
              try {
                if (jsonString.trim() !== "[DONE]") {
                  const dataObj = JSON.parse(jsonString);
                  const delta = dataObj.choices[0].delta.content;

                  if (delta) {
                    finalStream += delta;
                    setStream(finalStream);
                  }
                }
              } catch (error) {
                console.log("error: ", line);
              }
            }
          },
        }
      );

      setStream(finalStream);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="pt-24 pb-28 px-4 lg:px-0 max-w-3xl mx-auto">
      <div className="bg-white dark:bg-black transition-all rounded space-y-10">
        <div className="text-left w-full">
          {/* Progress Bar */}
          <div
            onClick={(e) => {
              e.stopPropagation();
              setQuestionListModalOpen(true);
            }}
            className="bg-gray-100 rounded-full h-2 mb-2 cursor-pointer"
          >
            <div
              className="bg-emerald-500 h-2 rounded-full transition-all"
              style={{
                width: `${
                  (quiz.questions
                    .map((question) => question.id)
                    .indexOf(question.id) /
                    (quiz.questions.length - 1)) *
                  100
                }%`,
              }}
            />
          </div>

          {/* Header */}
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-2 sm:space-y-0 text-xl leading-snug whitespace-pre-line text-black dark:text-gray-100 font-medium">
            <div>
              Q
              {quiz.questions
                .map((question) => question.id)
                .indexOf(question.id) + 1}
              . {question.title}
            </div>
            {wrongCount && (
              <span className="inline-flex max-w-fit items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                {wrongCount} {t("Incorrect Attempts")}
              </span>
            )}
          </div>

          <div className="mt-2">
            {question.answers.filter((answer) => answer.isCorrect).length >
              1 && (
              <div className="text-xs inline-flex font-medium bg-sky-100 text-sky-600 rounded-full text-center px-2.5 py-1">
                {t("Can choose more than one answer")}
              </div>
            )}
          </div>

          {/* Media */}
          {question.images.length > 0 && (
            <div className="flex flex-col items-center space-y-2 mt-2">
              {question.images.length > 0 &&
                question.images.map((media) => {
                  return (
                    <img
                      src={media.url}
                      alt="Cover Image"
                      className="max-h-72"
                    />
                  );
                })}
            </div>
          )}
        </div>

        {/* Body */}
        <div className="h-[cal(100vh-64px)] overflow-auto space-y-10 pb-24">
          {/* Answer Choices */}
          <div className="overflow-auto">
            <ul role="list" className="divided-y divided-gray-200 space-y-2">
              {question.answers.map((answer, idx) => renderAnswer(answer, idx))}
            </ul>

            {/* Correct Indicator */}
            {submitted && isSubmittionCorect === true && (
              <div className="font-extrabold text-emerald-500">
                {t("Correct")}!
              </div>
            )}
            {submitted && isSubmittionCorect === false && (
              <div className="font-extrabold text-rose-500">
                {t("Incorrect")}!
              </div>
            )}
          </div>

          {/* Solution */}
          {submitted &&
            question.solution &&
            question.solution.trim() !== "" && (
              <div className="text-black dark:text-white">
                <div className="font-extrabold text-left font-lg">
                  {t("Solution")}
                </div>
                <div className="text-left w-full">{question.solution}</div>
              </div>
            )}

          {/* Chatgpt */}
          <div>
            {!question.solution &&
              submitted &&
              (!isFetching ? (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    askChatGPT();
                  }}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700"
                >
                  {t("Ask ChatGPT")} (Beta)
                </button>
              ) : (
                <div className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-sky-600/70">
                  {t("Ask ChatGPT")} (Beta)...
                </div>
              ))}
            {stream && (
              <div className="text-black dark:text-white">
                <div className="font-extrabold text-left font-lg">
                  {t("ChatGPT Solution")}
                </div>
                <div className="text-left w-full">{stream.trim()}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionMain;
