import React from "react";
import { ReactComponent as LoadingIcon } from "../images/Loader.svg";

const Loader = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      {/* <LoadingIcon className="w-20 h-20 animate-spin fill-emerald-500 group-hover:fill-white" /> */}
    </div>
  );
};

export default Loader;
