import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const QuestionAnswer = ({
  quiz,
  question,
  quizId,
  setId,
  submitted,
  setIsSubmitted,
  next,
  prev,
  startedAutoNext,
  setSearchParams,
  isFetching,
}) => {
  // i18n
  const { t } = useTranslation();

  const renderNextBtn = () => {
    if (!submitted) {
      if (question.hasMultiAnswers) {
        // Return Submit Button
        return (
          <button
            type="button"
            onClick={() => {
              setIsSubmitted(true);
            }}
            className="w-full inline-flex items-center justify-center px-8 py-6 text-lg leading-4 font-medium sm:rounded-r-md shadow-sm text-white bg-indigo-500 hover:bg-indigo-600 dark:bg-indigo-600 dark:hover:bg-indigo-500"
          >
            {t("Submit")}
          </button>
        );
      }
    }

    return next ? (
      !isFetching ? (
        <button
          type="button"
          onClick={() => {
            setSearchParams({ questionId: next });
          }}
          className="relative w-full inline-flex items-center justify-center px-8 py-6 text-lg leading-4 font-medium sm:rounded-r-md shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 dark:bg-emerald-600 dark:hover:bg-emerald-500"
        >
          <span className="z-10">{t("Next")}</span>
          <div
            className="absolute bottom-0 left-0 bg-emerald-600 dark:bg-emerald-500  h-full sm:rounded-r-md transition-all duration-1000"
            style={{
              width: startedAutoNext ? "100%" : "0%",
            }}
          />
        </button>
      ) : (
        <span className="cursor-not-allowed w-full inline-flex items-center justify-center px-8 py-6 text-lg leading-4 font-medium sm:rounded-r-md shadow-sm text-white bg-emerald-400/20 dark:bg-emerald-500/20">
          {t("Next")}...
        </span>
      )
    ) : quiz.questions.map((question) => question.id).indexOf(question.id) ===
      quiz.questions.length - 1 ? (
      <Link
        to={`/practice/${quizId}/${setId}/results`}
        className="w-full inline-flex items-center justify-center px-8 py-6 text-lg leading-4 font-medium sm:rounded-r-md shadow-sm text-white bg-sky-500 hover:bg-sky-600 dark:bg-sky-600 dark:hover:bg-sky-500 "
      >
        {t("See Result")}
      </Link>
    ) : (
      <span className="cursor-not-allowed w-full inline-flex items-center justify-center px-8 py-6 text-lg leading-4 font-medium sm:rounded-r-md shadow-sm text-white bg-emerald-400/20 dark:bg-emerald-500/20">
        {t("Next")}
      </span>
    );
  };

  return (
    <div className="fixed bottom-0 w-full flex justify-center">
      <div className="flex justify-between w-full max-w-3xl">
        {prev ? (
          !isFetching ? (
            <button
              type="button"
              onClick={() => {
                setSearchParams({ questionId: prev });
              }}
              className="w-full inline-flex items-center justify-center px-8 py-6 text-lg leading-4 font-medium sm:rounded-l-md shadow-sm text-white bg-gray-500 hover:bg-gray-600 dark:bg-gray-600 dark:hover:bg-gray-500"
            >
              {t("Previous")}
            </button>
          ) : (
            <span className="cursor-not-allowed w-full inline-flex items-center justify-center px-8 py-6 text-lg leading-4 font-medium sm:rounded-l-md shadow-sm text-white bg-gray-400/20 dark:bg-gray-500/20">
              {t("Previous")}...
            </span>
          )
        ) : (
          <></>
        )}

        {renderNextBtn()}
      </div>
    </div>
  );
};

export default QuestionAnswer;
