import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useLocalStorage } from "@mantine/hooks";
import superjson from "superjson";
import { useTranslation } from "react-i18next";
import { shuffleWithSeed } from "../../utils/Utils";

import useAxiosPublic from "../../hooks/useAxiosPublic";
import endpoints from "../../api/endpoints";
import Loader from "../../partials/Loader";
import PageNotFound from "../error/PageNotFound";
import PracticeHeader from "../../partials/practice/PracticeHeader";
import QuestionListModal from "../../partials/practice/QuestionListModal";
import QuestionMain from "../../partials/practice/QuestionMain";
import QuestionAnswer from "../../partials/practice/QuestionAnswer";

function PracticeQuestion() {
  // i18n
  const { t } = useTranslation();

  const [quiz, setQuiz] = useState();
  const [question, setQuestion] = useState();
  const [next, setNext] = useState();
  const [prev, setPrev] = useState();

  const [isLoading, setLoading] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [submitted, setIsSubmitted] = useState(false);
  const [isSubmittionCorect, setIsSubmissionCorrect] = useState();
  const [startedAutoNext, setStartedAutoNext] = useState(false);

  const axiosPublic = useAxiosPublic();
  const controller = new AbortController();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // AutoNext
  const [autoNext, setAutoNext] = useLocalStorage({
    key: "autoNext",
    defaultValue: false,
  });

  // Modal
  const [questionListModalOpen, setQuestionListModalOpen] = useState(false);

  // Params
  const { quizId, setId } = useParams();
  const questionId = searchParams.get("questionId");

  // Record Practice Setting
  const [practiceSetting, setPracticeSetting] = useLocalStorage({
    key: `practiceSetting`,
    defaultValue: new Map(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Map() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Wrong History
  const [wrongStatistics, setWrongStatistics] = useLocalStorage({
    key: `wrongStatistics`,
    defaultValue: new Map(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Map() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Wrong History
  const [wrongHistory, setWrongHistory] = useLocalStorage({
    key: `wrongHistory`,
    defaultValue: new Set(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Set() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Get Previous Answer
  const [answerHistory, setAnswerHistory] = useLocalStorage({
    key: `practice-${quizId}-answer-history`,
    defaultValue: new Map(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Map() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Get Answer Statistics
  const [answerStatistics, setAnswerStatistics] = useLocalStorage({
    key: `practice-${quizId}-answer-statistics`,
    defaultValue: new Map(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Map() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Get Question List
  const getQuestionList = async () => {
    try {
      if (!practiceSetting.has(quizId)) {
        // Reset is submitted
        navigate(`/practice/${quizId}`);
        return;
      }

      const practiceObj = practiceSetting.get(quizId);

      const res = await axiosPublic.get(
        `${endpoints.PRACTICE_URL}/${quizId}/${setId}`,
        {
          signal: controller.signal,
        }
      );

      // Shuffle Questions
      if (practiceObj.isQuestionShuffled === true) {
        res.data.questions = shuffleWithSeed(
          res.data.questions,
          practiceObj.practiceId
        );
      }

      setQuiz(res.data);

      if (answerStatistics.size === 0) {
        // Push initial statistics
        res.data.questions.map((question) => {
          answerStatistics.set(question.id, "untouched");
        });
        setAnswerStatistics(answerStatistics);
      }

      if (!questionId) {
        setSearchParams({ questionId: res.data.questions[0].id || undefined });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // Get Answers
  const getAnswers = async (questionId) => {
    try {
      const res = await axiosPublic.get(
        `${endpoints.PRACTICE_URL}/${quizId}/${setId}/${questionId}`,

        {
          signal: controller.signal,
        }
      );

      // Shuffle Answers
      const practiceObj = practiceSetting.get(quizId);

      if (practiceObj.isAnswerShuffled === true) {
        res.data.answers = shuffleWithSeed(
          res.data.answers,
          res.data.id + practiceObj.practiceId
        );
      }

      setQuestion(res.data);

      // Set Next and Prev
      if (quiz) {
        const idx = quiz.questions.findIndex((q) => q.id === questionId);
        if (idx > 0) {
          setPrev(quiz.questions[idx - 1].id);
        } else {
          setPrev(null);
        }

        if (idx < quiz.questions.length - 1 && quiz.questions.length !== 1) {
          setNext(quiz.questions[idx + 1].id);
        } else {
          setNext(null);
        }
      }

      // Reset User Answer
      setUserAnswers([]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderQuestion = async () => {
    setIsFetching(true);
    if (questionId && quiz) {
      await getAnswers(questionId);

      if (answerHistory.has(questionId)) {
        // Render Previous Answer
        setUserAnswers(answerHistory.get(questionId));
        setIsSubmitted(true);

        const answerAttempt = answerHistory.get(questionId);

        // If question is multiple choice
        if (answerAttempt.length > 1) {
          // Check if user has answered correctly
          let isCorrect = true;
          for (const answer of question.answers) {
            // User answer a wrong answer
            if (answerAttempt.includes(answer) && !answer.isCorrect) {
              isCorrect = false;
              // User didn't answer a correct answer
            } else if (!answerAttempt.includes(answer) && answer.isCorrect) {
              isCorrect = false;
            }
          }
          setIsSubmissionCorrect(isCorrect);
        } else if (answerAttempt.length === 1) {
          setIsSubmissionCorrect(answerAttempt[0].isCorrect);
        } else {
          setIsSubmissionCorrect(false);
        }
      } else {
        // User submit an empty answer
        setIsSubmitted(false);
      }

      // Touch Question
      if (answerStatistics instanceof Map) {
        // Check if untouched
        if (answerStatistics.get(questionId) === "untouched") {
          answerStatistics.set(questionId, "touched");
          setAnswerStatistics(answerStatistics);
        }
      }

      setLoading(false);
    }
    setIsFetching(false);
  };
  const setSelectedAnswer = (answer) => {
    if (userAnswers.includes(answer)) {
      setUserAnswers(userAnswers.filter((a) => a.id !== answer.id));
    } else {
      setUserAnswers([...userAnswers, answer]);
    }
  };

  useEffect(() => {
    if (submitted === true) {
      let isCorrect = true;

      // Check if use has answered previously
      if (!answerHistory.has(questionId)) {
        // Save Answer
        answerHistory.set(questionId, userAnswers);
        setAnswerHistory(answerHistory);

        // Check if userAnswer is correct or not
        for (const answer of question.answers) {
          // User answer a wrong answer
          if (userAnswers.includes(answer) && !answer.isCorrect) {
            isCorrect = false;
            // User didn't answer a correct answer
          } else if (!userAnswers.includes(answer) && answer.isCorrect) {
            isCorrect = false;
          }
        }

        if (!isCorrect) {
          // Save Wrong History
          wrongHistory.add(`${quiz.id}:${questionId}:${quizId}`);
          setWrongHistory(wrongHistory);

          // Question Statistics
          if (wrongStatistics.has(questionId)) {
            // Increment Wrong Count
            wrongStatistics.set(
              questionId,
              wrongStatistics.get(questionId) + 1
            );
          } else {
            // Set Wrong Count
            wrongStatistics.set(questionId, 1);
            console.log("New");
          }
          setWrongStatistics(wrongStatistics);
        }

        // Update statistics and correctness
        answerStatistics.set(questionId, isCorrect ? "correct" : "wrong");

        setAnswerStatistics(answerStatistics);
        setIsSubmissionCorrect(isCorrect);

        // Check if autoNext is enabled
        if (autoNext) {
          setStartedAutoNext(true);
          setTimeout(() => {
            if (next) {
              setSearchParams({ questionId: next });
              setStartedAutoNext(false);
            }
          }, 1000);
        }
      }
    }
  }, [submitted]);

  // Render Question
  useEffect(() => {
    if (isFetching) return;
    renderQuestion();
  }, [quiz, searchParams]);

  useEffect(() => {
    if (isFetching) return;
    renderQuestion();
  }, [quiz]);

  // Get Quiz
  const init = async () => {
    setLoading(true);
    await getQuestionList();
  };

  useEffect(() => {
    init();
  }, []);

  if (isLoading) return <Loader />;
  if ((!quiz || !question) && !isLoading) return <PageNotFound />;
  return (
    <>
      <PracticeHeader
        quiz={quiz}
        quizId={quizId}
        question={question}
        setQuestionListModalOpen={setQuestionListModalOpen}
        autoNext={autoNext}
        setAutoNext={setAutoNext}
      />

      <main>
        <QuestionListModal
          importModalOpen={questionListModalOpen}
          setImportModalOpen={setQuestionListModalOpen}
          quizId={quizId}
          setId={setId}
        />

        <QuestionMain
          quiz={quiz}
          question={question}
          userAnswers={userAnswers}
          submitted={submitted}
          setIsSubmitted={setIsSubmitted}
          setSelectedAnswer={setSelectedAnswer}
          setQuestionListModalOpen={setQuestionListModalOpen}
          isSubmittionCorect={isSubmittionCorect}
        />

        <QuestionAnswer
          quiz={quiz}
          question={question}
          quizId={quizId}
          setId={setId}
          submitted={submitted}
          setIsSubmitted={setIsSubmitted}
          next={next}
          prev={prev}
          setSearchParams={setSearchParams}
          isFetching={isFetching}
          startedAutoNext={startedAutoNext}
        />
      </main>
    </>
  );
}

export default PracticeQuestion;
