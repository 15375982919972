import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import LoadingBtn from "../../components/LoadingBtn";

import endpoints from "../../api/endpoints";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Banner from "../../components/Banner";

function SecurityPanel(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  // Send Email Verification Email
  const sendEmailVerification = async () => {
    try {
      setIsSending(true);
      await axiosPrivate.get(endpoints.SENDEMAILVERIFICATION_URL);
      setIsSent(true);
    } catch (error) {
      console.error(error);
      setWarning(error.response.data.errors.msg);
    } finally {
      setIsSending(false);
    }
  };

  // Reset Password Handler
  const resetPasswordHandler = async (values, { setErrors, resetForm }) => {
    setIsLoading(true);
    // Reset Alert
    setSuccess();
    setWarning();

    try {
      const res = await axiosPrivate.post(endpoints.CHANGEPW_URL, values);
      console.log(res);

      if (res.data.msg === "PASSWORD_CHANGED") {
        setSuccess("Password changed successfully");
      }

      // Reset Form
      resetForm();
    } catch (error) {
      console.log(error.response.data.errors.msg);

      if (error.response.data.errors.msg === "WRONG_PASSWORD") {
        setWarning("Wrong old password");
      } else {
        setWarning(error.response.data.errors.msg);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetPasswordForm = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().trim().required("Old password is required"),
      newPassword: Yup.string()
        .trim()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: resetPasswordHandler,
  });

  return (
    <div className="grow">
      <form onSubmit={resetPasswordForm.handleSubmit}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-black dark:text-white font-bold mb-5">
            Security
          </h2>

          <section>
            <h2 className="text-xl leading-snug text-black dark:text-white font-bold mb-1 space-x-2 inline-flex">
              <span>
                Your Email:{" "}
                <span className="text-emerald-500 space-x-2">
                  {props?.user?.email} {/* Check if verified */}
                  {props?.user?.googleId && (
                    <div className="text-xs items-cernter inline-flex font-medium bg-sky-100 text-sky-600 rounded-full text-center px-2.5 py-1">
                      Liked with Google
                    </div>
                  )}
                </span>
              </span>
            </h2>
            <div className="mt-5">
              {props?.user?.verified !== true ? (
                isSending ? (
                  <button
                    className="btn bg-emerald-500 hover:bg-emerald-600 text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none"
                    disabled=""
                  >
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
                    </svg>
                    <span className="ml-1">Sending</span>
                  </button>
                ) : isSent ? (
                  <button
                    className="btn bg-teal-500 hover:bg-teal-600 text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none"
                    disabled=""
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={3}
                      stroke="currentColor"
                      className="w-4 h-4 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span className="ml-1">Sent</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      sendEmailVerification();
                    }}
                    className="btn border-gray-200 hover:border-gray-300 shadow-sm text-emerald-500"
                  >
                    Verify Again
                  </button>
                )
              ) : null}
            </div>
          </section>

          {/* Password */}

          <section>
            <h2 className="text-xl leading-snug text-black dark:text-white font-bold mb-1">
              Change Password
            </h2>
            <Banner type="warning" open={warning} setOpen={setWarning}>
              {warning}
            </Banner>

            <Banner type="success" open={success} setOpen={setSuccess}>
              {success}
            </Banner>

            <div className="mt-5">
              <div className="sm:flex sm:items-start space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                <div className="sm:w-1/3">
                  <label
                    className="block text-sm font-medium mb-1 text-black dark:text-white"
                    htmlFor="oldPassword"
                  >
                    Old Password
                  </label>
                  <input
                    id="oldPassword"
                    name="oldPassword"
                    className="form-input w-full dark:bg-gray-800 dark:text-white dark:border-gray-600"
                    type="password"
                    onChange={resetPasswordForm.handleChange}
                    value={resetPasswordForm.values.oldPassword}
                  />
                  <p className="text-red-600 text-sm">
                    {resetPasswordForm.errors.oldPassword &&
                    resetPasswordForm.touched.oldPassword
                      ? `Error: ${resetPasswordForm.errors.oldPassword}`
                      : null}
                  </p>
                </div>
                <div className="sm:w-1/3">
                  <label
                    className="block text-sm font-medium mb-1 text-black dark:text-white"
                    htmlFor="newPassword"
                  >
                    New Password
                  </label>
                  <input
                    id="newPassword"
                    name="newPassword"
                    className="form-input w-full dark:bg-gray-800 dark:text-white dark:border-gray-600"
                    type="password"
                    onChange={resetPasswordForm.handleChange}
                    value={resetPasswordForm.values.newPassword}
                  />
                  <p className="text-red-600 text-sm">
                    {resetPasswordForm.errors.newPassword &&
                    resetPasswordForm.touched.newPassword
                      ? `Error: ${resetPasswordForm.errors.newPassword}`
                      : null}
                  </p>
                  <PasswordStrengthBar
                    minLength={8}
                    scoreWordClassName="text-sm font-medium"
                    password={resetPasswordForm.values.newPassword}
                  />
                </div>
                <div className="sm:w-1/3">
                  <label
                    className="block text-sm font-medium mb-1 text-black dark:text-white"
                    htmlFor="confirmPassword"
                  >
                    Confirm New Password
                  </label>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    className="form-input w-full dark:bg-gray-800 dark:text-white dark:border-gray-600"
                    type="password"
                    onChange={resetPasswordForm.handleChange}
                    value={resetPasswordForm.values.confirmPassword}
                  />
                  <p className="text-red-600 text-sm">
                    {resetPasswordForm.errors.confirmPassword &&
                    resetPasswordForm.touched.confirmPassword
                      ? `Error: ${resetPasswordForm.errors.confirmPassword}`
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5">
            <div className="flex self-end ml-3">
              {isLoading ? (
                <LoadingBtn text="Saving" />
              ) : (
                <button
                  type="submit"
                  className="btn bg-emerald-500 hover:bg-emerald-600 dark:bg-emerald-600 dark:hover:bg-emerald-500 text-white"
                >
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}

export default SecurityPanel;
