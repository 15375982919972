/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import endpoints from "../../api/endpoints";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function NewQuizModal(props) {
  const cancelButtonRef = useRef(null);

  // i18n
  const { t } = useTranslation();

  const axiosPrivate = useAxiosPrivate();
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  const submitHandler = async (values, { setErrors, resetForm }) => {
    try {
      setIsFetching(true);

      const res = await axiosPrivate.post(
        endpoints.QUESTIONSETS_URL,
        JSON.stringify(formik.values),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      navigate(`/quizzes/${res.data.id}`);
    } catch (err) {
      console.log(err);

      if (!err?.response) {
        console.error("No Server res");
      } else {
        let error = err?.response?.data?.errors?.msg;
        console.error(error);
        toast.error(`${t("Error occurred")}!`);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required(t("This field is required")),
      description: Yup.string().trim(),
    }),
    onSubmit: submitHandler,
  });

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={formik.handleSubmit}
              className="inline-block align-bottom bg-white dark:bg-black transition-all rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl w-full sm:p-6"
            >
              <div>
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-left text-lg leading-6 font-medium text-black dark:text-white mb-4"
                  >
                    {t("Create Quiz")}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="mt-1">
                      <label
                        className="text-left block text-sm font-medium mb-1 dark:text-white"
                        htmlFor="name"
                      >
                        {t("Title")} <span className="text-rose-500">*</span>
                      </label>
                      <input
                        id="title"
                        name="title"
                        className="shadow-sm block w-full sm:text-sm border-gray-300 dark:bg-gray-800 dark:border-gray-600 dark:text-white rounded-md"
                        type="text"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <p className="text-red-600 text-sm">
                        {formik.errors.title && formik.touched.title
                          ? `${t("Error")}: ${formik.errors.title}`
                          : null}
                      </p>
                    </div>
                    <div className="mt-1">
                      <label
                        className="text-left block text-sm font-medium mb-1 dark:text-white"
                        htmlFor="name"
                      >
                        {t("Description")}{" "}
                        <span className="text-xs text-gray-500">
                          {t("Optional")}
                        </span>
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        className="shadow-sm block w-full sm:text-sm border-gray-300 dark:bg-gray-800 dark:border-gray-600 dark:text-white rounded-md resize-none"
                        type="description"
                        rows="10"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <p className="text-red-600 text-sm">
                        {formik.errors.description && formik.touched.description
                          ? `${t("Error")}: ${formik.errors.description}`
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 sm:col-start-2 sm:text-sm"
                  disabled={isFetching}
                >
                  {t("Create")}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-black hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => props.setOpen(false)}
                  ref={cancelButtonRef}
                >
                  {t("Cancel")}
                </button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
