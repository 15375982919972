import React from "react";
import { useTranslation } from "react-i18next";

const DraftStatus = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case true:
      return (
        <div className="text-xs block font-medium bg-gray-100 text-black rounded-full text-center px-2.5 py-1 whitespace-nowrap">
          {t("Draft")}
        </div>
      );
    default:
      return;
  }
};

export default DraftStatus;
