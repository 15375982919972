import React from "react";
import { ReactComponent as LoaderIcon } from "../images/Loader.svg";

const Spinner = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <LoaderIcon className="w-5 h-5 animate-spin fill-emerald-500 group-hover:fill-white" />
    </div>
  );
};

export default Spinner;
