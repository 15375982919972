import React, { useState, useEffect, Fragment } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { List, arrayMove } from "react-movable";
import { toast } from "react-toastify";
import useAxiosPublic from "../../hooks/useAxiosPublic";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { fileUpload } from "../../utils/FileUpload";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import Loader from "../../partials/Loader";
import Spinner from "../../partials/Spinner";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Warning from "../../components/alert/Warning";
import QuestionsNavDropdown from "../../partials/quizzes/QuestionsNavDropdown";
import DeleteQuestionModal from "../../partials/questions/DeleteQuestionModal";

function EditQuiz() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // ChatGPT
  const [isFetching, setIsFetching] = useState(false);
  const axiosPublic = useAxiosPublic();

  // i18n
  const { t } = useTranslation();

  // Warning
  const [warning, setWarning] = useState();

  const { id, setId, questionId } = useParams();
  const [quiz, setQuiz] = useState({});
  const [quizSet, setQuizSet] = useState({});
  const [question, setQuestion] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const [user, setUser] = useState();

  // Quiz Helper
  const answerValidator = (values) => {
    // Validate if number of answers > number of choices
    const correctLength = values.answers.filter(
      (answer) => answer.isCorrect
    ).length;

    if (correctLength === 0) {
      throw new Error("You must select at least one correct answer");
    } else if (correctLength === values.answers.length) {
      throw new Error("You must provide at least one wrong choice");
    }
  };

  const askChatGPT = async () => {
    try {
      setIsFetching(true);
      const instruction = t(
        "State the correct answer and a short explanation. Do not repeat the question and answer again. if the quiz is in chinese, response in traditional chinese."
      );
      const prompt = `${quiz.title}: ${question.title}\n${question.answers
        .map((answer) => `- ${answer.title}`)
        .join("\n")}\n\n${instruction}`;

      let finalStream = "";
      await axiosPrivate.post(
        "https://chatgpt-stream.edge.articue.io",
        { messages: [{ role: "user", content: prompt }] },
        {
          withCredentials: false,
          onDownloadProgress: (progressEvent) => {
            finalStream = "";

            const dataChunk = progressEvent.target.response;

            let concatedPayload = dataChunk.split("data: ");

            // Filter out empty strings
            concatedPayload = concatedPayload.filter(
              (str) => str.trim() !== ""
            );

            for (const line of concatedPayload) {
              const jsonString = line;
              try {
                if (jsonString.trim() !== "[DONE]") {
                  const dataObj = JSON.parse(jsonString);
                  const delta = dataObj.choices[0].delta.content;

                  if (delta) {
                    finalStream += delta;
                    formik.setFieldValue("solution", finalStream);
                  }
                }
              } catch (error) {
                console.log("error: ", line);
              }
            }
          },
        }
      );

      formik.setFieldValue("solution", finalStream);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  const submitHandler = async (values, { setErrors, resetForm }) => {
    try {
      setWarning("");
      setSubmitLoading(true);

      // Validate Answers
      answerValidator(values);

      // Submit to Backend
      await axiosPrivate.patch(
        endpoints.QUESTIONS_URL + "/" + questionId,
        values,
        {
          signal: controller.signal,
        }
      );

      // Render Quiz
      await init();

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      // Toast
      toast.success(`${t("Question edited successfully")}!`);

      // Reset Form
      resetForm();
    } catch (err) {
      // Customer Validation Error
      if (err.message) {
        setWarning(<Warning msg={err.message} />);
      } else if (err.response.data.errors) {
        if (err.response.data.errors.msg.length > 0) {
          const errors = err.response.data.errors.msg;
          console.log(errors);

          let errorList = [];
          for (const error of errors) {
            errorList.push(`${error.param}: ${error.msg}`);
          }

          setWarning(<Warning list={errorList} />);
        }
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: question.title || "",
      images: question.images || [],
      answers: question.answers || [
        { isCorrect: true, title: "" },
        { isCorrect: false, title: "" },
      ],
      solution: question.solution || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("This field is required")),
      images: Yup.array().of(Yup.object()).optional(),
      answers: Yup.array(
        Yup.object({
          title: Yup.string().required(t("This field is required")),
          isCorrect: Yup.boolean().required(t("This field is required")),
        })
      ),
      solution: Yup.string().optional(),
    }),
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const addQuestion = () => {
    formik.setFieldValue("answers", [
      ...formik.values.answers,
      { isCorrect: false, title: "" },
    ]);

    // Focus on the last input
    const lastInput = document.querySelector(
      `input[name="answers[${formik.values.answers.length}].title"]`
    );
    if (lastInput) {
      lastInput.focus();
    } else {
      console.log("Cannot focus on the last input");
    }
  };

  const removeQuestion = (idx) => {
    formik.setFieldValue(
      "answers",
      formik.values.answers.filter((_, i) => i !== idx)
    );
  };

  // Delete
  const deleteQuestion = async () => {
    try {
      setWarning("");
      setDeleteLoading(true);

      // Submit to Backend
      const res = await axiosPrivate.delete(
        `${endpoints.QUESTIONSETS_URL}/${id}/${setId}/questions/${questionId}`,
        {
          signal: controller.signal,
        }
      );

      // Go to previous question
      if (quizSet.questions.length > 1) {
        const questionIds = quizSet.questions.map((q) => q.id);
        const qIdx = questionIds.indexOf(questionId);
        let targetId = "";
        if (qIdx === 0 && questionIds.length > 1) {
          // Get Next Question
          targetId = questionIds[1];
        } else if (qIdx < questionIds.length - 1) {
          // Get Next Question
          targetId = questionIds[qIdx + 1];
        } else if (qIdx === questionIds.length - 1) {
          // Get Previous Question
          targetId = questionIds[qIdx - 1];
        } else {
          navigate(`/quizzes/${id}/`);
        }
        if (targetId !== "") {
          navigate(`/quizzes/${id}/${setId}/edit/${targetId}`);
        }
      } else {
        navigate(`/quizzes/${id}/${setId}/new`);
      }
    } catch (err) {
      if (err.response.data.errors) {
        if (err.response.data.errors.msg.length > 0) {
          const errors = err.response.data.errors.msg;
          console.log(errors);

          let errorList = [];
          for (const error of errors) {
            errorList.push(`${error.param}: ${error.msg}`);
          }

          setWarning(<Warning list={errorList} />);
        }
      }
    } finally {
      setDeleteLoading(false);

      // Go to the top
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const renderAnswer = (item, props) => {
    return (
      <li
        {...props}
        tabIndex="-1"
        className="relative w-full cursor-move bg-white dark:bg-gray-800 shadow-md rounded-lg border border-gray-200 dark:border-gray-600 p-3"
        draggable="true"
      >
        <div className="sm:flex sm:justify-between sm:items-start">
          {/* Left side */}
          <div className="grow my-1 sm:mb-0 space-y-3">
            <div className="flex items-center space-x-1">
              {/* Drag button */}
              <div>
                <span className="sr-only">Drag</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </div>

              {/* Checkbox button */}
              <label className="w-full flex items-center justify-between">
                {props.key !== undefined && (
                  <>
                    <input
                      type="checkbox"
                      name={`answers[${props.key}].isCorrect`}
                      checked={formik.values.answers[props.key].isCorrect}
                      value={formik.values.answers[props.key].isCorrect}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      tabIndex="-1"
                      className="ml-2 focus:ring-0 focus-visible:ring w-5 h-5 p-2 bg-white border border-gray-200 dark:border-gray-600 text-emerald-500 rounded-full"
                    />
                    <input
                      name={`answers[${props.key}].title`}
                      className="w-full bg-transparent dark:text-white border-none focus:ring-0 focus:ring-offset-0 placeholder:text-gray-400 ml-1"
                      type="text"
                      placeholder={`${t("Type your answer here")}...`}
                      autoComplete="off"
                      value={formik.values.answers[props.key].title}
                      onChange={formik.handleChange}
                      onKeyDown={(e) => {
                        console.log(e.key);

                        if (e.key === "Backspace") {
                          if (e.target.value === "") {
                            removeQuestion(props.key);

                            // Focus the previous input
                            const prevInput = document.querySelector(
                              `input[name="answers[${props.key - 1}].title"]`
                            );
                            if (prevInput) {
                              prevInput.focus();

                              // Do not trigger the delete function
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }
                        }

                        if (e.key === "Enter") {
                          console.log("Enter");
                          const nextInput = document.querySelector(
                            `input[name="answers[${props.key + 1}].title"]`
                          );
                          if (nextInput) {
                            nextInput.focus();
                          } else {
                            // create a new answer
                            addQuestion();
                          }
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </>
                )}
                {props.key === undefined && (
                  <>
                    <input
                      type="checkbox"
                      defaultChecked={item.isCorrect}
                      readOnly={true}
                      tabIndex="-1"
                      className="ml-2 focus:ring-0 focus-visible:ring w-5 h-5 p-2 bg-white border border-gray-200 dark:border-gray-600 text-emerald-500 rounded-full"
                    />
                    <input
                      className="w-full bg-transparent border-none focus:ring-0 focus:ring-offset-0 placeholder:text-gray-400 ml-1"
                      type="text"
                      placeholder={`${t("Type your answer here")}...`}
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          if (e.target.value === "") {
                            removeQuestion(props.key);

                            // Focus the previous input
                            const prevInput = document.querySelector(
                              `input[name="answers[${props.key - 1}].title"]`
                            );
                            if (prevInput) {
                              prevInput.focus();

                              // Do not trigger the delete function
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }
                        }

                        if (e.key === "Enter") {
                          console.log("Enter");
                          const nextInput = document.querySelector(
                            `input[name="answers[${props.key + 1}].title"]`
                          );
                          if (nextInput) {
                            nextInput.focus();
                          } else {
                            // create a new answer
                            addQuestion();
                          }
                        }
                      }}
                      value={item.title}
                      readOnly={true}
                    />
                  </>
                )}
              </label>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            removeQuestion(props.key);
          }}
          tabIndex="-1"
          className="absolute top-0 right-0 w-5 h-5 -mt-2 -mr-2 rounded-full bg-rose-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </li>
    );
  };

  const renderAddBtn = () => {
    return (
      <div className="mt-5">
        <button
          type="button"
          onClick={() => addQuestion()}
          tabIndex="-1"
          className="border-none bg-white dark:bg-emerald-500 dark:text-white text-emerald-500 hover:bg-emerald-500 hover:text-white rounded-full p-2 transition-all"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </button>
      </div>
    );
  };

  const hasPrevious = () => {
    if (quizSet.questions.map((q) => q.id).indexOf(questionId) === 0) {
      return false;
    } else {
      return true;
    }
  };

  const hasNext = () => {
    if (
      quizSet.questions.map((q) => q.id).indexOf(questionId) ===
      quizSet.questions.length
    ) {
      return false;
    } else {
      return true;
    }
  };

  const next = () => {
    if (hasNext()) {
      // If Last one
      if (
        quizSet.questions.map((q) => q.id).indexOf(questionId) ===
        quizSet.questions.length - 1
      ) {
        navigate(`/quizzes/${id}/${setId}/new/`);
      } else {
        navigate(
          `/quizzes/${id}/${setId}/edit/${
            quizSet.questions[
              quizSet.questions.map((q) => q.id).indexOf(questionId) + 1
            ].id
          }`
        );
      }
    }
  };

  const previous = () => {
    if (hasPrevious()) {
      navigate(
        `/quizzes/${id}/${setId}/edit/${
          quizSet.questions[
            quizSet.questions.map((q) => q.id).indexOf(questionId) - 1
          ].id
        }`
      );
    }
  };

  const init = async () => {
    let tempQuiz = null;
    try {
      // Quiz
      const resQuiz = await axiosPrivate.get(
        `${endpoints.QUESTIONSETS_URL}/${id}`,
        {
          signal: controller.signal,
        }
      );

      setQuiz(resQuiz.data);

      // Quiz Set
      const resQuizSet = await axiosPrivate.get(
        `${endpoints.QUESTIONSETS_URL}/${id}/${setId}`,
        {
          signal: controller.signal,
        }
      );

      setQuizSet(resQuizSet.data);

      // Questions
      const resQuestion = await axiosPrivate.get(
        `${endpoints.QUESTIONSETS_URL}/${id}/${setId}/questions/${questionId}`,
        {
          signal: controller.signal,
        }
      );

      const question = resQuestion.data;

      setQuestion(question);

      // User
      const res = await axiosPrivate.get(endpoints.PROFILE_URL, {
        signal: controller.signal,
      });

      setUser(res.data);
    } catch (error) {
      // Go to the new question
      navigate(`/quizzes/${id}`);
    }

    setLoading(false);
  };

  const deleteImage = (idx) => {
    // deleteImage(idx, mediaIdx, "images");
    if (idx !== null) {
      formik.setFieldValue(
        "images",
        formik.values.images.filter((item, index) => index !== idx)
      );
    } else {
      formik.setFieldValue("images", []);
    }
  };

  const moveImageToTop = (idx) => {
    // deleteImage(idx, mediaIdx, "images");

    if (idx) {
      formik.setFieldValue("images", [
        formik.values.images[idx],
        ...formik.values.images.filter((item, index) => index !== idx),
      ]);
    } else {
      formik.setFieldValue("images", []);
    }
  };

  const handleFileUpload = async (file, field, type) => {
    if (file.length > 0) {
      // Activate Loader
      formik.setFieldValue(field, [
        ...formik.values[field],
        ...Array(file.length).fill("loader"),
      ]);

      // Upload to server
      const urls = await Promise.all(
        Array.from(file).map((item) => fileUpload(axiosPrivate, item, type))
      );

      // Update URL to form
      formik.setFieldValue(field, [...formik.values[field], ...urls]);
    } else {
      // Activate Loader
      formik.setFieldValue(field, "loader");

      // Upload to server
      const url = await fileUpload(axiosPrivate, file, type);

      // Update URL to form
      formik.setFieldValue(field, url);
    }
  };

  useEffect(() => {
    init();
  }, [id, questionId]);

  if (isLoading) return <Loader />;
  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          backPath={`/quizzes/${id}`}
          rightBtn={
            <div className="flex items-center space-x-2">
              <button
                className={
                  hasPrevious()
                    ? "flex items-center space-x-2 w-full rounded-md px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-white dark:hover:text-gray-100 whitespace-nowrap"
                    : "cursor-not-allowed flex items-center space-x-2 w-full rounded-md px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-white dark:hover:text-gray-100 whitespace-nowrap"
                }
                disabled={!hasPrevious()}
                onClick={() => previous()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
                <span className="hidden sm:block">{t("Previous")}</span>
              </button>
              <button
                className={
                  hasNext()
                    ? "flex items-center space-x-2 w-full rounded-md px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-white dark:hover:text-gray-100 whitespace-nowrap"
                    : "cursor-not-allowed flex items-center space-x-2 w-full rounded-md px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-white dark:hover:text-gray-100 whitespace-nowrap"
                }
                disabled={!hasNext()}
                onClick={() => next()}
              >
                <span className="hidden sm:block">{t("Next")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </button>
            </div>
          }
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <DeleteQuestionModal
            deleteFunction={deleteQuestion}
            setOpen={setDeleteModalOpen}
            open={deleteModalOpen}
          />

          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-8 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 sm:gap-y-0 mb-8">
              {/* Left: Title */}
              <div className="mb-0">
                <span className="text-sm font-bold text-black dark:text-white">
                  {t("Edit Question")}
                </span>
                <h1 className="text-2xl md:text-3xl text-black font-bold">
                  <QuestionsNavDropdown
                    quiz={quiz}
                    set={quizSet}
                    questionId={questionId}
                  />
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="flex items-center justify-start sm:justify-end gap-4">
                {/* Setting button */}
                {/* <button className="btn bg-emerald-500 hover:bg-emerald-600 text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>

                  <span className="hidden sm:block ml-2">Settings</span>
                </button> */}

                {/* Insert */}
                {/* <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-600 dark:text-white text-sm font-medium text-black hover:bg-gray-50">
                      {t("Insert Media")}
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="dark:bg-black transition-alltext-white">
                        <Menu.Item>
                          <label
                            htmlFor="images"
                            className="text-center bg-gray-50 hover:bg-gray-100 text-black dark:bg-gray-700 dark:hover:bg-gray-800 dark:text-white block px-4 py-2 w-full first:rounded-t-md text-sm"
                          >
                            {t("Insert Image")}
                          </label>
                        </Menu.Item>
                        <Menu.Item>
                          <label
                            htmlFor="audios"
                            className="text-center bg-gray-50 hover:bg-gray-100 text-black dark:bg-gray-700 dark:hover:bg-gray-800 dark:text-white block px-4 py-2 w-full last:rounded-b-md text-sm"
                          >
                            {t("Insert Audio")}
                          </label>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu> */}

                <label
                  htmlFor="images"
                  className="cursor-pointer inline-flex justify-center w-fit rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-600 dark:text-white text-sm font-medium text-black hover:bg-gray-50"
                >
                  {t("Insert Image")}
                </label>

                {/* Delete button */}
                {deleteLoading && (
                  <button
                    className="btn bg-rose-400 cursor-progress text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none"
                    disabled=""
                  >
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
                    </svg>
                    <span className="ml-2">{t("Processing")}</span>
                  </button>
                )}
                {!deleteLoading && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteModalOpen(true);
                    }}
                    className="btn bg-rose-500 hover:bg-rose-600 text-white"
                  >
                    <span>{t("Delete Question")}</span>
                    <span className="sm:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </span>
                  </button>
                )}

                {/* Save Button */}
                <div className="flex items-center space-x-2">
                  {submitLoading && (
                    <button
                      className="px-8 btn bg-emerald-400 cursor-progress text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none"
                      disabled=""
                    >
                      <svg
                        className="animate-spin w-4 h-4 fill-current shrink-0"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
                      </svg>
                      <span className="ml-2">{t("Processing")}</span>
                    </button>
                  )}
                  {!submitLoading && (
                    <button
                      type="button"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      className="btn bg-emerald-500 hover:bg-emerald-600 text-white px-8"
                    >
                      {t("Save")}
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* Warning */}
            {warning}

            {/* Question */}
            <div className="space-y-6">
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <h2 className="grow font-semibold text-black dark:text-white truncate mb-4">
                    {t("Title")}
                  </h2>
                  <div className="w-full flex flex-col items-center justify-center space-y-2">
                    <textarea
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full bg-white dark:bg-gray-800 dark:text-white shadow-md resize-none rounded-lg border border-gray-200 dark:border-gray-600 p-4 font-lg placeholder:text-gray-400"
                      type="text"
                      rows={3}
                      placeholder={`${t("Type your question here")}...`}
                    ></textarea>
                    <p className="text-red-600 text-sm">
                      {formik.errors.title && formik.touched.title
                        ? `${t("Error")}: ${formik.errors.title}`
                        : null}
                    </p>
                  </div>
                </div>

                {formik.values.images.length > 0 && (
                  <div className="mb-2">
                    <div className="flex items-start justify-between mb-4">
                      <h2 className="grow font-semibold text-black dark:text-white truncate">
                        {t("Images")} ({t("Optional")})
                      </h2>
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-3 gap-x-2">
                      {formik.values.images &&
                        formik.values.images.length > 0 &&
                        formik.values.images.map((media, mediaIdx) => {
                          if (media === "loader") {
                            return (
                              <div
                                className="relative h-24 w-full"
                                key={mediaIdx}
                              >
                                <Spinner />
                              </div>
                            );
                          } else {
                            return (
                              <div className="relative" key={mediaIdx}>
                                <img
                                  src={media.url}
                                  alt="Cover Image"
                                  className="w-full mb-2"
                                />
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteImage(mediaIdx);
                                  }}
                                  className="group flex justify-center items-center absolute h-5 w-5 top-0 right-0 -mt-1 -mr-1 z-20"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="flex absolute h-4 w-4 z-10 text-white"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                  <span className="relative inline-flex rounded-full h-5 w-5 bg-rose-500 group-hover:bg-rose-600"></span>
                                </button>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    moveImageToTop(mediaIdx);
                                  }}
                                  className="group flex justify-center items-center absolute h-5 w-5 top-7 right-0 -mt-1 -mr-1 z-20"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="flex absolute h-4 w-4 z-10 text-white"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                                    />
                                  </svg>
                                  <span className="relative inline-flex rounded-full h-5 w-5 bg-amber-500 group-hover:bg-amber-600"></span>
                                </button>
                              </div>
                            );
                          }
                        })}
                    </div>

                    <p className="text-red-600 text-sm">
                      {formik.errors.images &&
                      formik.touched.images &&
                      formik.errors.images
                        ? `Error: ${formik.errors.images}`
                        : null}
                    </p>
                  </div>
                )}

                {/* {formik.values.audios.length > 0 && (
                  <div className="mb-2">
                    <div className="flex items-start justify-between mb-4">
                      <h2 className="grow font-semibold text-black dark:text-white truncate">
                        {t("Audios")} ({t("Optional")})
                      </h2>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-x-2">
                      {formik.values.audios &&
                        formik.values.audios.length > 0 &&
                        formik.values.audios.map((media, mediaIdx) => {
                          if (media === "loader") {
                            return (
                              <div
                                className="relative h-24 w-full"
                                key={mediaIdx}
                              >
                                <Spinner />
                              </div>
                            );
                          } else {
                            return (
                              <div className="relative" key={mediaIdx}>
                                <audio controls className="w-full mb-2">
                                  <source src={media} />
                                </audio>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteImage(mediaIdx);
                                  }}
                                  className="group flex justify-center items-center absolute h-5 w-5 top-0 right-0 -mt-1 -mr-1 z-20"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="flex absolute h-4 w-4 z-10 text-white"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                  <span className="relative inline-flex rounded-full h-5 w-5 bg-rose-500 group-hover:bg-rose-600"></span>
                                </button>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    moveImageToTop(mediaIdx);
                                  }}
                                  className="group flex justify-center items-center absolute h-5 w-5 top-7 right-0 -mt-1 -mr-1 z-20"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="flex absolute h-4 w-4 z-10 text-white"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                                    />
                                  </svg>
                                  <span className="relative inline-flex rounded-full h-5 w-5 bg-amber-500 group-hover:bg-amber-600"></span>
                                </button>
                              </div>
                            );
                          }
                        })}
                    </div>

                    <div>
                      <p className="text-red-600 text-sm">
                        {formik.errors.audios &&
                        formik.touched.audios &&
                        formik.errors.audios
                          ? `Error: ${formik.errors.audios}`
                          : null}
                      </p>
                    </div>
                  </div>
                )} */}

                {/* Images */}
                <input
                  id="images"
                  names="images"
                  onChange={(e) =>
                    handleFileUpload(e.target.files, "images", "image")
                  }
                  accept=".jpg,.jpeg"
                  type="file"
                  className="sr-only"
                  multiple=""
                />

                {/* Audios */}
                <input
                  id="audios"
                  names="audios"
                  onChange={(e) => {
                    console.log(e);
                    handleFileUpload(e.target.files, "audios", "audio");
                  }}
                  accept=".mp3,.wav,.m4a"
                  type="file"
                  className="sr-only"
                  multiple=""
                />

                <div>
                  <div className="gap-x-2 flex items-center mb-4">
                    <h2 className="grow font-semibold text-black dark:text-white truncate">
                      {t("Answers")}
                    </h2>

                    {/* More than one answer indicator */}
                    {formik.values.answers.filter((answer) => answer.isCorrect)
                      .length > 1 && (
                      <div className="text-xs inline-flex font-medium bg-indigo-100 text-indigo-600 rounded-full text-center px-2.5 py-1">
                        {t("More than one correct answer")}
                      </div>
                    )}
                  </div>

                  <List
                    values={formik.values.answers}
                    onChange={({ oldIndex, newIndex }) => {
                      formik.setFieldValue(
                        "answers",
                        arrayMove(formik.values.answers, oldIndex, newIndex)
                      );
                    }}
                    renderList={({ children, props }) => (
                      <ul
                        {...props}
                        className="w-full flex flex-col items-center justify-center space-y-4"
                      >
                        {children}
                      </ul>
                    )}
                    renderItem={({ value, props }) =>
                      renderAnswer(value, props)
                    }
                  />
                  <div className="w-full flex flex-col items-center justify-center space-y-4">
                    {renderAddBtn()}
                  </div>

                  {/* Error Msg */}
                  <div className="mb-2">
                    {formik.errors.answers &&
                    formik.touched.answers &&
                    formik.touched.answers.length > 0
                      ? formik.errors.answers.map((errorItem, idx) => {
                          if (
                            errorItem !== null &&
                            errorItem?.title !== undefined
                          ) {
                            return (
                              <p key={idx} className="text-red-600 text-sm">
                                {`${t("Error")}: [A${idx + 1}] ${
                                  errorItem.title
                                }`}
                              </p>
                            );
                          }
                        })
                      : null}

                    {formik.errors.answers &&
                    formik.touched.answers &&
                    formik.touched.answers.length > 0
                      ? formik.errors.answers.map((errorItem, idx) => {
                          if (
                            errorItem !== null &&
                            errorItem?.isCorrect !== undefined
                          ) {
                            return (
                              <p key={idx} className="text-red-600 text-sm">
                                {`${t("Error")}: [A${idx + 1}] ${
                                  errorItem.isCorrect
                                }`}
                              </p>
                            );
                          }
                        })
                      : null}
                  </div>
                </div>
                <div>
                  <h2 className="grow font-semibold text-black dark:text-white truncate mb-4">
                    {t("Solution")} ({t("Optional")}){" "}
                    {!isFetching ? (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          askChatGPT();
                        }}
                        className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700"
                      >
                        {t("Ask ChatGPT")} (Beta)
                      </button>
                    ) : (
                      <div className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-sky-600/70">
                        {t("Ask ChatGPT")} (Beta)...
                      </div>
                    )}
                  </h2>
                  <div className="w-full flex flex-col items-center justify-center space-y-4">
                    <textarea
                      name="solution"
                      value={formik.values.solution}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full bg-white dark:bg-gray-800 dark:text-white shadow-md resize-none rounded-lg border border-gray-200 dark:border-gray-600 p-4 font-lg placeholder:text-gray-400"
                      type="text"
                      rows={4}
                      placeholder={`${t("Type your solution here")}...`}
                    ></textarea>
                    <p className="text-red-600 text-sm">
                      {formik.errors.solution && formik.touched.solution
                        ? `${t("Error")}: ${formik.errors.solution}`
                        : null}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="w-full flex items-center justify-between">
                    <Link
                      to={`/quizzes/${quiz.id}`}
                      className="px-8 btn bg-gray-500 hover:bg-gray-600 text-white"
                    >
                      {t("Back")}
                    </Link>
                    <div className="flex items-center space-x-2">
                      {submitLoading && (
                        <button
                          className="px-8 btn bg-emerald-400 cursor-progress text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none"
                          disabled=""
                        >
                          <svg
                            className="animate-spin w-4 h-4 fill-current shrink-0"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
                          </svg>
                          <span className="ml-2">{t("Processing")}</span>
                        </button>
                      )}
                      {!submitLoading && (
                        <button
                          type="button"
                          className="px-8 btn bg-emerald-500 hover:bg-emerald-600 text-white"
                        >
                          {t("Save")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditQuiz;
