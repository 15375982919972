import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VisibilityStatus from "./VisibilityStatus";
import DraftStatus from "./DraftStatus";

function QuizTableItem({ quiz, showStatus }) {
  const { t } = useTranslation();

  return (
    <Link
      to={`/practice/${quiz.id}`}
      className="h-24 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 shadow rounded-md"
    >
      <div className="px-4 py-4 sm:px-6 flex justify-between space-x-4">
        <div className="flex flex-col items-start">
          <p className="text-md font-bold text-black dark:text-white line-clamp-1">
            {quiz.title}
          </p>
          <p className="text-xs font-base text-black dark:text-gray-400 line-clamp-2">
            {quiz.description ? quiz.description : t("No Description")}
          </p>
        </div>
        {showStatus && (
          <div className="max-w-fit flex flex-col justify-start space-y-2">
            <VisibilityStatus status={quiz.visibility} />
            <DraftStatus status={quiz.isDraft} />
          </div>
        )}
      </div>
    </Link>
  );
}

export default QuizTableItem;
