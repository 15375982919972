import React, { useEffect, useState } from "react";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { toast } from "react-toastify";

function QuizDetailHeader(props) {
  // i18n
  const { t, i18n } = useTranslation();
  const [title, setTitle] = useState(props.quiz.title);
  const [open, setOpen] = useState(false);
  const [backPath, setBackPath] = useState();

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Get URL from browser
    const path = location.pathname;
    console.log(path);

    // Set back path
    switch (path) {
      case "/":
        // Set Path to null
        setBackPath();
        break;
      case "/search":
        // Set Path to null
        setBackPath();
        break;
      case "/library":
        setBackPath();
        break;
      case "/signin":
        setBackPath();
        break;
      case "/signup":
        setBackPath();
        break;
      case "/reset-password":
        setBackPath();
        break;
      default:
        // Set Path to /
        setBackPath("/");
    }
  }, [location]);

  const share = async () => {
    const url = `https://app.practtice.com/practice/${props.quiz.id}`;
    try {
      await navigator.share({ url });
    } catch {
      navigator.clipboard.writeText(url);
    }

    toast.success(`${t("Copied to clipboard")}!`);
  };

  return (
    <div>
      <header className="lg:left-[256px] md:left-[80px] fixed top-0 left-0 right-0 bg-white dark:bg-transparent backdrop-blur-xl dark:backdrop-brightness-75 transition-all no-duration z-30">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 -mb-px">
            {/* Header: Left side */}
            <div className="flex items-center space-x-4">
              {/* Back Button */}
              <Link
                to={backPath}
                className="inline-flex justify-center w-full rounded-md px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-white dark:hover:text-gray-100 whitespace-nowrap"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
              </Link>
            </div>

            {/* Header: Right side */}
            <div className="flex items-center space-x-4">
              {/* Edit Button */}
              {props.user &&
                (props.user.role === "admin" ||
                  props.quiz.user.id === props.user.id) && (
                  <Link
                    to={`/quizzes/${props.quiz.id}`}
                    className="inline-flex gap-x-2 py-2 px-4 items-center w-full rounded-md text-sm font-medium text-black hover:bg-gray-200 dark:text-white dark:hover:bg-gray-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </Link>
                )}

              {/* Share Button */}
              <button
                onClick={() => share()}
                className="inline-flex gap-x-2 py-2 px-4 items-center w-full rounded-md text-sm font-medium text-black hover:bg-gray-200 dark:text-white dark:hover:bg-gray-800"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Dynamic Title */}
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            {title && <title>{t(title)} | Practtice</title>}
            
          </Helmet>
        </HelmetProvider>
      </header>
    </div>
  );
}

export default QuizDetailHeader;
