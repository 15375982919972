import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tab } from "@headlessui/react";

import useAuth from "../../hooks/useAuth";
import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";
import endpoints from "../../api/endpoints";

import PageNotFound from "../../pages/error/PageNotFound";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import UserTab from "../profile/UserTab";
import BottomAppBar from "../../partials/ui/BottomAppBar";

export default function FollowList() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const { t } = useTranslation();

  const axiosTryPrivate = useAxiosTryPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth?.user;
  const { state } = useParams();

  const [currentTab, setCurrentTab] = useState(state === "followers" ? 0 : 1);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);

  // Render Followers
  const renderFollower = async () => {
    try {
      const res = await axiosTryPrivate.get(endpoints.SELF_FOLLOWERS, {
        signal: controller.signal,
      });

      setFollowers(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Render Following
  const renderFollowing = async () => {
    try {
      const res = await axiosTryPrivate.get(endpoints.SELF_FOLLOWING, {
        signal: controller.signal,
      });

      setFollowing(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const main = async () => {
    try {
      setLoading(true);
      await Promise.all([renderFollower(), renderFollowing()]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    main();
  }, []);

  if (state !== "following" && state !== "followers") {
    return <PageNotFound />;
  }
  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto">
            {/* <WelcomeBanner user={user} /> */}

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-black dark:text-white font-bold">
                  {user?.name}
                </h1>
              </div>
            </div>

            <Tab.Group
              selectedIndex={currentTab}
              onChange={(index) => {
                setCurrentTab(index);
              }}
            >
              <Tab.List className="flex items-center justify-center gap-x-4">
                <Tab as={Fragment}>
                  <button
                    className={`${
                      currentTab === 0
                        ? "border-sky-500 text-sky-500"
                        : "border-transparent text-gray-900 hover:text-black dark:text-gray-100 dark:hover:text-white hover:border-gray-300"
                    } w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm`}
                  >
                    {t("Followers")}
                  </button>
                </Tab>
                <Tab as={Fragment}>
                  <button
                    className={`${
                      currentTab === 1
                        ? "border-sky-500 text-sky-500"
                        : "border-transparent text-gray-900 hover:text-black dark:text-gray-100 dark:hover:text-white hover:border-gray-300"
                    } w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm`}
                  >
                    {t("Following")}
                  </button>
                </Tab>
              </Tab.List>
              <Tab.Panels className="pb-28">
                <Tab.Panel>
                  <UserTab users={followers} isLoading={isLoading} />
                </Tab.Panel>
                <Tab.Panel>
                  <UserTab users={following} isLoading={isLoading} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}
