/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import endpoints from "../../api/endpoints";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function NewSectionModal(props) {
  // i18n
  const { t } = useTranslation();

  const axiosPrivate = useAxiosPrivate();
  const [title, setTitle] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const confirmHandler = async (e) => {
    try {
      e.stopPropagation();

      setIsFetching(true);

      // Check if file is selected
      if (title.trim() !== "") {
        await axiosPrivate.post(
          `${endpoints.QUESTIONSETS_URL}/${props.quiz.id}/sections`,
          { title }
        );

        props.setOpen(false);
        props.refetch();
        // Success msg
        toast.success(`${t("New Section Created")}!`);
      }
    } catch (error) {
      console.error(error);
      toast.error(`${t("Error occurred")}!`);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                confirmHandler(e);
              }}
              className="inline-block bg-white dark:bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full p-6"
            >
              <div>
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-black dark:text-white mb-4"
                  >
                    {t("New Section")}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="mt-1">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoFocus
                        onChange={(e) => setTitle(e.target.value)}
                        className="shadow-sm block w-full sm:text-sm border-gray-300 dark:bg-gray-800 dark:border-gray-600 dark:text-white rounded-md"
                        placeholder={t("Awesome Section...")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 sm:col-start-2 sm:text-sm"
                  disabled={isFetching}
                >
                  {t("Create")}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-black hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => props.setOpen(false)}
                >
                  {t("Cancel")}
                </button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
