import React, { useState, useEffect } from "react";
import Quizzes from "./QuizTableItem";
import { useTranslation } from "react-i18next";
function QuizTable({ quizzes, isLoading, showStatus = false }) {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
      {isLoading &&
        Array.from(Array(12).keys()).map((item, idx) => (
          <div
            key={idx}
            className="h-24 animated-ping bg-white dark:bg-gray-800 shadow rounded-md"
          ></div>
        ))}
      {!isLoading &&
        quizzes.map((quiz) => (
          <Quizzes key={quiz.id} quiz={quiz} showStatus={showStatus} />
        ))}
    </div>
  );
}

export default QuizTable;
