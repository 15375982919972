/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PaymentModal(props) {
  const cancelButtonRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  // i18n
  const { t } = useTranslation();

  const onClose = () => {
    props.setOpen(false);

    // Reset searchParams
    searchParams.delete("paymentStatus");
    searchParams.delete("stripeSessionId");
    setSearchParams(searchParams);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-white dark:bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full p-6">
              <div>
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-black dark:text-white mb-4"
                  >
                    {props?.paymentInfo?.title}
                  </Dialog.Title>
                </div>
                <div className="mt-2 space-y-4">
                  <p className="text-sm text-gray-500 dark:text-gray-200 break-words">
                    {props.paymentInfo.description &&
                      props.paymentInfo.description}
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-200 break-words">
                    {props.paymentInfo.ref &&
                      `${t("Ref")}: ${props.paymentInfo.ref}`}
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-black hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => onClose()}
                  ref={cancelButtonRef}
                >
                  {t("Understand")}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
