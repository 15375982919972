import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";

import ProcessingBtn from "../../components/btn/ProcessingBtn";
import axios from "../../api/axios";
import endpoints from "../../api/endpoints";

import Danger from "../../components/alert/Danger";
import Success from "../../components/alert/Success";
import { useTranslation } from "react-i18next";

const SignUpComponent = ({ redirect = true, setOpen, setToken, setPage }) => {
  // i18n
  const { t } = useTranslation();

  // Navigation
  const navigate = useNavigate();

  // Warning
  const [warning, setWarning] = useState();

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    try {
      const res = await axios.post(
        endpoints.REGISTER_URL,
        JSON.stringify(formik.values),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const token = res?.data?.id;

      if (redirect) {
        navigate(`/mfa/${token}`);
      } else {
        setToken(token);
        setPage("mfa");
      }
    } catch (err) {
      // Scroll to top
      window.scrollTo({ top: 0, behavior: "smooth" });

      console.log(err);

      if (!err?.response) {
        console.error("No Server res");
      } else {
        const error = err?.response?.data?.error;

        setWarning(<Danger title={error.msg} />);
      }
    } finally {
      setLoading(false);
    }
  };

  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      tnc: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("This is not a valid email"))
        .required(t("This field is required")),
      name: Yup.string().trim().required(t("This field is required")),
      password: Yup.string()
        .trim()
        .min(8, t("Password must be at least 8 characters"))
        .required(t("This field is required")),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        t("Passwords mismatched")
      ),
      tnc: Yup.boolean().oneOf([true], t("You must agree to the terms")),
    }),
    onSubmit: submitHandler,
  });

  return (
    <div className="text-black dark:text-white">
      <h1 className="text-3xl text-black dark:text-white font-bold">
        {t("Create your Account")} ✨
      </h1>

      {/* Warning */}
      {warning}

      {/* Form */}
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-2">
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="email">
              {t("Email")} <span className="text-rose-500">*</span>
            </label>
            <input
              name="email"
              className="form-input w-full text-lg sm:text-base dark:bg-gray-800 dark:border-gray-600 dark:text-white"
              type="string"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p className="text-red-600 text-sm">
              {formik.errors.email && formik.touched.email
                ? `${t("Error")}: ${formik.errors.email}`
                : null}
            </p>
          </div>
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="name">
              {t("Name")} <span className="text-rose-500">*</span>
            </label>
            <input
              name="name"
              className="form-input w-full text-lg sm:text-base dark:bg-gray-800 dark:border-gray-600 dark:text-white"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p className="text-red-600 text-sm">
              {formik.errors.name && formik.touched.name
                ? `${t("Error")}: ${formik.errors.name}`
                : null}
            </p>
          </div>
          <div>
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="password"
            >
              {t("Password")} <span className="text-rose-500">*</span>
            </label>
            <input
              name="password"
              className="form-input w-full text-lg sm:text-base dark:bg-gray-800 dark:border-gray-600 dark:text-white"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="">
              <PasswordStrengthBar
                minLength={8}
                scoreWordClassName="text-sm font-medium"
                password={formik.values.password}
                shortScoreWord={t("Too short")}
                scoreWords={[
                  t("weak"),
                  t("weak"),
                  t("okay"),
                  t("good"),
                  t("strong"),
                ]}
              />
            </div>
            <p className="text-red-600 text-sm">
              {formik.errors.password && formik.touched.password
                ? `${t("Error")}: ${formik.errors.password}`
                : null}
            </p>
          </div>
          <div>
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="confirmPassword"
            >
              {t("Confirm Password")} <span className="text-rose-500">*</span>
            </label>
            <input
              name="confirmPassword"
              className="form-input w-full text-lg sm:text-base dark:bg-gray-800 dark:border-gray-600 dark:text-white"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p className="text-red-600 text-sm">
              {formik.errors.confirmPassword && formik.touched.confirmPassword
                ? `${t("Error")}: ${formik.errors.confirmPassword}`
                : null}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between mt-6">
          <div className="mr-1">
            <label className="flex items-center">
              <input
                name="tnc"
                type="checkbox"
                className="form-checkbox"
                value={formik.tnc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="text-sm ml-2">
                {t("I agree to the")}{" "}
                <a
                  className="text-sky-500 underline"
                  target="_blank"
                  href="https://practtice.com/terms-of-service"
                >
                  {t("terms of service")}
                </a>{" "}
                {t("and")}{" "}
                <a
                  className="text-sky-500 underline"
                  target="_blank"
                  href="https://practtice.com/privacy-policy"
                >
                  {t("privacy policy")}
                </a>
              </span>
            </label>
            <p className="text-red-600 text-sm">
              {formik.errors.tnc && formik.touched.tnc
                ? `${t("Error")}: ${formik.errors.tnc}`
                : null}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between mt-6">
          {!isLoading && (
            <button
              type="submit"
              className="inline-block btn bg-emerald-500 hover:bg-emerald-600 text-white whitespace-nowrap w-full"
              to="/"
            >
              {t("Sign Up")}
            </button>
          )}
          {isLoading && <ProcessingBtn title={t("Sign Up")} />}
        </div>
      </form>
      {/* Footer */}
      <div className="pt-5 mt-6 border-t border-gray-200">
        <div className="flex justify-center text-sm gap-x-2">
          {t("Have an account?")}{" "}
          {redirect === true ? (
            <Link
              className="font-medium text-emerald-500 hover:text-emerald-600"
              to="/signin"
            >
              {t("Sign In")}
            </Link>
          ) : (
            <button
              type="button"
              className="font-medium text-emerald-500 hover:text-emerald-600"
              onClick={() => {
                setPage("signin");
              }}
            >
              {t("Sign In")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpComponent;
