import React, { useState, useEffect, Fragment } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { Tab } from "@headlessui/react";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";
import BottomAppBar from "../../partials/ui/BottomAppBar";
import QuizTab from "../quizzes/QuizTab";
import RenderUserProfileAvatar from "../../partials/users/RenderUserProfileAvatar";

export default function ProfileHome() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Library
  const { auth } = useAuth();
  const user = auth?.user;
  const { id } = useParams();

  // Get Quizs
  const [currentTab, setCurrentTab] = useState(0);
  const [quizzes, setQuizzes] = useState([]);
  const [targetUser, setTargetUser] = useState();
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });

  const navigate = useNavigate();

  // Get User Profile
  const renderUser = async () => {
    try {
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        console.log("Search String: " + debouncedSearchString);
        params = { search: debouncedSearchString };
      }

      params = { ...params, offset: 0, limit: 12 };

      const res = await axiosPrivate.get(`${endpoints.PROFILE_URL}/${id}`, {
        signal: controller.signal,
        params: params,
      });

      setTargetUser(res?.data);

      // Check if user is yourself
      if (res?.data?.id === user?.id) {
        // Redirect to your own profile
        navigate("/library");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Render Quizs
  const renderQuiz = async () => {
    try {
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        console.log("Search String: " + debouncedSearchString);
        params = { search: debouncedSearchString };
      }

      params = { ...params, offset: 0, limit: 12 };

      const res = await axiosPrivate.get(
        `${endpoints.PROFILE_URL}/${id}/quizzes`,
        {
          signal: controller.signal,
          params: params,
        }
      );

      setQuizzes(res?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const follow = async () => {
    try {
      const res = await axiosPrivate.patch(
        `${endpoints.PROFILE_URL}/${id}/follow`
      );

      // Update targetUser
      setTargetUser({
        ...targetUser,
        isFollowed: res?.data?.isFollowing,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    await Promise.all([renderUser(), renderQuiz()]);
    setLoading(false);
  };

  // Init
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    renderQuiz();
  }, [debouncedSearchString, currentTab]);

  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          title={targetUser?.name || t("Library")}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main className="min-h-screen flex flex-col">
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
            {/* User Page */}
            <div className="md:py-8">
              <div className="flex items-center justify-center gap-x-4 md:gap-x-10">
                <RenderUserProfileAvatar user={targetUser} />
                <div className="space-y-2 items-center">
                  <div className="relative flex items-center max-w-48 truncate">
                    <div className="text-lg md:text-3xl leading-6 font-bold text-black dark:text-white line-clamp-2">
                      {targetUser?.name}
                    </div>
                    {targetUser?.isVerifiedAccount === true && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="ml-1 w-6 h-6 text-sky-600"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </div>

                  {/* Follow Btn */}
                  <div>
                    {targetUser?.isFollowed !== null &&
                      (targetUser?.isFollowed === true ? (
                        <button
                          onClick={() => {
                            follow();
                          }}
                          className="flex items-center px-10 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black dark:text-black dark:bg-white"
                        >
                          {t("Following")}
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            follow();
                          }}
                          className="inline-flex items-center px-10 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600"
                        >
                          {t("Follow")}
                        </button>
                      ))}
                  </div>

                  {/* Statistics */}
                  <div className="hidden md:grid grid-cols-3">
                    <div className="text-base text-black dark:text-white">
                      <span className="font-bold">
                        {targetUser?.quizCount || 0}
                      </span>{" "}
                      {t("quizzes")}
                    </div>
                    <Link
                      to="followers"
                      className="text-base text-black dark:text-white"
                    >
                      <span className="font-bold">{user?.followers || 0}</span>{" "}
                      {t("followers")}
                    </Link>
                    <Link
                      to="following"
                      className="text-base text-black dark:text-white"
                    >
                      <span className="font-bold">{user?.following || 0}</span>{" "}
                      {t("following")}
                    </Link>
                  </div>

                  {/* Bio */}
                  <div className="text-sm text-black dark:text-white line-clamp-3">
                    {targetUser?.bio}
                  </div>
                </div>
              </div>
            </div>

            {/* Statistics */}
            <div className="grid md:hidden grid-cols-3 text-center py-4 my-4 border-y border-gray-500">
              <div className="text-base text-black dark:text-white">
                <span className="font-bold">{targetUser?.quizCount || 0}</span>{" "}
                {t("quizzes")}
              </div>
              <Link
                to="followers"
                className="text-base text-black dark:text-white"
              >
                <span className="font-bold">{targetUser?.followers || 0}</span>{" "}
                {t("followers")}
              </Link>
              <Link
                to="following"
                className="text-base text-black dark:text-white"
              >
                <span className="font-bold">{targetUser?.following || 0}</span>{" "}
                {t("following")}
              </Link>
            </div>

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-black dark:text-white font-bold">
                  {t("All Quizzes")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2">
                {/* Search form */}
                <SearchForm value={searchString} setValue={setSearchString} />
              </div>
            </div>
          </div>

          <div className="pb-24">
            <QuizTab
              quizzes={quizzes}
              isLoading={isLoading}
              searchString={searchString}
            />
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}
