/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDebouncedValue } from "@mantine/hooks";
import { Combobox } from "@headlessui/react";

import { useFormik } from "formik";
import * as Yup from "yup";

import endpoints from "../../api/endpoints";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function SelectUserModal(props) {
  const cancelButtonRef = useRef(null);

  // i18n
  const { t } = useTranslation();

  const axiosPrivate = useAxiosPrivate();
  const [isFetching, setIsFetching] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [userList, setUserList] = useState([]);

  const searchUser = async (search) => {
    try {
      const res = await axiosPrivate.get(endpoints.SEARCH_USER_URL, {
        params: {
          search,
        },
        withCredentials: true,
      });

      setUserList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (debouncedSearchString) {
      searchUser(debouncedSearchString);
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    if (props.quiz?.user) {
      setSelectedUser(props.quiz?.user);
    }
  }, [props.quiz?.user]);

  const submitHandler = async (values, { setErrors, resetForm }) => {
    try {
      setIsFetching(true);

      await axiosPrivate.patch(
        endpoints.QUESTIONSETS_URL + "/" + props.quiz.id,
        JSON.stringify(formik.values),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      props.setOpen(false);

      // Update the quiz in quizzes
      props.setQuizzes((prevQuizzes) => {
        let newQuizzes = [...prevQuizzes];
        let quizIndex = newQuizzes.findIndex(
          (quiz) => quiz.id === props.quiz.id
        );
        newQuizzes[quizIndex] = {
          ...newQuizzes[quizIndex],
          user: selectedUser,
        };
        return newQuizzes;
      });

      // Success msg
      toast.success(`${t("Quiz Updated")}!`);
    } catch (err) {
      console.log(err);

      if (!err?.response) {
        console.error("No Server res");
      } else {
        let error = err?.response?.data?.errors?.msg;
        console.error(error);
        toast.error(`${t("Error occurred")}!`);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      user: props?.quiz?.user?.id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      user: Yup.string().trim().required(t("This field is required")),
    }),
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (props.open === true) {
      formik.resetForm();
    }
  }, [props.open]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <div className="h-screen flex items-center justify-center text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={formik.handleSubmit}
              className="block bg-white dark:bg-black px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:align-middle w-full h-full sm:p-6"
            >
              <div>
                <div className="flex justify-between items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-left text-lg leading-6 font-medium text-black dark:text-white mb-4"
                  >
                    {t("Transfer Ownership")}
                  </Dialog.Title>
                  <button
                    type="button"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                    className="block rounded-full bg-gray-100 text-gray-500 hover:text-black"
                  >
                    <span className="sr-only">Back</span>
                    <svg width="32" height="32" viewBox="0 0 32 32">
                      <path
                        className="fill-current"
                        d="M15.95 14.536l4.242-4.243a1 1 0 111.415 1.414l-4.243 4.243 4.243 4.242a1 1 0 11-1.415 1.415l-4.242-4.243-4.243 4.243a1 1 0 01-1.414-1.415l4.243-4.242-4.243-4.243a1 1 0 011.414-1.414l4.243 4.243z"
                      />
                    </svg>
                  </button>
                </div>

                {/* Form */}
                <div className="h-[calc(100vh-228px)] sm:h-[calc(100vh-168px)] mt-2 space-y-4 overflow-scroll">
                  <div>
                    <label
                      className="text-left block text-sm font-medium mb-1 dark:text-white"
                      htmlFor="name"
                    >
                      {t("User")} <span className="text-rose-500">*</span>
                    </label>
                    <Combobox
                      value={selectedUser}
                      onChange={(user) => {
                        // Input
                        setSelectedUser(user);

                        // Update formik
                        formik.setFieldValue("user", user.id);
                      }}
                    >
                      <Combobox.Input
                        onChange={(event) =>
                          setSearchString(event.target.value)
                        }
                        displayValue={(user) => user?.name}
                        className="shadow-sm block w-full sm:text-sm border-gray-300 dark:bg-gray-800 dark:border-gray-600 dark:text-white rounded-md"
                      />
                      <Combobox.Options className="shadow-md rounded-b-md">
                        {userList &&
                          userList.map((user) => (
                            /* Use the `active` state to conditionally style the active option. */
                            /* Use the `selected` state to conditionally style the selected option. */
                            <Combobox.Option
                              key={user.id}
                              value={user}
                              as={Fragment}
                            >
                              {({ active, selected }) => (
                                <li
                                  className={` ${
                                    active
                                      ? "bg-sky-500 text-white hover:bg-sky-600"
                                      : "hover:bg-gray-100 dark:hover:bg-gray-700"
                                  } px-4 py-2 border-b border-gray-200 dark:border-gray-600 cursor-pointer last:rounded-b-md`}
                                >
                                  <div className="flex items-center space-x-2">
                                    {selected && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M4.5 12.75l6 6 9-13.5"
                                        />
                                      </svg>
                                    )}
                                    {user.name}
                                  </div>
                                </li>
                              )}
                            </Combobox.Option>
                          ))}
                      </Combobox.Options>
                    </Combobox>
                    <input
                      id="user"
                      name="user"
                      className="hidden"
                      type="text"
                      autoFocus={true}
                      value={formik.values.user}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.user && formik.touched.user
                        ? `${t("Error")}: ${formik.errors.user}`
                        : null}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 sm:col-start-2 sm:text-sm"
                  disabled={isFetching}
                >
                  {t("Update")}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-black hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => props.setOpen(false)}
                  ref={cancelButtonRef}
                >
                  {t("Cancel")}
                </button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
