import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { Tab } from "@headlessui/react";
import { useLocalStorage } from "@mantine/hooks";
import superjson from "superjson";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";
import NewQuizModal from "../../partials/quizzes/NewQuizModal";
import BottomAppBar from "../../partials/ui/BottomAppBar";
import RenderUserProfileAvatar from "../../partials/users/RenderUserProfileAvatar";
import QuizTable from "../../partials/quizzes/QuizTable";

export default function LibraryHome() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { t } = useTranslation();

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Library
  const { auth, setAuth } = useAuth();
  const user = auth?.user;

  // Wrong Quiz
  const [wrongQuizzes, setWrongQuizzes] = useState([]);
  const [recentQuizzes, setRecentQuizzes] = useState([]);

  // Render Quizs
  const getQuizSimpleJson = async (ids, visibility) => {
    try {
      const res = await axiosPrivate.get(endpoints.EXPLORE_URL + "/continue", {
        signal: controller.signal,
        params: {
          ids,
          visibility,
        },
      });

      let quizzes = res.data || [];

      // Sort quizzes by ids
      quizzes = quizzes.sort((a, b) => {
        return ids.indexOf(a.id) - ids.indexOf(b.id);
      });

      return quizzes;
    } catch (error) {
      console.error(error);
    }
  };

  // Recent History
  const [viewHistory] = useLocalStorage({
    key: `viewHistory`,
    defaultValue: [],
    serialize: superjson.stringify,
    deserialize: (array) => (array === undefined ? [] : superjson.parse(array)),
    getInitialValueInEffect: false,
  });

  // Wrong History
  const [wrongHistory] = useLocalStorage({
    key: `wrongHistory`,
    defaultValue: new Set(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Set() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  const renderWrongDB = async () => {
    const wrongQuizIds = new Set();
    const wrongQuestionNum = new Map();

    for (const raw of wrongHistory) {
      const setId = raw.split(":")[0];
      const questionId = raw.split(":")[1];
      const quizId = raw.split(":").length === 3 ? raw.split(":")[2] : null;

      if (quizId) {
        wrongQuizIds.add(quizId);

        // Increment count in wrongQuestionNum
        if (wrongQuestionNum.has(quizId)) {
          wrongQuestionNum.set(quizId, wrongQuestionNum.get(quizId) + 1);
        } else {
          wrongQuestionNum.set(quizId, 1);
        }
      }
    }

    // Sort the wrongQuizIds by wrongQuestionNum
    let sortedWrongQuizIds = [...wrongQuizIds].sort(
      (a, b) => wrongQuestionNum.get(b) - wrongQuestionNum.get(a)
    );

    // Get the top 6 elements
    sortedWrongQuizIds = sortedWrongQuizIds.slice(0, 6);

    let quizzes = [];
    if (sortedWrongQuizIds.length > 0) {
      quizzes = await getQuizSimpleJson(sortedWrongQuizIds, 1);

      quizzes = quizzes.map((quiz) => {
        return {
          ...quiz,
          count: wrongQuestionNum.get(quiz.id),
        };
      });
    }

    setWrongQuizzes(quizzes);
  };

  useEffect(() => {
    renderWrongDB();
  }, []);

  // Get Quizs
  const tabMapping = [
    endpoints.SELF_PURCHASED_QUIZZES,
    endpoints.SELF_BOOKMARKED_QUIZZES,
    endpoints.SELF_QUIZZES,
  ];
  const [currentTab, setCurrentTab] = useState(0);
  const [pQuizzes, setPQuizzes] = useState([]);
  const [pLoading, setPLoading] = useState(true);
  const [bQuizzes, setBQuizzes] = useState([]);
  const [bLoading, setBLoading] = useState(true);
  const [sQuizzes, setSQuizzes] = useState([]);
  const [sLoading, setSLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });

  // Render Quizs
  const renderQuiz = async (idx, setQuiz, setLoadingHandler) => {
    try {
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        console.log("Search String: " + debouncedSearchString);
        params = { search: debouncedSearchString };
      }

      params = { ...params, offset: 0, limit: 12 };

      const res = await axiosPrivate.get(tabMapping[idx], {
        signal: controller.signal,
        params: params,
      });

      setQuiz(res?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHandler(false);
    }
  };

  // Get User Profile
  const getUserProfile = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PROFILE_URL, {
        signal: controller.signal,
      });

      // Check if user is the same
      if (res.data !== auth?.user) {
        // Update auth
        setAuth({ ...auth, user: res.data });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    renderQuiz(0, setPQuizzes, setPLoading);
    renderQuiz(1, setBQuizzes, setBLoading);
    renderQuiz(2, setSQuizzes, setSLoading);
    getUserProfile();
  }, []);

  useEffect(() => {
    // Render Quizs
    if (currentTab === 0) {
      renderQuiz(0, setPQuizzes, setPLoading);
    }
    if (currentTab === 1) {
      renderQuiz(1, setBQuizzes, setBLoading);
    }
    if (currentTab === 2) {
      renderQuiz(2, setSQuizzes, setSLoading);
    }
  }, [debouncedSearchString, currentTab]);

  const createQuiz = async () => {
    try {
      setCreateModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          title="Library"
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main className="min-h-screen flex flex-col">
          {!isLoading && (
            <div>
              <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
                {/* User Page */}
                <div className="my-4">
                  <div className="flex items-center justify-center gap-x-4 md:gap-x-10">
                    <RenderUserProfileAvatar user={user} />
                    <div className="space-y-2">
                      <div className="relative flex items-center max-w-48 truncate">
                        <div className="text-lg md:text-3xl leading-6 font-bold text-black dark:text-white line-clamp-2">
                          {user?.name}
                        </div>
                        {user?.isVerifiedAccount === true && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="ml-1 w-6 h-6 text-sky-600"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </div>

                      {/* Statistics */}
                      <div className="hidden md:grid grid-cols-3">
                        <div className="text-base text-black dark:text-white">
                          <span className="font-bold">
                            {user?.quizCount || 0}
                          </span>{" "}
                          {t("quizzes")}
                        </div>
                        <Link
                          to="followers"
                          className="text-base text-black dark:text-white"
                        >
                          <span className="font-bold">
                            {user?.followers || 0}
                          </span>{" "}
                          {t("followers")}
                        </Link>
                        <Link
                          to="following"
                          className="text-base text-black dark:text-white"
                        >
                          <span className="font-bold">
                            {user?.following || 0}
                          </span>{" "}
                          {t("following")}
                        </Link>
                      </div>

                      {user.bio && (
                        <div className="text-sm text-black dark:text-white line-clamp-3">
                          {user.bio}
                        </div>
                      )}

                      {/* Setting Btn */}
                      <div className="flex items-center space-x-2">
                        <Link
                          to={"/settings"}
                          className="inline-flex items-center sm:px-8 px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black dark:text-black dark:bg-white"
                        >
                          {t("Settings")}
                        </Link>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            createQuiz();
                          }}
                          className="inline-flex items-center sm:px-8 px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 dark:bg-emerald-600 dark:hover:bg-emerald-700"
                        >
                          {t("Create Quiz")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Statistics */}
                <div className="grid md:hidden grid-cols-3 text-center py-2 my-2 sm:py-4 sm:my-4 border-y border-gray-500">
                  <div className="sm:text-sm text-xs text-black dark:text-white">
                    <span className="font-bold">{user?.quizCount || 0}</span>{" "}
                    {t("quizzes")}
                  </div>
                  <Link
                    to="followers"
                    className="sm:text-sm text-xs text-black dark:text-white"
                  >
                    <span className="font-bold">{user?.followers || 0}</span>{" "}
                    {t("followers")}
                  </Link>
                  <Link
                    to="following"
                    className="sm:text-sm text-xs text-black dark:text-white"
                  >
                    <span className="font-bold">{user?.following || 0}</span>{" "}
                    {t("following")}
                  </Link>
                </div>

                {/* Modal */}
                <NewQuizModal
                  open={createModalOpen}
                  setOpen={setCreateModalOpen}
                />

                <Tab.Group
                  onChange={(index) => {
                    setCurrentTab(index);
                  }}
                >
                  <Tab.List className="flex items-center justify-center gap-x-4">
                    <Tab as={Fragment}>
                      <button
                        className={`${
                          currentTab === 0
                            ? "border-sky-500 text-sky-500"
                            : "border-transparent text-gray-900 hover:text-black dark:text-gray-100 dark:hover:text-white hover:border-gray-300"
                        } w-1/4 py-4 px-1 text-center border-b-2 font-medium sm:text-sm text-xs`}
                      >
                        {/* {t("Purchased")} */}
                        {t("My Quizzes")}
                      </button>
                    </Tab>
                    <Tab as={Fragment}>
                      <button
                        className={`${
                          currentTab === 1
                            ? "border-sky-500 text-sky-500"
                            : "border-transparent text-gray-900 hover:text-black dark:text-gray-100 dark:hover:text-white hover:border-gray-300"
                        } w-1/4 py-4 px-1 text-center border-b-2 font-medium sm:text-sm text-xs`}
                      >
                        {t("Bookmarked")}
                      </button>
                    </Tab>
                    <Tab as={Fragment}>
                      <button
                        className={`${
                          currentTab === 2
                            ? "border-sky-500 text-sky-500"
                            : "border-transparent text-gray-900 hover:text-black dark:text-gray-100 dark:hover:text-white hover:border-gray-300"
                        } w-1/4 py-4 px-1 text-center border-b-2 font-medium sm:text-sm text-xs`}
                      >
                        {t("Redo")}
                      </button>
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className="pb-28">
                    <Tab.Panel>
                      <div>
                        <QuizTable
                          isLoading={sLoading && pLoading}
                          quizzes={[...sQuizzes, ...pQuizzes]}
                        />
                      </div>
                    </Tab.Panel>
                    <Tab.Panel>
                      <div>
                        <QuizTable isLoading={bLoading} quizzes={bQuizzes} />
                      </div>
                    </Tab.Panel>
                    <Tab.Panel>
                      <div>
                        <QuizTable isLoading={false} quizzes={wrongQuizzes} />
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          )}

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}
