import React from "react";
import { useTranslation } from "react-i18next";

const VisibilityStatus = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case 0:
      return (
        <div className="text-xs block font-medium bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1 whitespace-nowrap">
          {t("Public")}
        </div>
      );
    case 1:
      return (
        <div className="text-xs block font-medium bg-amber-100 text-amber-600 rounded-full text-center px-2.5 py-1 whitespace-nowrap">
          {t("Unlisted")}
        </div>
      );
    case 2:
      return (
        <div className="text-xs block font-medium bg-rose-100 text-rose-600 rounded-full text-center px-2.5 py-1 whitespace-nowrap">
          {t("Private")}
        </div>
      );
    default:
      return (
        <div className="text-xs inline-flex font-medium bg-gray-100 text-black rounded-full text-center px-2.5 py-1 whitespace-nowrap">
          {t("Unknown")}
        </div>
      );
  }
};

export default VisibilityStatus;
