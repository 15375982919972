import React, { useState, useEffect } from "react";
import User from "./UserTableItem";

export default function UserTable({ users, isLoading, showStatus = true }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
      {isLoading &&
        Array.from(Array(12).keys()).map((item, idx) => (
          <div
            key={idx}
            className="h-20 animated-ping bg-white dark:bg-gray-800 shadow rounded-md"
          ></div>
        ))}
      {!isLoading && users.map((user) => <User key={user.id} user={user} />)}
    </div>
  );
}
