import React, { useEffect, useState } from "react";
import {
  Link,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RadioGroup } from "@headlessui/react";
import moment from "moment";

import { ReactComponent as Logo } from "../../images/logo/Logo.svg";
import ProcessingBtn from "../../components/btn/ProcessingBtn";
import ModalBlank from "../../components/ModalBlank";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import Loader from "../../partials/Loader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Pay() {
  const [card, setCard] = useState(true);
  const [selected, setSelected] = useState({});
  const [step, setStep] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [premiumPlan, setPremiumPlan] = useState([]);
  const [businessPlan, setBusinessPlan] = useState([]);
  const [isInit, setIsInit] = useState(false);
  const [user, setUser] = useState();

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const navigate = useNavigate();
  const location = useLocation();

  const getUserProfile = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PROFILE_URL, {
        signal: controller.signal,
      });
      setUser(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getProducts = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PRODUCTS_URL, {
        signal: controller.signal,
      });

      let premiumArr = res.data.filter((item) => item.productId === "Premium");
      premiumArr = premiumArr.sort((a, b) => a.price - b.price);
      const businessArr = res.data.filter(
        (item) => item.productId === "Business"
      );

      setPremiumPlan(premiumArr);
      setBusinessPlan(businessArr);
      setSelected(premiumArr[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsInit(true);
    }
  };

  useEffect(() => {
    getUserProfile();
    getProducts();
  }, []);

  useEffect(() => {
    // if selected is business plan, go to step 1 and show modal
    if (selected.productId === "Business") {
      toStep(1);
    }

    formik.setFieldValue("planId", selected.id);
  }, [selected]);

  const claimPremium = async (planId) => {
    try {
      await axiosPrivate.post(endpoints.PLAN_URL + "/" + planId + "/purchase", {
        signal: controller.signal,
      });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const payHandlerTest = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    console.log(values);
    setTimeout(() => {
      setIsSuccess(true);
      setLoading(false);
    }, 2000);
  };

  const paypalHandlerTest = async () => {
    setLoading(true);
    setTimeout(() => {
      setIsSuccess(true);
      setLoading(false);
    }, 2000);
  };

  const paypalHandler = async () => {
    setLoading(true);
    const isSuccess = await claimPremium(selected.id);
    if (isSuccess) {
      setIsSuccess(true);
    }
    setLoading(false);
  };

  const payHandler = async (values, { setErrors, resetForm }) => {
    paypalHandler();
  };

  const formik = useFormik({
    initialValues: {
      planId: "",
      card: "",
      expiry: "",
      cvc: "",
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      planId: Yup.string().trim().required(),
      card: Yup.string().trim().required("Card is required").max(16),
      expiry: Yup.string().required("Expiration date is required"),
      cvc: Yup.string().max(4),
      name: Yup.string().trim().required("Name is required"),
      email: Yup.string()
        .email("This is not a valid email")
        .required("Email is required"),
    }),
    onSubmit: payHandler,
  });

  const toStep = (step) => {
    if (selected.productId === "Business") {
      setStep(1);
    } else {
      setStep(step);
    }
  };

  const formatCard = (number) => {
    return number.split("").reduce((seed, next, index) => {
      if (index !== 0 && !(index % 4)) seed += " ";
      return seed + next;
    }, "");
  };

  const formatExpiry = (number) => {
    const dateString = number.split("").reduce((seed, next, index) => {
      if (index !== 0 && !(index % 2)) seed += "/";
      return seed + next;
    }, "");

    return dateString;
  };

  const pricePage = () => {
    return (
      <>
        <div className="flex flex-col text-center py-4 mb-6 gap-y-10">
          <h1 className="text-2xl leading-snug text-black font-bold">
            Choose Your Plan
          </h1>
        </div>
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">
            Subscription Plan
          </RadioGroup.Label>
          {premiumPlan.length > 0 && (
            <div className="mb-2">
              <div className="text-left font-light text-gray-500 mb-2">
                For Individuals
              </div>
              <div className="bg-white rounded-md -space-y-px text-left">
                {renderPriceList(premiumPlan)}
              </div>
            </div>
          )}

          {businessPlan.length > 0 && (
            <div className="mb-2">
              <div className="text-left font-light text-gray-500 mb-2">
                For Teams
              </div>
              <div className="bg-white rounded-md -space-y-px text-left">
                {renderPriceList(businessPlan)}
              </div>
            </div>
          )}
        </RadioGroup>
      </>
    );
  };

  const paymentPage = () => {
    return (
      <>
        <div className="relative flex flex-col py-4 mb-6 gap-y-10">
          <span className="inline-block">
            <button
              onClick={() => {
                toStep(1);
              }}
              className="float-left"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                className="w-6 h-6 text-gray-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
              </svg>
            </button>
            <h1 className="text-2xl text-center leading-snug text-black font-bold">
              Selected Plan
            </h1>
          </span>
          <span
            key={selected.name}
            value={selected}
            className="rounded border-emerald-500 border-2 z-10 relative border p-4 flex cursor-pointer focus:outline-none items-center justify-start h-32 sm:h-24"
          >
            <div className="w-full flex justify-between items-center sm:px-2">
              <div className="flex flex-row items-center gap-x-4 ml-2">
                <div className="relative flex items-center justify-center">
                  <div className="absolute h-6 w-6 bg-emerald-500 rounded-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={3}
                      stroke="currentColor"
                      className="w-3 h-3 stroke-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-3 flex flex-col">
                  <span className="text-emerald-900 block text-sm font-medium">
                    {selected.isPopular && (
                      <div className=" text-xs inline-flex font-medium bg-amber-100 text-amber-600 rounded-md text-center px-2">
                        Most Popular
                      </div>
                    )}
                    <div className="text-xl font-bold">{selected.title}</div>
                  </span>
                  <span className="text-emerald-700 block text-sm">
                    {selected.description}
                  </span>
                </div>
              </div>

              {/* Price */}
              <div className="flex flex-col items-start text-md font-semibold gap-x-1">
                <div>
                  <span className="text-xl whitespace-nowrap">
                    {selected.currency}
                    {selected.price}
                  </span>
                  <span className="text-gray-500"> {selected.unit}</span>
                </div>
                {/* Badges */}
                <div
                  className={classNames(
                    selected.badge ? "" : "invisible",
                    "text-xs inline-flex font-medium bg-emerald-100 text-emerald-500 rounded-md text-center px-2"
                  )}
                >
                  {selected.badge}
                </div>
              </div>
            </div>
          </span>{" "}
        </div>
        {/* Toggle */}
        <div className="flex justify-center mb-6">
          <div className="relative flex w-full p-1 bg-gray-50 rounded">
            <span
              className="absolute inset-0 m-1 pointer-events-none"
              aria-hidden="true"
            >
              <span
                className={`absolute inset-0 w-1/2 bg-white rounded border border-gray-200 shadow-sm transition duration-150 ease-in-out ${
                  card ? "translate-x-0" : "translate-x-full"
                }`}
              ></span>
            </span>
            <button
              className="relative flex-1 text-sm font-medium p-1 duration-150 ease-in-out"
              onClick={(e) => {
                e.preventDefault();
                setCard(true);
              }}
            >
              Pay With Card
            </button>
            <button
              className="relative flex-1 text-sm font-medium p-1 duration-150 ease-in-out"
              onClick={(e) => {
                e.preventDefault();
                setCard(false);
              }}
            >
              Pay With PayPal
            </button>
          </div>
        </div>

        {/* Card form */}
        {card && (
          <form onSubmit={formik.handleSubmit}>
            {" "}
            <div className="space-y-4">
              {/* Card Number */}
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="card"
                >
                  Card Number <span className="text-rose-500">*</span>
                </label>
                <input
                  id="card"
                  name="card"
                  className="form-input w-full"
                  type="text"
                  placeholder="1234 1234 1234 1234"
                  maxLength="19"
                  value={formatCard(formik.values.card.replaceAll(" ", ""))}
                  onChange={(e) => {
                    e.target.value = e.target.value.replaceAll(" ", "");
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                />
                <p className="text-red-600 text-sm">
                  {formik.errors.card && formik.touched.card
                    ? `Error: ${formik.errors.card}`
                    : null}
                </p>
              </div>
              {/* Expiry and CVC */}
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="expiry"
                  >
                    Expiry Date <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="expiry"
                    name="expiry"
                    className="form-input w-full"
                    type="text"
                    placeholder="MM/YY"
                    maxLength="5"
                    value={formatExpiry(formik.values.expiry)}
                    onChange={(e) => {
                      e.target.value = e.target.value.replaceAll("/", "");
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <p className="text-red-600 text-sm">
                    {formik.errors.expiry && formik.touched.expiry
                      ? `Error: ${formik.errors.expiry}`
                      : null}
                  </p>
                </div>
                <div className="flex-1">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="cvc"
                  >
                    CVC <span className="text-rose-500">*</span>
                  </label>
                  <input
                    id="cvc"
                    name="cvc"
                    className="form-input w-full"
                    type="password"
                    placeholder="CVC"
                    maxLength="4"
                    value={formik.values.cvc}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="text-red-600 text-sm">
                    {formik.errors.cvc && formik.touched.cvc
                      ? `Error: ${formik.errors.cvc}`
                      : null}
                  </p>
                </div>
              </div>
              {/* Name on Card */}
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  Name on Card <span className="text-rose-500">*</span>
                </label>
                <input
                  id="name"
                  name="name"
                  className="form-input w-full"
                  type="text"
                  placeholder="John Doe"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <p className="text-red-600 text-sm">
                  {formik.errors.name && formik.touched.name
                    ? `Error: ${formik.errors.name}`
                    : null}
                </p>
              </div>
              {/* Email */}
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="email"
                >
                  Email <span className="text-rose-500">*</span>
                </label>
                <input
                  id="email"
                  name="email"
                  className="form-input w-full"
                  type="email"
                  placeholder="john@company.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <p className="text-red-600 text-sm">
                  {formik.errors.email && formik.touched.email
                    ? `Error: ${formik.errors.email}`
                    : null}
                </p>
              </div>
            </div>
            {/* htmlForm footer */}
            <div className="mt-6">
              <div className="mb-4">
                {!isLoading && (
                  <button
                    className="btn w-full bg-emerald-500 hover:bg-emerald-600 text-white"
                    type="submit"
                  >
                    Pay {selected.currency}
                    {selected.billPrice}
                  </button>
                )}
                {isLoading && (
                  <ProcessingBtn
                    title={`Processing Payment ${selected.currency}${selected.billPrice}`}
                  />
                )}
              </div>
              <div className="text-xs text-gray-500 text-center">
                Pay Securely With Stripe
              </div>
            </div>
          </form>
        )}

        {/* PayPal htmlForm */}
        {!card && (
          <div>
            <div>
              <div className="mb-4">
                {!isLoading && (
                  <button
                    className="btn w-full bg-emerald-500 hover:bg-emerald-600 text-white"
                    onClick={() => {
                      paypalHandler();
                    }}
                  >
                    Pay with PayPal - {selected.currency}
                    {selected.billPrice}
                  </button>
                )}
                {isLoading && (
                  <ProcessingBtn
                    title={`Processing Payment ${selected.currency}${selected.billPrice}`}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderPriceList = (planList) => {
    return planList.map((planItem, idx) => (
      <RadioGroup.Option
        key={planItem.title}
        value={planItem}
        className={({ checked }) =>
          classNames(
            idx === 0 ? "rounded-tl-md rounded-tr-md" : "",
            idx === planList.length - 1 ? "rounded-bl-md rounded-br-md" : "",
            checked ? "border-emerald-500 border-2 z-10" : "border-gray-200",
            "relative border p-4 flex cursor-pointer focus:outline-none items-center h-32 sm:h-24"
          )
        }
      >
        {({ active, checked }) => (
          <div className="w-full flex justify-between items-center sm:px-2">
            <div className="flex flex-row items-center gap-x-4 ml-2">
              <div
                className={classNames(
                  checked ? "" : "invisible",
                  "relative flex items-center justify-center"
                )}
              >
                <div className="absolute h-6 w-6 bg-emerald-500 rounded-full flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-3 h-3 stroke-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-3 flex flex-col">
                <RadioGroup.Label
                  as="span"
                  className={classNames(
                    checked ? "text-emerald-900" : "text-black",
                    "block text-sm font-medium"
                  )}
                >
                  {planItem.isPopular && (
                    <div className=" text-xs inline-flex font-medium bg-amber-100 text-amber-600 rounded-md text-center px-2">
                      Most Popular
                    </div>
                  )}
                  <div className="text-xl font-bold">{planItem.title}</div>
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="span"
                  className={classNames(
                    checked ? "text-emerald-700" : "text-gray-500",
                    "block text-sm"
                  )}
                >
                  {planItem.description}
                </RadioGroup.Description>
              </div>
            </div>

            {/* Price */}
            <div className="flex flex-col items-start text-md font-semibold gap-x-1">
              <div>
                <span className="text-xl whitespace-nowrap">
                  {planItem.currency}
                  {planItem.price}
                </span>
                <span className="text-gray-500"> {planItem.unit}</span>
              </div>
              {/* Badges */}
              <div
                className={classNames(
                  planItem.badge ? "" : "invisible",
                  "text-xs inline-flex font-medium bg-emerald-100 text-emerald-500 rounded-md text-center px-2"
                )}
              >
                {planItem.badge}
              </div>
            </div>
          </div>
        )}
      </RadioGroup.Option>
    ));
  };

  useEffect(() => {
    if (selected.productId === "Business") {
      // Turn on the modal
      setIsBusiness(true);
    }
  }, [selected]);

  if (!isInit) {
    return <Loader />;
  } else {
    return (
      <>
        {/* Header */}
        <header className="bg-white">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-20 -mb-px">
              {/* Logo */}
              <Link className="block" to="/">
                {/* Logo */}
                <Logo className="h-8 w-8" />
              </Link>

              {/* Progress bar */}
              <div className="max-w-md mx-auto w-full mt-10">
                <div className="relative">
                  <ul className="relative flex items-center justify-between w-full">
                    <li>
                      <button
                        className={classNames(
                          step === 1 ? "text-emerald-500" : "text-gray-500",
                          "flex flex-col items-center justify-center gap-y-2 p-5 hover:bg-gray-200/20 hover:rounded-md"
                        )}
                        onClick={() => {
                          toStep(1);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                          />
                        </svg>

                        <div className="font-light text-sm">Select Plan</div>
                      </button>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                        />
                      </svg>
                    </li>
                    <li>
                      <button
                        className={classNames(
                          step === 2 ? "text-emerald-500" : "text-gray-500",
                          "flex flex-col items-center justify-center gap-y-2 p-5 hover:bg-gray-200/20 hover:rounded-md"
                        )}
                        onClick={() => {
                          toStep(2);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                          />
                        </svg>

                        <div className="font-light text-sm">
                          Complete Payment
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="flex items-center gap-x-4">
                <div className="font-bold">Hi, {user.name}!</div>

                <Link
                  className="block rounded-full bg-gray-100 text-gray-500 hover:text-black"
                  to="/"
                >
                  <span className="sr-only">Back</span>
                  <svg width="32" height="32" viewBox="0 0 32 32">
                    <path
                      className="fill-current"
                      d="M15.95 14.536l4.242-4.243a1 1 0 111.415 1.414l-4.243 4.243 4.243 4.242a1 1 0 11-1.415 1.415l-4.242-4.243-4.243 4.243a1 1 0 01-1.414-1.415l4.243-4.242-4.243-4.243a1 1 0 011.414-1.414l4.243 4.243z"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </header>

        <main>
          <ModalBlank
            id="business-modal"
            modalOpen={isBusiness}
            setModalOpen={setIsBusiness}
          >
            <div className="p-5 flex space-x-4">
              <div className="flex flex-col w-full justify-center gap-y-2">
                <div className="flex gap-4">
                  {/* Content */}
                  <div className="mb-2">
                    {/* Modal header */}
                    <div className="">
                      <div className="text-lg font-semibold text-black">
                        Thank you for your interest in business plan
                      </div>
                    </div>
                    {/* Modal content */}
                    <div className="text-sm">
                      <div className="space-y-4">
                        <p>
                          We are currently running business plan in close beta.
                          Please contact our sales for more information.
                        </p>
                        <p>
                          Email:{" "}
                          <a
                            href="mailto:info@practtice.com"
                            className="underline text-emerald-500 decoration-emerald-500"
                          >
                            info@practtice.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="flex justify-center w-full space-x-2">
                  <button
                    className="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full"
                    onClick={() => {
                      setIsBusiness(false);
                    }}
                  >
                    Understand
                  </button>
                </div>
              </div>
            </div>
          </ModalBlank>
          <ModalBlank
            id="success-modal"
            modalOpen={isSuccess}
            setModalOpen={setIsSuccess}
          >
            <div className="p-5 flex space-x-4">
              <div className="flex flex-col w-full justify-center gap-y-2">
                <div className="flex gap-x-4">
                  {/* Content */}
                  <div className="mb-2">
                    {/* Modal header */}
                    <div className="">
                      <div className="text-lg font-semibold text-black">
                        Thank you for your support
                      </div>
                    </div>
                    {/* Modal content */}
                    <div className="text-sm">
                      <div className="space-y-2">
                        <p>
                          We are currently under open beta. Therefore, we are
                          not accepting any payments at the moment. However, we
                          appreciate your support and we are giving you a chance
                          to test out our premium plan for free.
                        </p>
                        <p className="text-gray-500 font-light">
                          *Credit card information is not sent to the server.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="flex justify-center w-full space-x-2">
                  <Link
                    className="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full"
                    to="/"
                  >
                    Enjoy your free premium!
                  </Link>
                </div>
              </div>
            </div>
          </ModalBlank>
          <div className="relative pt-12 px-4 sm:px-6 lg:px-8 pb-8 max-w-3xl mx-auto">
            <div className="bg-white sm:px-8 pb-6 rounded-lg sm:shadow-lg">
              {/* Card header */}
              {step === 1 && pricePage()}
              {step === 2 && paymentPage()}

              {step === 1 && (
                <button
                  className={classNames(
                    selected.productId === "Business"
                      ? "bg-emerald-300"
                      : "bg-emerald-500 hover:bg-emerald-600",
                    "w-full btn text-white"
                  )}
                  onClick={() => {
                    toStep(2);
                  }}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Pay;
