import React from "react";

function PaginationNumeric(props) {
  return (
    <div className="flex justify-center">
      <nav className="flex" role="navigation" aria-label="Navigation">
        <div className="mr-2">
          <button
            onClick={() => {
              props.pagination.previous();
            }}
            className={`inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-600 hover:bg-emerald-500 hover:text-white dark:text-gray-400 shadow-sm`}
          >
            <span className="sr-only">Previous</span>
            <wbr />
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
            </svg>
          </button>
        </div>
        <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
          {props.pagination.range.map((page, idx) => {
            if (page === props.page) {
              // Return current page
              return (
                <li key={idx} className="first:rounded-l last:rounded-r">
                  <button
                    onClick={() => {
                      props.pagination.setPage(page);
                    }}
                    className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-600 text-emerald-500 dark:text-white"
                  >
                    {page}
                  </button>
                </li>
              );
            } else if (page !== "dots") {
              // Return the number page
              return (
                <li key={idx} className="first:rounded-l last:rounded-r">
                  <button
                    onClick={() => {
                      props.pagination.setPage(page);
                    }}
                    className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white dark:bg-gray-800 hover:bg-emerald-500 border border-gray-400 dark:border-gray-600 text-black dark:text-gray-400 hover:text-white"
                  >
                    {page}
                  </button>
                </li>
              );
            }
            // Return the dots page
            else
              return (
                <li key={idx}>
                  <span className="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-600 text-gray-400">
                    …
                  </span>
                </li>
              );
          })}
        </ul>
        <div className="ml-2">
          <button
            onClick={() => {
              props.pagination.next();
            }}
            className={`inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-600 hover:bg-emerald-500 hover:text-white dark:text-gray-400 shadow-sm`}
          >
            <span className="sr-only">Next</span>
            <wbr />
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
            </svg>
          </button>
        </div>
      </nav>
    </div>
  );
}

export default PaginationNumeric;
