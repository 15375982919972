import React, { useState, useEffect } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useLocalStorage } from "@mantine/hooks";
import superjson from "superjson";
import moment from "moment";
import { shuffleWithSeed } from "../../utils/Utils";

import useAuth from "../../hooks/useAuth";
import Sidebar from "../../partials/Sidebar";
import { useTranslation } from "react-i18next";
import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";
import endpoints from "../../api/endpoints";
import PageNotFound from "../../pages/error/PageNotFound";
import Header from "../../partials/practice/PracticeHomeHeader";
import RedoListModal from "../../partials/practice/RedoListModal";
import PracticeQuizSection from "../../partials/practice/PracticeQuizSection";

import BottomPracticeBar from "../../partials/ui/BottomPracticeBar";
import RenderUserAvatar from "../../partials/users/RenderUserAvatar";
import PremiumLockModal from "../../partials/practice/PremiumLockModal";
import PaymentModal from "../../partials/practice/PaymentModal";
import VisibilityStatus from "../../partials/quizzes/VisibilityStatus";
import SignInModal from "../../partials/auths/SignInModal";

function PracticeHome() {
  // i18n
  const { t, i18n } = useTranslation();

  const [quiz, setQuiz] = useState();
  const [isQueried, setIsQueried] = useState(false);
  const [wrongDB, setWrongDB] = useState();
  const [isQuestionShuffled, setIsQuestionShuffled] = useState();
  const [isAnswerShuffled, setIsAnswerShuffled] = useState();
  const [open, setOpen] = useState(false);
  const [targetSection, setTargetSection] = useState();
  const [loginOpen, setLoginOpen] = useState(false);
  const [viewMode, setViewMode] = useState();
  const { auth } = useAuth();
  const user = auth?.user;

  const [hasAccess, setHasAccess] = useState(false);
  const [expiredOn, setExpiredOn] = useState();
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const axiosTryPrivate = useAxiosTryPrivate();
  const controller = new AbortController();

  const [searchParams, setSearchParams] = useSearchParams();

  const { quizId } = useParams();
  const id = searchParams.get("quizId") || quizId;
  const paymentStatus = searchParams.get("paymentStatus");

  // Recent History
  const [viewHistory, setViewHistory] = useLocalStorage({
    key: `viewHistory`,
    defaultValue: [],
    serialize: superjson.stringify,
    deserialize: (array) => (array === undefined ? [] : superjson.parse(array)),
    getInitialValueInEffect: false,
  });

  // Personal Setting
  const [personalSetting, setPersonalSetting] = useLocalStorage({
    key: `personalSetting`,
    defaultValue: {
      isQuestionShuffled: true,
      isAnswerShuffled: true,
      viewMode: "grid",
    },
    serialize: superjson.stringify,
    deserialize: (obj) =>
      obj === undefined
        ? {
            isQuestionShuffled: true,
            isAnswerShuffled: true,
            viewMode: "grid",
          }
        : superjson.parse(obj),
    getInitialValueInEffect: false,
  });

  // Record Practice Setting
  const [practiceSetting, setPracticeSetting] = useLocalStorage({
    key: `practiceSetting`,
    defaultValue: new Map(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Map() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Get Previous Answer
  const [answerHistory, setAnswerHistory] = useLocalStorage({
    key: `practice-${id}-answer-history`,
    defaultValue: new Map(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Map() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Get Answer Statistics
  const [answerStatistics, setAnswerStatistics] = useLocalStorage({
    key: `practice-${id}-answer-statistics`,
    defaultValue: new Map(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Map() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Wrong History
  const [wrongHistory, setWrongHistory] = useLocalStorage({
    key: `wrongHistory`,
    defaultValue: new Set(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Set() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Get Has Access
  const getHasAccess = async (quiz) => {
    let tempHasAccess = true;
    if (quiz.isPremium) {
      if (!user) {
        tempHasAccess = false;
      } else {
        // Check if user has access
        const res = await axiosTryPrivate.get(
          `${endpoints.PRACTICE_URL}/${id}/hasaccess`
        );
        tempHasAccess = res.data.hasAccess;
        console.log(res.data);
        setExpiredOn(res.data.expiredOn);
      }
    }

    setHasAccess(tempHasAccess);
  };

  const preprocessWrongDB = async (quiz) => {
    // Remove non-existence ids in wrongHistory set
    const wrongHistoryLatest = new Set();

    for (const item of wrongHistory) {
      const setId = item.split(":")[0];
      const questionId = item.split(":")[1];
      const quizId = item.split(":")[2];

      if (quizId !== id) {
        wrongHistoryLatest.add(item);
      } else {
        // Check if setId exists in quiz
        for (const section of quiz.sections) {
          for (const set of section.sets) {
            if (set.id === setId) {
              if (set.objects.includes(questionId)) {
                wrongHistoryLatest.add(item);
              }
            }
          }
        }
      }
    }

    // Update wrongHistory
    setWrongHistory(wrongHistoryLatest);
  };

  // Get Quiz
  const getQuiz = async () => {
    try {
      const res = await axiosTryPrivate.get(endpoints.PRACTICE_URL + "/" + id, {
        signal: controller.signal,
      });

      // Preprocess wrongDB
      await preprocessWrongDB(res.data);

      renderWrongDB(res.data);
      setQuiz(res.data);

      // Get Has Access
      getHasAccess(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsQueried(true);
    }
  };

  // Change Personal Settings
  useEffect(() => {
    setPersonalSetting({
      isQuestionShuffled,
      isAnswerShuffled,
      viewMode,
    });
  }, [viewMode, isQuestionShuffled, isAnswerShuffled]);

  // Verify payment
  const verifyPayment = async (sessionId) => {
    try {
      const plan = await axiosTryPrivate.post(
        `${endpoints.PLAN_URL}/verify-payment`,
        {
          sessionId,
        }
      );

      // Update Access
      getHasAccess(quiz);

      setPaymentInfo({
        title: t("Payment Successful"),
        description: `${t("You have successfully purchase the plan")}: ${
          plan.data.title
        }`,
        ref: sessionId,
      });
    } catch (err) {
      console.error(err);
      setPaymentInfo({
        title: t("Payment Failed"),
        ref: sessionId,
      });
    } finally {
      setPaymentModalOpen(true);
    }
  };

  // Stripe
  useEffect(() => {
    // Reset payment info
    setPaymentInfo({});

    // Get stripeSessionId
    const stripeSessionId = searchParams.get("stripeSessionId");

    if (paymentStatus === "success") {
      verifyPayment(stripeSessionId);
    } else if (paymentStatus === "cancel") {
      setPaymentInfo({
        title: t("Payment Cancelled"),
        ref: stripeSessionId,
      });
      setPaymentModalOpen(true);
    }
  }, [paymentStatus]);

  // Get Quiz
  const start = async (set, mode) => {
    try {
      if (!hasAccess) {
        if (!user) {
          setLoginOpen(true);
          return;
        }
        setPremiumModalOpen(true);
        return;
      }

      if (mode === "redo") {
        // Have to login
        if (!user) {
          setLoginOpen(true);
          return;
        }
      }

      // Save Practice Setting
      practiceSetting.set(quiz.id, {
        isQuestionShuffled,
        isAnswerShuffled,
        practiceId: uuidv4(),
      });

      setPracticeSetting(practiceSetting);

      // Reset Answer History
      setAnswerHistory(new Map());
      setAnswerStatistics(new Map());

      const wrongDBLatest = renderWrongDB(quiz);

      console.log(wrongDBLatest);

      if (mode === "quiz") {
        navigate(`/practice/${quiz.id}/${set.id}`);
      } else if (mode === "redo") {
        navigate(
          `/practice/redo/${quiz.id}/${set.id}/?questionId=${
            wrongDBLatest.get(set.id).questionIds[0]
          }`
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const like = async () => {
    const URL = `${endpoints.QUESTIONSETS_URL}/${quiz.id}/like`;
    try {
      await axiosTryPrivate.patch(URL);

      // Update Quiz
      setQuiz({
        ...quiz,
        isLiked: !quiz.isLiked,
        likeCount: !quiz.isLiked ? quiz.likeCount + 1 : quiz.likeCount - 1,
      });
    } catch {
      setLoginOpen(true);
    }
  };

  const bookmark = async () => {
    const URL = `${endpoints.QUESTIONSETS_URL}/${quiz.id}/bookmark`;
    try {
      await axiosTryPrivate.patch(URL);

      // Update Quiz
      setQuiz({
        ...quiz,
        isBookmarked: !quiz.isBookmarked,
        bookmarkCount: !quiz.isBookmarked
          ? quiz.bookmarkCount + 1
          : quiz.bookmarkCount - 1,
      });
    } catch {
      setLoginOpen(true);
    }
  };

  const purchasePlan = async (plan) => {
    try {
      // Check if user is logged in
      if (!user) {
        setLoginOpen(true);
        return;
      }

      if (hasAccess && expiredOn === null) {
        setPaymentInfo({
          title: t("You already have access to this quiz"),
        });

        setPaymentModalOpen(true);
        return;
      }

      // Create Checkout Session
      const checkoutSession = await axiosTryPrivate.post(
        `${endpoints.PLAN_URL}/${plan.id}/create-checkout-session`
      );

      // Redirect to Checkout
      window.location.replace(checkoutSession.data.url);
    } catch (err) {
      console.log(err);
    }
  };

  const renderWrongDB = (quiz) => {
    // Get All Set from quiz object
    const quizSets = [];

    const setTitleMapping = new Map();
    const sectionTitleMapping = new Map();
    const setSectionMapping = new Map();

    for (const section of quiz.sections) {
      sectionTitleMapping.set(section.id, section.title);
      for (const set of section.sets) {
        quizSets.push(set);
        setTitleMapping.set(set.id, set.title);
        setSectionMapping.set(set.id, section.id);
      }
    }

    const setIds = quizSets.map((q) => q.id);

    const wrongDb = new Map();

    for (const raw of wrongHistory) {
      const setId = raw.split(":")[0];
      const questionId = raw.split(":")[1];

      if (setIds.includes(setId) && questionId !== null) {
        let questionIds = wrongDb.get(setId)?.questionIds
          ? [...new Set([...wrongDb.get(setId).questionIds, questionId])]
          : [questionId];

        // Filter null or "null" values
        questionIds = questionIds.filter(
          (item) => item !== null && item !== "null"
        );

        if (isQuestionShuffled) {
          questionIds.sort();
          questionIds = shuffleWithSeed(questionIds, setId);
        }

        if (questionIds.length > 0) {
          wrongDb.set(setId, {
            id: setId,
            title: setTitleMapping.get(setId),
            section: {
              id: setSectionMapping.get(setId),
              title: sectionTitleMapping.get(setSectionMapping.get(setId)),
            },
            questionIds,
          });
        } else {
          wrongDb.delete(setId);
        }
      }
    }

    setWrongDB(wrongDb);
    return wrongDb;
  };

  const renderWrongDBCount = (section) => {
    const wrongDBArr = Object.values(Object.fromEntries(wrongDB));
    return wrongDBArr.filter((item) => {
      return item.section.id === section.id;
    }).length;
  };

  const init = async () => {
    // Redirect to practice/:quizId if get query parameter
    if (searchParams.get("quizId")) {
      navigate(`/practice/${searchParams.get("quizId")}`);
    }

    if (!isAnswerShuffled) {
      // Initialize Personal Settings
      setIsAnswerShuffled(personalSetting.isAnswerShuffled);
    }
    if (!isQuestionShuffled) {
      setIsQuestionShuffled(personalSetting.isQuestionShuffled);
    }
    if (!viewMode) {
      setViewMode(personalSetting.viewMode);
    }

    await getQuiz();

    // Set the view History
    if (viewHistory[viewHistory.length - 1] !== id) {
      setViewHistory([...viewHistory, id]);
    }
  };

  const addView = async () => {
    const URL = `${endpoints.ANALYTICS_URL}/view`;
    await axiosTryPrivate.post(URL, { id, type: "Quiz" });
  };

  useEffect(() => {
    // Add view history to backend
    addView();
    init();
  }, [auth]);

  if (!quiz) {
    if (isQueried) {
      return <PageNotFound />;
    } else {
      return;
    }
  } else {
    return (
      <div className="flex overflow-hidden">
        {/* Sidebar */}
        <Sidebar user={user} />

        {/* Content area */}
        <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header quiz={quiz} user={user} />

          <RedoListModal
            open={open}
            section={targetSection}
            setOpen={setOpen}
            wrongDB={wrongDB}
            start={start}
          />

          <PremiumLockModal
            open={premiumModalOpen}
            setOpen={setPremiumModalOpen}
          />

          <PaymentModal
            open={paymentModalOpen}
            setOpen={setPaymentModalOpen}
            paymentInfo={paymentInfo}
          />

          {/* Redeem Modal */}
          <SignInModal open={loginOpen} setOpen={setLoginOpen} />
          <main>
            <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 w-full mx-auto space-y-10">
              {/* CoverImg */}
              {quiz.coverImg && (
                <div className="relative flex items-center h-80 justify-center">
                  <div className="aspect-square h-72">
                    <img
                      src={quiz.coverImg}
                      alt="Cover"
                      className="h-full w-full object-cover shadow-lg"
                    />
                  </div>
                  {/* Blurred background image */}
                  <img
                    src={quiz.coverImg}
                    alt="Cover"
                    className="-z-10 absolute w-full blur-sm h-80 object-cover brightness-50"
                  />
                </div>
              )}

              <div className="space-y-2">
                <div className="grid grid-cols-1 justify-between md:gap-x-4 space-y-4">
                  {/* Left */}
                  <div className="w-full col-span-2 space-y-2">
                    <div className="flex items-start">
                      <div className="h1 text-black dark:text-white">
                        {quiz.title}
                      </div>
                    </div>

                    {/* View, Like and bookmark count */}
                    <div className="flex items-center text-black dark:text-white space-x-4 text-sm">
                      <div className="flex items-center gap-x-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                        {quiz.viewCount}
                      </div>
                      <div className="flex items-center gap-x-2">
                        <button
                          onClick={() => like()}
                          className="inline-flex gap-x-2 items-center w-full rounded-md text-sm font-medium"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className={quiz.isLiked ? "fill-current" : ""}
                              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                            />
                          </svg>
                        </button>
                        {quiz.likeCount}
                      </div>
                      <div className="flex items-center gap-x-2">
                        {/* Bookmark Button */}
                        <button
                          onClick={() => bookmark()}
                          className="inline-flex gap-x-2 items-center w-full rounded-md text-sm font-medium"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className={
                                quiz.isBookmarked ? "fill-current" : ""
                              }
                              d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
                            />
                          </svg>
                        </button>

                        {quiz.bookmarkCount}
                      </div>
                      {/* Visibility Tab */}
                      <div className="max-w-fit">
                        <VisibilityStatus status={quiz.visibility} />
                      </div>
                      {/* Premium Badge */}
                      {quiz.isPremium && (
                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium text-white bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                          {t("Premium Quiz")}
                        </span>
                      )}
                    </div>

                    {/* Description */}
                    {quiz.description && (
                      <div>
                        <div className="line-clamp-none text-black dark:text-gray-400 whitespace-pre-line">
                          {quiz.description}
                        </div>
                      </div>
                    )}

                    {/* Purchase Plan */}
                    {quiz.isPremium &&
                      quiz.plans.length > 0 &&
                      (expiredOn || !hasAccess) && (
                        <div>
                          <div className="h3 leading-snug text-black dark:text-white font-bold">
                            {t("Purchase Plans")}
                          </div>
                          {hasAccess && expiredOn && (
                            <div className="max-w-fit text-amber-600 dark:text-amber-500">
                              {t("Access expired on")}:{" "}
                              {moment(expiredOn).fromNow(true)}
                            </div>
                          )}

                          <div
                            role="list"
                            className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4"
                          >
                            {quiz.plans &&
                              quiz.plans.length > 0 &&
                              quiz.plans.map((plan, idx) => (
                                <button
                                  key={plan.id}
                                  onClick={() => {
                                    purchasePlan(plan);
                                  }}
                                  className="col-span-1 flex shadow-sm bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-md hover:scale-105 transition-all"
                                >
                                  <div className="flex items-center justify-center h-full w-16 text-white text-lg font-extrabold rounded-l-md">
                                    {idx + 1}
                                  </div>
                                  <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800 rounded-r-md truncate">
                                    <div className="text-left flex-1 px-4 py-2 text-sm truncate">
                                      <div className="font-medium text-black dark:text-white">
                                        {plan.title}
                                      </div>
                                      <p className="text-black dark:text-gray-400">
                                        HK${plan.price} (
                                        {plan.validForDays === -1
                                          ? t("Lifetime")
                                          : `${plan.validForDays}${t(" days")}`}
                                        )
                                      </p>
                                    </div>
                                    <div className="flex-shrink-0 pr-2">
                                      <div className="w-8 h-8 bg-white inline-flex items-center justify-center text-black-400 rounded-full bg-transparent hover:text-gray-500 dark:text-gray-100 dark:hover:text-white">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-6 h-6"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </button>
                              ))}
                          </div>
                        </div>
                      )}

                    <div className="flex items-center justify-between">
                      <Link
                        to={
                          quiz.user.id !== user?.id
                            ? `/profile/${quiz.user.id}`
                            : "/library"
                        }
                        className="flex items-center max-w-fit"
                      >
                        <div>
                          <RenderUserAvatar user={quiz.user} />
                        </div>
                        <div className="flex flex-col ml-3 max-w-fit">
                          <div className="flex items-center">
                            <div className="text-sm font-bold text-black dark:text-white max-w-28 line-clamp-1">
                              {quiz.user.name}{" "}
                            </div>
                            {quiz.user.isVerifiedAccount && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="ml-1 w-6 h-6 text-sky-600"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}
                          </div>
                          <div className="text-xs font-medium text-gray-500 max-w-28 line-clamp-1">
                            {t("View profile")}
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div>
                      <div className="h3 leading-snug text-black dark:text-white font-bold">
                        {t("Practice Setting")}
                      </div>
                      <div className="space-y-4 max-w-md text-black dark:text-white rounded-md py-3 flex flex-col justify-center">
                        <div className="space-y-2">
                          <div className="flex items-center space-x-4">
                            <div>{t("Questions Order")}</div>
                            <div className="w-24">
                              <div className="flex items-center">
                                <div className="form-switch">
                                  <input
                                    type="checkbox"
                                    id="switch-1"
                                    className="sr-only"
                                    checked={isQuestionShuffled}
                                    onChange={() =>
                                      setIsQuestionShuffled(!isQuestionShuffled)
                                    }
                                  />
                                  <label
                                    className="bg-gray-800 dark:bg-gray-600"
                                    htmlFor="switch-1"
                                  >
                                    <span
                                      className="bg-white shadow-md"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">
                                      Switch label
                                    </span>
                                  </label>
                                </div>
                                <div className="text-sm whitespace-nowrap text-black dark:text-white italic ml-2">
                                  {isQuestionShuffled
                                    ? t("Shuffle")
                                    : t("In Order")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center space-x-4">
                            <div>{t("Answers Order")}</div>
                            <div className="w-24">
                              <div className="flex items-center">
                                <div className="form-switch">
                                  <input
                                    type="checkbox"
                                    id="switch-2"
                                    className="sr-only"
                                    checked={isAnswerShuffled}
                                    onChange={() =>
                                      setIsAnswerShuffled(!isAnswerShuffled)
                                    }
                                  />
                                  <label
                                    className="bg-gray-800 dark:bg-gray-600"
                                    htmlFor="switch-2"
                                  >
                                    <span
                                      className="bg-white shadow-sm"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">
                                      Switch label
                                    </span>
                                  </label>
                                </div>
                                <div className="text-sm whitespace-nowrap text-black dark:text-white italic ml-2">
                                  {isAnswerShuffled
                                    ? t("Shuffle")
                                    : t("In Order")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Section */}
                    <PracticeQuizSection
                      quiz={quiz}
                      renderWrongDBCount={renderWrongDBCount}
                      s
                      setTargetSection={setTargetSection}
                      setOpen={setOpen}
                      viewMode={viewMode}
                      setViewMode={setViewMode}
                      start={start}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Bottom App Nav bar */}
            <BottomPracticeBar
              quiz={quiz}
              start={start}
              bookmark={bookmark}
              like={like}
            />
          </main>
        </div>
      </div>
    );
  }
}

export default PracticeHome;
