import { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import ProcessingBtn from "../../components/btn/ProcessingBtn";
import useModal from "../../hooks/useModal";
import endpoints from "../../api/endpoints";
import axios from "../../api/axios";

import Danger from "../../components/alert/Danger";
import Success from "../../components/alert/Success";

// the hook
import { useTranslation } from "react-i18next";

export default function ForgotPasswordComponent({
  redirect = true,
  setOpen,
  setPage,
}) {
  // i18n
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const { setIsModalOpen } = useModal();

  // Warning
  const [warning, setWarning] = useState();

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setLoading(true);

    try {
      await axios.post(endpoints.FORGOTPW_URL, formik.values);

      setWarning(
        <Success title={t("Please check your email for verification link.")} />
      );
    } catch (err) {
      if (!err?.response) {
        console.error("No Server res");
      } else {
        const error = err.response?.data?.error;
        setWarning(<Danger title={error.msg} />);
      }
    } finally {
      setLoading(false);
      setIsModalOpen(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("This is not a valid email"))
        .required(t("This field is required")),
    }),
    onSubmit: submitHandler,
  });

  return (
    <div className="text-black dark:text-white">
      <h1 className="text-3xl text-black dark:text-white font-bold mb-6">
        {t("Reset your Password")} ✨
      </h1>

      {/* Warning */}
      {warning}

      {/* Form */}
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="email">
              {t("Email")} <span className="text-rose-500">*</span>
            </label>
            <input
              name="email"
              className="form-input w-full text-lg sm:text-base dark:bg-gray-800 dark:border-gray-600 dark:text-white"
              type="string"
              value={formik.email}
              onChange={formik.handleChange}
            />
            <p className="text-red-600 text-sm">
              {formik.errors.email
                ? `${t("Error")}: ${formik.errors.email}`
                : null}
            </p>
          </div>
        </div>
        <div className="flex justify-end mt-6">
          {!isLoading && (
            <button
              type="submit"
              className="btn bg-emerald-500 hover:bg-emerald-600 text-white whitespace-nowrap w-full"
            >
              {t("Submit")}
            </button>
          )}
          {isLoading && <ProcessingBtn title={t("Submit")} />}
        </div>
        <div className="flex justify-center mt-6">
          {redirect === true ? (
            <Link to="/" className="text-emerald-600 underline">
              {t("Back to home")}
            </Link>
          ) : (
            <button
              onClick={() => {
                setPage("signin");
              }}
              className="text-emerald-600 underline"
            >
              {t("Back to home")}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
