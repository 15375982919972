import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

import useAuth from "../../hooks/useAuth";
import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";
import endpoints from "../../api/endpoints";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import BottomAppBar from "../../partials/ui/BottomAppBar";
import QuizSwiper from "../../partials/swiper/QuizSwiper";

function Home() {
  const { t } = useTranslation();

  const axiosTryPrivate = useAxiosTryPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth?.user;

  // Get Quizs
  const [banners, setBanners] = useState([]);
  const [trending, setTrending] = useState([]);
  const [themes, setThemes] = useState([]);
  const [isBannerLoading, setBannerLoading] = useState(true);
  const [isTrendingLoading, setTrendingLoading] = useState(true);
  const [isThemesLoading, setThemesLoading] = useState(false);

  const getBanners = async () => {
    try {
      const res = await axiosTryPrivate.get(
        `${endpoints.EXPLORE_URL}/banners`,
        {
          signal: controller.signal,
        }
      );

      setBanners(res.data || []);
    } catch (error) {
      console.error("Cannot login: ", error);
    } finally {
      setBannerLoading(false);
    }
  };

  const getTrending = async () => {
    try {
      const res = await axiosTryPrivate.get(endpoints.TRENDING_URL, {
        signal: controller.signal,
      });

      setTrending(res.data.docs);
    } catch (error) {
      console.error("Cannot login: ", error);
    } finally {
      setTrendingLoading(false);
    }
  };

  const renderThemes = async () => {
    try {
      // Check if debouncedSearchString
      const res = await axiosTryPrivate.get(`${endpoints.EXPLORE_URL}/themes`, {
        signal: controller.signal,
      });

      setThemes(res?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBanners();
    getTrending();
    renderThemes();
  }, []);

  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar user={user} />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header title="Home" user={user} />

        <main>
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto space-y-10">
            {/* Banner */}
            <Swiper
              spaceBetween={15}
              autoPlay={{
                delay: 300,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              className="z-0 mx-0"
              modules={[Navigation, Autoplay]}
              navigation={{
                enabled: true,
                disabledClass: "hidden",
              }}
            >
              {isBannerLoading &&
                Array.from(Array(10).keys()).map((idx) => (
                  <SwiperSlide>
                    <div className="animate-pulse h-48 group rounded-lg bg-gray-600 dark:bg-gray-800 overflow-hidden"></div>
                  </SwiperSlide>
                ))}
              {!isBannerLoading &&
                banners.map((item, idx) => (
                  <SwiperSlide>
                    <Link to={item.url || ""}>
                      <img
                        className="max-h-72 aspect-12/5 w-full rounded-lg object-cover bg-center"
                        src={item.coverImg || ""}
                        alt={item.title}
                      />
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>

            {/* Section: Trending */}
            <div className="space-y-2">
              {/* Page header */}
              <div className="mb-2 sm:flex sm:justify-between sm:items-center">
                {/* Left: Title */}
                <h1 className="text-2xl md:text-3xl text-black dark:text-white font-bold">
                  {t("Trending")}
                </h1>
              </div>

              {/* Themes */}
              <Swiper
                slidesPerView="auto"
                spaceBetween={15}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 300,
                }}
                loopedSlides={12}
                className="max-w-fit z-0 mx-0"
              >
                {isThemesLoading &&
                  Array.from(Array(10).keys()).map((idx) => (
                    <SwiperSlide
                      key={`theme-loader-${idx}`}
                      className="max-w-fit"
                    >
                      <div className="animate-pulse p-4 h-20 group block aspect-square rounded-lg bg-gray-600 dark:bg-gray-800 overflow-hidden"></div>
                      <div className="mt-1 h-6 w-36 rounded-full animate-pulse bg-gray-400 dark:bg-gray-600"></div>
                    </SwiperSlide>
                  ))}
                {!isThemesLoading &&
                  themes.map((item, idx) => (
                    <SwiperSlide key={`theme-${idx}`} className="max-w-fit">
                      <Link to={`/themes/${item.value}`}>
                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                          {item.title}
                        </span>
                      </Link>
                    </SwiperSlide>
                  ))}
              </Swiper>

              <QuizSwiper quizzes={trending} isLoading={isTrendingLoading} />
            </div>

            {/* TODO: Section: For you */}
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}

export default Home;
