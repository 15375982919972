import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EmptyProfile from "../../images/EmptyProfile.webp";
import moment from "moment";

function UsersTableItem(props) {
  const { t } = useTranslation();
  return (
    <tr>
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input
              id={props.user.id}
              className="form-checkbox"
              type="checkbox"
              onChange={props.user.handleClick}
              checked={props.user.isChecked}
            />
          </label>
        </div>
      </td> */}
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <Link to={`/profile/${props.user?.id}`} className="flex items-center">
          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            <img
              className="rounded-full object-cover w-10 h-10"
              src={props.user?.avatar ? props.user.avatar : EmptyProfile}
              alt={props.user.username}
            />
          </div>
          <div className="text-left font-bold flex items-center">
            {props.user.name}{" "}
            {props.user?.isVerifiedAccount && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="ml-1 w-6 h-6 text-sky-600"
              >
                <path
                  fillRule="evenodd"
                  d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        </Link>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{props.user.email}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        {props.user.role === "user" && (
          <div className="text-xs inline-flex font-extrabold bg-emerald-100 dark:bg-emerald-200 text-emerald-600 rounded-full text-center px-2.5 py-1">
            {t("User")}
          </div>
        )}
        {props.user.role === "admin" && (
          <div className="text-xs inline-flex font-extrabold bg-rose-100 dark:bg-rose-200 text-rose-600 rounded-full text-center px-2.5 py-1">
            {t("Admin")}
          </div>
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-xs inline-flex font-extrabold bg-black text-white rounded-full text-center px-2.5 py-1">
          {moment(props.user.createdAt).fromNow()}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <Link
          to={`/allusers/${props.user.id}/edit`}
          className="bg-gray-50 hover:bg-gray-100 text-black block px-4 py-1 text-sm rounded-md"
        >
          {t("Edit")}
        </Link>

        {/* <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="text-gray-400 hover:text-gray-500 rounded-full">
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <circle cx="16" cy="16" r="2" />
                <circle cx="10" cy="16" r="2" />
                <circle cx="22" cy="16" r="2" />
              </svg>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div>
                <Menu.Item>
                  <Link
                    to={`/allusers/${props.user.id}/edit`}
                    className="hover:bg-gray-100 text-black block px-4 py-3 text-sm"
                  >
                    Edit
                  </Link>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu> */}
      </td>
    </tr>
  );
}

export default UsersTableItem;
