import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useLocalStorage } from "@mantine/hooks";
import superjson from "superjson";
import { useTranslation } from "react-i18next";

import useAxiosPublic from "../../hooks/useAxiosPublic";
import endpoints from "../../api/endpoints";
import Loader from "../../partials/Loader";
import PageNotFound from "../error/PageNotFound";
import QuestionSelector from "../../partials/practice/QuestionSelector";
import PracticeHeader from "../../partials/practice/PracticeHeader";

function PracticeResults() {
  // i18n
  const { t, i18n } = useTranslation();

  const [quiz, setQuiz] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const axiosPublic = useAxiosPublic();
  const controller = new AbortController();

  const { quizId, setId } = useParams();

  // Get Previous Answer
  const [answerHistory, setAnswerHistory] = useLocalStorage({
    key: `practice-${quizId}-answer-history`,
    defaultValue: new Map(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Map() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Get Answer Statistics
  const [answerStatistics] = useLocalStorage({
    key: `practice-${quizId}-answer-statistics`,
    defaultValue: new Map(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Map() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  // Get Quiz
  const getPractice = async () => {
    try {
      const res = await axiosPublic.get(
        `${endpoints.PRACTICE_URL}/${quizId}/${setId}`,
        {
          signal: controller.signal,
        }
      );

      setQuiz(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const init = async () => {
    setLoading(true);
    await getPractice();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  if (isLoading) return <Loader />;
  if (!quiz) return <PageNotFound />;
  return (
    <>
      <PracticeHeader quizId={quizId} quiz={quiz} result={true} />

      <main>
        <div className="relative py-20">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-4xl lg:px-8">
            <div className="relative overflow-hidden">
              <div className="relative space-y-4">
                <div className="flex items-center justify-between">
                  <div className="h1 text-black dark:text-white tracking-tight">
                    {quiz.title}
                  </div>
                </div>

                <div className="h2 leading-snug text-black dark:text-white font-bold">
                  {
                    [...answerStatistics].filter(
                      (item) => item[1] === "correct"
                    ).length
                  }
                  /{quiz.questions.length}
                </div>
                <div className="flex items-center space-x-4">
                  <div className="text-lg font-bold text-emerald-500">
                    {t("Correct Percentage")}:{" "}
                    {Math.ceil(
                      ([...answerStatistics].filter(
                        (item) => item[1] === "correct"
                      ).length /
                        quiz.questions.length) *
                        100
                    )}
                    %
                  </div>
                  <div className="text-lg font-bold text-gray-500">
                    {t("Blank Percentage")}:{" "}
                    {Math.ceil(
                      ([...answerStatistics].filter(
                        (item) => item[1] !== "correct" && item[1] !== "wrong"
                      ).length /
                        quiz.questions.length) *
                        100
                    )}
                    %
                  </div>
                </div>
                <div className="h3 leading-snug text-black dark:text-white font-bold">
                  {t("Your Practice Results")}
                </div>
                {quiz && (
                  <QuestionSelector
                    answerHistory={answerHistory}
                    answerStatistics={answerStatistics}
                    quizId={quizId}
                    setId={setId}
                    quiz={quiz}
                  />
                )}
                <div className="flex space-x-2">
                  <Link
                    to={`/practice/${quizId}`}
                    className="w-full inline-flex justify-center items-center py-3 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 dark:bg-emerald-600 dark:hover:bg-emerald-700"
                  >
                    {t("Back to home")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default PracticeResults;
