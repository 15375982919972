import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const QuestionSelector = ({
  answerHistory,
  answerStatistics,
  quizId,
  quiz,
  setId,
}) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-10">
      <div className="flex justify-start items-center">
        <div className="w-full grid grid-cols-1 gap-y-2">
          {[...answerStatistics].map((question, idx) => {
            if (question[1] === "correct" || question[1] === "wrong") {
              const answered = answerHistory.get(question[0])[0];

              return (
                <Link
                  to={`/practice/${quizId}/${setId}?questionId=${question[0]}`}
                  key={question[0]}
                  className={`relative ${
                    question[1] === "correct" &&
                    "border-2 border-emerald-500 dark:border-emerald-600"
                  } ${
                    question[1] === "wrong" &&
                    "border-2 border-rose-500 dark:border-rose-600"
                  } ${
                    (question[1] === "touched" ||
                      question[1] === "untouched") &&
                    "bg-gray-300 dark:bg-gray-400"
                  } rounded-md h-full flex flex-col px-4 py-2`}
                >
                  <div className="font-medium dark:text-white pointer-events-none">
                    Q{idx + 1}. {quiz.questions[idx].title}
                  </div>
                  <div
                    className={`font-medium truncate pointer-events-none w-fit ${
                      answered.isCorrect
                        ? "text-emerald-500 dark:text-emerald-600"
                        : "text-rose-500 dark:text-rose-600"
                    }`}
                  >
                    <div className="flex items-center space-x-1">
                      {answered.isCorrect ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={3}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={3}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      )}
                      {answered.title}
                    </div>
                  </div>
                  {!answered.isCorrect && (
                    <div
                      className={`font-medium text-white truncate pointer-events-none w-fit  ${
                        answered.isCorrect
                          ? "bg-emerald-500 dark:bg-emerald-600"
                          : "bg-rose-500 dark:bg-rose-600"
                      }`}
                    >
                      {t("Correct answer")} :{" "}
                      {
                        quiz.questions[idx].answers.find(
                          (answer) => answer.isCorrect
                        ).title
                      }
                    </div>
                  )}
                </Link>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default QuestionSelector;
