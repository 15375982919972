import { useTranslation } from "react-i18next";

const PracticeQuizSection = ({
  quiz,
  renderWrongDBCount,
  setTargetSection,
  setOpen,
  viewMode,
  setViewMode,
  start,
}) => {
  const { t } = useTranslation();

  const renderViewModeBtn = () => {
    if (viewMode === "list") {
      return (
        <button
          onClick={() => {
            setViewMode("grid");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            setViewMode("list");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
            />
          </svg>
        </button>
      );
    }
  };

  return (
    <div className="space-y-4">
      {quiz.sections.map((section, idx) => {
        return (
          <div key={idx} className="space-y-4">
            <h2 className="flex justify-between items-center text-xl leading-snug text-black dark:text-white font-bold">
              <div>{section.title}</div>
              <div className="flex items-cener space-x-2">
                {renderWrongDBCount(section) > 0 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setTargetSection(section);
                      setOpen(true);
                    }}
                    className="relative inline-flex justify-center items-center px-4 py-1 text-xs sm:text-sm font-medium border border-transparent rounded-full shadow-sm text-white bg-rose-600 hover:bg-rose-700 transition-all whitespace-nowrap"
                  >
                    {t("Redo Wrong Questions")} ({renderWrongDBCount(section)})
                  </button>
                )}
                {/* List or Grid View */}
                {renderViewModeBtn()}
              </div>
            </h2>
            <div
              className={`grid ${
                viewMode === "grid"
                  ? "grid-cols-2 md:grid-cols-3"
                  : "grid-cols-1"
              } gap-4`}
            >
              {section.sets.map((set, idx) => (
                <button
                  key={idx}
                  onClick={() => {
                    start(set, "quiz");
                  }}
                  className="w-full block group shadow-lg rounded-lg border dark:border-gray-600 px-5 py-4 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-black transition-all border-gray-200"
                >
                  <div className="flex sm:flex-row flex-col sm:justify-between space-y-4 sm:space-y-0 items-start sm:items-center">
                    {/* Left side */}
                    <div className="flex items-center space-x-4">
                      <div className="relative w-9 h-9 shrink-0 flex justify-center items-center">
                        <div className="absolute font-extrabold text-white">
                          {idx + 1}
                        </div>
                        <div className="w-9 h-9 rounded-full bg-emerald-500 dark:bg-emerald-600"></div>
                      </div>
                      <div className="line-clamp-2 hover:line-clamp-none text-left text-black dark:text-gray-100">
                        {set.title}
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PracticeQuizSection;
