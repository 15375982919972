import React, { useState, useEffect } from "react";
import ThemeItem from "./ThemeTableItem";

export default function ThemeTable({ themes, isLoading }) {
  return (
    <div className="mt-6 flex flex-col space-y-4">
      {themes.map((theme) => {
        return <ThemeItem key={theme.id} theme={theme} isLoading={isLoading} />;
      })}
    </div>
  );
}
