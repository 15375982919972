import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebouncedValue, usePagination } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";
import endpoints from "../../api/endpoints";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";
import BottomAppBar from "../../partials/ui/BottomAppBar";
import QuizTable from "../../partials/quizzes/QuizTable";

function SearchHome() {
  const [isLoading, setLoading] = useState(true);
  const [isSearching, setSearching] = useState(false);

  const { t } = useTranslation();

  const axiosTryPrivate = useAxiosTryPrivate();
  const controller = new AbortController();

  // Query Params
  const [searchParams, setSearchParams] = useSearchParams();

  // Get User Profile
  const { auth } = useAuth();
  const [user, setUser] = useState(auth?.user);
  const [quizzes, setQuizzes] = useState([]);
  const [limit, setLimit] = useState(24);
  const [offset, setOffset] = useState(0);

  // Search
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });

  // Themes
  const [themes, setThemes] = useState([]);

  const renderThemes = async () => {
    try {
      // Check if debouncedSearchString
      const res = await axiosTryPrivate.get(`${endpoints.EXPLORE_URL}/themes`, {
        signal: controller.signal,
      });

      setThemes(res?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    renderThemes();
  }, []);

  useEffect(() => {
    if (searchParams.get("query")) {
      // Search Quizzes
      const searchQuizzes = async () => {
        try {
          setLoading(true);

          const res = await axiosTryPrivate.get(
            `${
              endpoints.SEARCH_QUIZZES_URL
            }?limit=${limit}&offset=${offset}&search=${searchParams.get(
              "query"
            )}`,
            {
              signal: controller.signal,
            }
          );

          setQuizzes(res?.data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      searchQuizzes();
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    // Update URL
    if (searchString) {
      setSearchParams({ query: searchString });
      setSearching(true);
    } else {
      setSearchParams({});
      setQuizzes([]);
      setSearching(false);
    }
  }, [searchString]);

  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar user={user} />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header title="Search" user={user} />

        <main>
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto">
            {/* <WelcomeBanner user={user} /> */}

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-black dark:text-white font-bold">
                  {t("Browse all")} 🔎
                </h1>
              </div>
              <SearchForm value={searchString} setValue={setSearchString} />
            </div>

            {/* Grid contains all the themes */}
            <ul
              role="list"
              className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-4"
            >
              {isLoading &&
                !isSearching &&
                Array.from(Array(12).keys()).map((item, idx) => (
                  <li key={idx} className="relative">
                    <div className="p-4 w-full group block aspect-square rounded-lg animate-pulse bg-gray-800 shadow-lg overflow-hidden"></div>
                  </li>
                ))}
              {!isLoading &&
                !isSearching &&
                themes.map((item, idx) => (
                  <li key={idx} className="relative">
                    <Link
                      to={`/themes/${item.value}`}
                      className="p-4 w-full group block aspect-square rounded-lg bg-gray-600 dark:bg-gray-800 shadow-lg overflow-hidden"
                    >
                      <div className="h4 text-white">{item.title}</div>
                    </Link>
                  </li>
                ))}
            </ul>
            {isSearching && (
              <>
                {/* Table */}
                <QuizTable quizzes={quizzes} />

                {!isLoading && quizzes.length === 0 && (
                  <div className="text-center py-10">
                    <p className="mt-1 text-sm text-gray-500">
                      {t("No result for")} '{debouncedSearchString}'
                    </p>
                  </div>
                )}
              </>
            )}
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}

export default SearchHome;
