import React from "react";
import ReactDOM from "react-dom/client";
import "./css/style.scss";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { ModalProvider } from "./context/ModalProvider";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./utils/ScrollToTop";
import ToastMessage from "./partials/ui/ToastMessage";

import ReactGA from "react-ga4";

ReactGA.initialize("G-BRSZTR6MQD");
ReactGA.send("pageview");

const root = ReactDOM.createRoot(document.getElementById("root"));

// i18n
i18n
  // 使用 i18next-http-backend
  .use(Backend)
  // 將 i18next 傳入 react-i18next 裡面
  .use(initReactI18next)
  // 實例化 initReactI18next
  .init({
    backend: {
      //網頁載入時去下載語言檔的位置
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    // 當目前的語言檔找不到對應的字詞時，會用 fallbackLng (zh) 作為預設語言
    fallbackLng: "zh-Hant",
    // 預設語言
    interpolation: {
      // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
      escapeValue: false,
    },
  });

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ModalProvider>
          <ScrollToTop />
          <ToastMessage />
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ModalProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
