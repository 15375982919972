/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import endpoints from "../../api/endpoints";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function ImportQuestionModal(props) {
  const cancelButtonRef = useRef(null);

  // i18n
  const { t } = useTranslation();

  const axiosPrivate = useAxiosPrivate();
  const [file, setFile] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const confirmHandler = async (e) => {
    try {
      e.stopPropagation();

      setIsFetching(true);

      // Check if file is selected
      if (!file) {
        // Toast
        toast.error(`${t("Please select file")}!`);
      } else {
        let formData = new FormData();
        formData.append("file", file);

        await axiosPrivate.post(
          `${endpoints.UPLOAD_QUESTIONS}/${props.quiz.id}/${props.set.id}`,
          formData
        );

        props.setOpen(false);
        props.refetch();
        // Success msg
        toast.success(`${t("Successfully imported")}!`);
      }
    } catch (error) {
      console.error(error);
      toast.error(`${t("Error occurred")}!`);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-black transition-all rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform sm:my-8 sm:align-middle sm:max-w-xl w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-left sm:mt-0">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-black dark:text-white mb-4"
                  >
                    {t("Import Question From Excel")}
                  </Dialog.Title>
                  <div className="mt-2 dark:text-gray-200 text-sm">
                    {t("Click")}{" "}
                    <a
                      href="/template/upload-template.xlsx"
                      target="_blank"
                      className="text-sky-500 hover:text-sky-600 underline"
                    >
                      {t("here")}
                    </a>{" "}
                    {t("to download the excel template")}.
                  </div>
                  <div className="mt-2">
                    <label>
                      <span className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:col-start-2 sm:text-sm">
                        {file?.name !== undefined
                          ? `${t("File")}: ${file.name}`
                          : t("Select file")}
                      </span>
                      <input
                        name="file"
                        type="file"
                        accept=".xlsx"
                        onChange={(e) => {
                          const fileReader = new FileReader();
                          fileReader.onload = () => {
                            if (fileReader.readyState === 2) {
                              console.log(e.target.files[0]);
                              setFile(e.target.files[0]);
                            }
                          };
                          fileReader.readAsDataURL(e.target.files[0]);
                        }}
                        className="sr-only"
                      />
                    </label>{" "}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 sm:col-start-2 sm:text-sm"
                  disabled={isFetching}
                  onClick={(e) => {
                    confirmHandler(e);
                  }}
                >
                  {t("Upload")}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-black hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => props.setOpen(false)}
                  ref={cancelButtonRef}
                >
                  {t("Cancel")}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
