import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";

import useAuth from "../../hooks/useAuth"; // Auth
import ProcessingBtn from "../../components/btn/ProcessingBtn";
import axios from "../../api/axios";
import endpoints from "../../api/endpoints";
import Loader from "../../partials/Loader";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Warning from "../../components/alert/Warning";
import Message from "../message/Message";
import BottomAppBar from "../../partials/ui/BottomAppBar";

// the hook
import { useTranslation } from "react-i18next";

function Reset() {
  // i18n
  const { t, i18n } = useTranslation();

  // Warning
  const [warning, setWarning] = useState();

  // Auth
  const { setAuth } = useAuth();

  // Reset Token
  let { resetToken } = useParams();
  const [isTokenValid, setTokenValid] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/signin";

  const resetHandlerTest = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    console.log(formik.values);
    await setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const resetHandler = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    try {
      const res = await axios.post(
        endpoints.RESETPW_URL,
        JSON.stringify(formik.values),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      console.log(JSON.stringify(res?.data));

      // Set Auth Payload
      setAuth(res?.data);

      // Return to previous page
      navigate(from, { replace: true });
    } catch (err) {
      console.log(err);

      if (!err?.response) {
        console.error("No Server res");
      } else {
        let error = err?.response.data.errors.msg;
        setWarning(<Warning title="Invalid Token" />);
      }
    } finally {
      setLoading(false);
    }
  };

  const [isLoading, setLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      resetToken: resetToken,
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      resetToken: Yup.string().required("Reset token is required"),
      password: Yup.string()
        .trim()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: resetHandler,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const checkToken = async () => {
      try {
        // Check if the reset token is valid
        console.log(resetToken);

        // Check if the reset token is valid
        await axios.get(endpoints.VERIFY_RESET_TOKEN_URL, {
          params: { id: resetToken },
        });

        setTokenValid(true);
      } catch {
        setTokenValid(false);
      } finally {
        setLoading(false);
      }
    };
    checkToken();
  }, [isLoading]);

  if (isLoading) return Loader;
  if (!isTokenValid) {
    return <Message message="Invalid verification token!" />;
  } else {
    return (
      <div className="flex">
        {/* Sidebar */}
        <Sidebar />

        {/* Content area */}
        <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header title="Reset" />

          <main>
            <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 max-w-md mx-auto space-y-10 text-black dark:text-white">
              <h1 className="text-3xl text-black dark:text-white font-bold mb-6">
                {t("Reset Password")}
              </h1>

              {/* Warning */}
              {warning}

              {/* Form */}
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      {t("New Password")}
                    </label>
                    <input
                      name="password"
                      className="form-input w-full text-lg sm:text-base dark:bg-gray-800 dark:border-gray-600 dark:text-white"
                      type="password"
                      value={formik.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.values.password && (
                      <PasswordStrengthBar
                        minLength={8}
                        scoreWordClassName="text-sm font-medium"
                        password={formik.values.password}
                      />
                    )}
                    <p className="text-red-600 text-sm">
                      {formik.errors.password && formik.touched.password
                        ? `${t("Error")}: ${formik.errors.password}`
                        : null}
                    </p>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="confirmPassword"
                    >
                      {t("Confirm Password")}
                    </label>
                    <input
                      name="confirmPassword"
                      className="form-input w-full text-lg sm:text-base dark:bg-gray-800 dark:border-gray-600 dark:text-white"
                      type="password"
                      autoComplete="on"
                      value={formik.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                        ? `${t("Error")}: ${formik.errors.confirmPassword}`
                        : null}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  {!isLoading && (
                    <button
                      type="submit"
                      className="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full"
                    >
                      {t("Reset")}
                    </button>
                  )}
                  {isLoading && <ProcessingBtn title="Reset" />}
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm mb-6">
                  {t("Have an account?")}{" "}
                  <Link
                    className="font-medium text-emerald-500 hover:text-emerald-600"
                    to="/signin"
                  >
                    {t("Sign In")}
                  </Link>
                </div>
              </div>
            </div>

            {/* Bottom App Nav bar */}
            <BottomAppBar />
          </main>
        </div>
      </div>
    );
  }
}

export default Reset;
