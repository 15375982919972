import React, { useState, useEffect } from "react";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import AccountPanel from "../../partials/settings/AccountPanel";
import SecurityPanel from "../../partials/settings/SecurityPanel";

import Loader from "../../partials/Loader";
import BottomAppBar from "../../partials/ui/BottomAppBar";

function Account() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const [user, setUser] = useState();

  const getUserProfile = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PROFILE_URL, {
        signal: controller.signal,
      });
      setUser(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  if (isLoading) return <Loader />;
  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto">
            {/* Content */}
            <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg mb-8">
              <div className="flex flex-col space-y-4">
                <AccountPanel user={user} updateHeader={getUserProfile} />
                <SecurityPanel user={user} />
              </div>
            </div>
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}

export default Account;
