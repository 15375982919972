import React from "react";
import { useTranslation } from "react-i18next";

import UserTable from "../../partials/users/UserTable";

export default function QuizTab({ users, isLoading, searchString }) {
  const { t } = useTranslation();

  return (
    <div className="px-4">
      {/* Table */}
      <UserTable users={users} isLoading={isLoading} />

      {!isLoading && users.length === 0 && (
        <div className="text-center py-10">
          <h3 className="mt-2 text-xl font-medium text-black dark:text-white">
            {searchString ? t("Not found") : t("No user")}
          </h3>
        </div>
      )}
    </div>
  );
}
