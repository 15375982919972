import { useNavigate, Link } from "react-router-dom";
import { ReactComponent as Logo } from "../images/logo/Logo.svg";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      {/* Header */}
      <div>
        <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
          {/* Logo */}
          <Link className="block" to="/">
            <Logo className="h-8 w-8" />
          </Link>
        </div>
      </div>

      {/* Content area */}
      <div className="relative flex flex-col overflow-y-auto overflow-x-hidden bg-white dark:bg-black transition-all dark:text-white">
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto">
            <div className="max-w-3xl m-auto mt-48">
              <div className="text-center px-4">
                <div className="mb-6">
                  Hmm... You do not have access to the requested page.
                </div>
                <button
                  onClick={goBack}
                  className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Unauthorized;
