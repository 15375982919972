import endpoints from "../api/endpoints";

const fileUpload = async (axiosPrivate, blob, type) => {
  let formData = new FormData();
  formData.append(type, blob);

  if (type === "image") {
    const res = await axiosPrivate.post(endpoints.UPLOAD_IMAGE_S3, formData);
    return res.data;
  } else {
    throw new Error("UNKNOWN_TYPE");
  }
};

const handleFileUpload = async (
  formik,
  axiosPrivate,
  file,
  idx,
  field,
  type
) => {
  if (idx === null) {
    // Activate Loader
    formik.setFieldValue(field, "loader");

    // Upload to server
    const meta = await fileUpload(axiosPrivate, file, type);

    // Update URL to form
    formik.setFieldValue(field, meta.url);

    // Update s3BucketKey
    formik.setFieldValue("s3BucketKey", meta.s3BucketKey);
    return;
  }

  if (file.length > 0) {
    // Activate Loader
    formik.setFieldValue(`languages[${idx}].${field}`, [
      ...formik.values.languages[idx][field],
      ...Array(file.length).fill("loader"),
    ]);

    // Upload to server
    const meta = await Promise.all(
      Array.from(file).map((item) => fileUpload(axiosPrivate, item, type))
    );

    // Update URL to form
    formik.setFieldValue(`languages[${idx}].${field}`, [
      ...formik.values.languages[idx][field],
      ...meta.map((item) => item.url),
    ]);

    // Update URL to form
    formik.setFieldValue(`languages[${idx}].s3BucketKey`, [
      ...formik.values.languages[idx][field],
      ...meta.map((item) => item.url),
    ]);
  } else {
    // Activate Loader
    formik.setFieldValue(`languages[${idx}].${field}`, "loader");

    // Upload to server
    const meta = await fileUpload(axiosPrivate, file, type);

    // Update URL to form
    formik.setFieldValue(`languages[${idx}].${field}`, meta.url);

    // Update s3BucketKey
    formik.setFieldValue(`languages[${idx}].s3BucketKey`, meta.s3BucketKey);
  }
};

const handleFileUploadMin = async (axiosPrivate, file, field, type) => {
  // Upload to server
  const meta = await fileUpload(axiosPrivate, file, type);

  return meta;
};

export { fileUpload, handleFileUpload, handleFileUploadMin };
