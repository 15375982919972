import ModalBlank from "../../components/ModalBlank";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@mantine/hooks";
import { Link } from "react-router-dom";
import superjson from "superjson";

const RedoListModal = (props) => {
  // i18n
  const { t } = useTranslation();

  const cancelHandler = (e) => {
    e.stopPropagation();
    props.setOpen(false);
  };

  const wrongDB = Object.values(Object.fromEntries(props.wrongDB)).filter(
    (item) => {
      return item.section.id === props?.section?.id;
    }
  );

  return (
    <ModalBlank
      id="question-list-modal"
      modalOpen={props.open}
      setModalOpen={props.setOpen}
    >
      <div className="relative p-5 flex flex-col w-full h-full">
        {/* Content */}
        {/* Modal header */}
        <div className="mb-2 flex justify-between items-center">
          <div className="text-lg font-semibold text-black dark:text-white">
            {t("Redo Wrong Questions")}{" "}
            {props?.section?.title ? `- ${props?.section?.title}` : ""}
          </div>

          <button
            onClick={cancelHandler}
            className="block rounded-full bg-gray-100 text-gray-500 hover:text-black"
          >
            <span className="sr-only">Back</span>
            <svg width="32" height="32" viewBox="0 0 32 32">
              <path
                className="fill-current"
                d="M15.95 14.536l4.242-4.243a1 1 0 111.415 1.414l-4.243 4.243 4.243 4.242a1 1 0 11-1.415 1.415l-4.242-4.243-4.243 4.243a1 1 0 01-1.414-1.415l4.243-4.242-4.243-4.243a1 1 0 011.414-1.414l4.243 4.243z"
              />
            </svg>
          </button>
        </div>

        <div className="py-6 space-y-2">
          {wrongDB.map((set, idx) => (
            <button
              key={idx}
              onClick={() => {
                props.start(set, "redo");
              }}
              className="w-full block group shadow-lg rounded-lg border dark:border-gray-600 px-5 py-4 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-black transition-all border-gray-200"
            >
              <div className="flex sm:flex-row flex-col sm:justify-between space-y-4 sm:space-y-0 items-start sm:items-center">
                {/* Left side */}
                <div className="flex items-center space-x-4">
                  <div className="relative w-9 h-9 shrink-0 flex justify-center items-center">
                    <div className="absolute font-extrabold text-white">
                      {idx + 1}
                    </div>
                    <div className="w-9 h-9 rounded-full bg-emerald-500 dark:bg-emerald-600"></div>
                  </div>
                  <div className="text-black dark:text-gray-100">
                    {set.title}
                  </div>
                </div>
                <div className="relative inline-flex justify-center items-center px-4 py-1 text-xs sm:text-sm font-medium border border-transparent rounded-full shadow-sm text-white bg-rose-600 whitespace-nowrap">
                  {set.questionIds.length} {t("Wrong Question(s)")}
                </div>
              </div>
            </button>
          ))}
          {wrongDB.length === 0 && t("Empty")}
        </div>
      </div>
    </ModalBlank>
  );
};

export default RedoListModal;
