import { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import ProcessingBtn from "../../components/btn/ProcessingBtn";
import axiosPublic from "../../api/axios";
import endpoints from "../../api/endpoints";
import Danger from "../../components/alert/Danger";
import Success from "../../components/alert/Success";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function MFAComponent({ redirect = true, setOpen, mfaToken }) {
  // i18n
  const { t } = useTranslation();

  const navigate = useNavigate();

  // Auth
  const { setAuth } = useAuth();

  // Warning
  const [warning, setWarning] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isFetching, setFetching] = useState(false);
  const [info, setInfo] = useState();
  const [isTokenValid, setTokenValid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSent, setSent] = useState(false);

  // OTP
  const { token = mfaToken } = useParams();
  console.log("token from mfa component: ", token);
  const [pin, setPin] = useState("");

  // Submit OTP token to server
  const submit = async () => {
    try {
      setFetching(true);
      const res = await axiosPublic.post(`${endpoints.VERIFY_URL}/${token}`, {
        pin,
      });

      // Set Auth Payload
      setAuth(res?.data);
      setWarning(
        <Success title={`${t("You have successfully registered")}!`} />
      );
      setIsSuccess(true);
    } catch (err) {
      const msg = err?.response?.data?.error?.msg;
      if (msg) {
        setWarning(<Danger title={msg} />);
      } else {
        setWarning(<Danger title={t("Cannot connect to server")} />);
      }
      setPin("");
      setFetching(false);
    }
  };

  const resendToken = async () => {
    try {
      setSent(true);
      const res = await axiosPublic.post(
        `${endpoints.RESENTVERIFY_URL}/${token}`
      );

      setWarning(
        <Success title={`${t("We have sent a new code to your email")}!`} />
      );
    } catch (err) {
      const msg = err?.response?.data?.error?.msg;
      if (msg) {
        setWarning(<Danger title={msg} />);
      } else {
        setWarning(<Danger title={t("Cannot connect to server")} />);
      }
    }
  };

  useEffect(() => {
    if (token) {
      // Check if token is valid
      const checkToken = async () => {
        try {
          const res = await axiosPublic.get(`${endpoints.VERIFY_URL}/${token}`);
          console.log(res.data);
          setInfo(res.data);
          setTokenValid(true);
        } catch (err) {
          setWarning(<Danger title={t("Invalid token")} />);
        } finally {
          setLoading(false);
        }
      };

      checkToken();
    }
  }, [token]);

  useEffect(() => {
    if (pin.length === 6) {
      submit();
    }
  }, [pin]);

  if (isLoading) {
    return;
  }

  return (
    <div className="text-black dark:text-white">
      <h1 className="text-3xl text-black dark:text-white font-bold">
        {t("Verify your email")} ✨
      </h1>

      {/* Warning */}
      {warning}

      {/* Form */}
      {isTokenValid && (
        <form
          onSubmit={(e) => {
            console.log(e);
          }}
        >
          <div className="space-y-2">
            {!isSuccess && (
              <div>
                <label
                  className="block text-sm font-medium mb-4"
                  htmlFor="email"
                >
                  {t("We have sent a code to your email ")}{" "}
                  <span className="text-sky-500 font-bold">{info?.email}</span>
                </label>
                <OtpInput
                  value={pin}
                  onChange={setPin}
                  numInputs={6}
                  shouldAutoFocus={true}
                  containerStyle="flex flex-row items-center justify-between mx-auto w-full text-black dark:text-white"
                  inputStyle="block p-0 form-input text-center !w-14 !h-14 text-xl font-bold dark:bg-gray-800 dark:border-gray-600 dark:text-white"
                  renderInput={(props) => (
                    <input inputmode="numeric" pattern="[0-9]*" {...props} />
                  )}
                />
              </div>
            )}
          </div>
          <div className="flex items-center justify-between mt-6">
            {isSuccess && (
              <button
                type="submit"
                className="inline-block btn bg-sky-500 hover:bg-sky-600 text-white whitespace-nowrap w-full"
                onClick={() => {
                  if (redirect) {
                    navigate("/");
                  } else {
                    setOpen(false);
                  }
                }}
              >
                {t("Go to home")}
              </button>
            )}
            {isLoading && <ProcessingBtn title={t("Verify")} />}
          </div>
        </form>
      )}
      {/* Footer */}
      {isTokenValid && !isSuccess && (
        <div className="pt-5 mt-6 border-t border-gray-200">
          <div className="flex justify-center text-sm gap-x-2">
            {`${t("Didn't receive code")}?`}{" "}
            {isSent === true ? (
              <button
                type="button"
                className="cursor-not-allowed font-medium text-emerald-500/50"
              >
                {t("Sent")}
              </button>
            ) : (
              <button
                type="button"
                className="font-medium text-emerald-500 hover:text-emerald-600"
                onClick={() => {
                  resendToken();
                }}
              >
                {t("Resend")}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
