import { Link } from "react-router-dom";
// the hook
import { useTranslation } from "react-i18next";

function Message({ message }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col overflow-y-auto overflow-x-hidden bg-white">
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto">
            <div className="max-w-3xl m-auto mt-16">
              <div className="text-center px-4 space-y-10">
                <div className="text-2xl font-bold">{message}</div>
                <Link
                  to="/"
                  className="btn bg-emerald-500 px-10 py-2 hover:bg-emerald-600 text-white"
                >
                  {t("Back to home")}
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Message;
