import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function QuestionListItem(props) {
  // i18n
  const { t } = useTranslation();

  let url = `/quizzes/${props.quiz.id}/${props.set.id}/`;

  if (props.set.objects.length > 0) {
    // Go to the last object
    url += `edit/${props.set.objects[0]}`;
  } else {
    // Go to new object
    url += "new";
  }

  return (
    <Link
      to={url}
      className="block group shadow-lg rounded-lg border dark:border-gray-600 px-5 py-4 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-black transition-all border-gray-200"
    >
      <div className="flex justify-between items-center">
        {/* Left side */}
        <div className="flex items-center space-x-4">
          <div className="relative w-9 h-9 shrink-0 flex justify-center items-center">
            <div className="absolute font-extrabold text-white">
              {props.idx + 1}
            </div>
            <div className="w-9 h-9 rounded-full bg-emerald-500 dark:bg-emerald-600"></div>
          </div>
          <div className="text-black dark:text-gray-100">
            {props.set.title} {props.set.count === 0 && `(${t("Empty")})`}
            {props.set.count > 0 && `(${props.set.count})`}
          </div>
        </div>
        {/* Right side */}
        <div className="hidden z-40 group-hover:flex items-center pl-0 space-x-4">
          {/* Import */}
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.importSet(props.section, props.set);
            }}
            className="px-2 py-1 text-gray-500 hover:text-black dark:text-white whitespace-nowrap hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
              />
            </svg>
          </button>
          {/* Edit */}
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.editSet(props.section, props.set);
            }}
            className="px-2 py-1 text-gray-500 hover:text-black dark:text-white whitespace-nowrap hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>
          {/* Delete */}
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.deleteSet(props.section, props.set);
            }}
            className="px-2 py-1 text-rose-500 hover:text-rose-600 dark:text-rose-600 whitespace-nowrap hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </button>
        </div>
      </div>
    </Link>
  );
}

export default QuestionListItem;
