/* eslint-disable no-undef */
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocalStorage } from "@mantine/hooks";
import superjson from "superjson";

import useAuth from "../../hooks/useAuth"; // Auth
import ProcessingBtn from "../../components/btn/ProcessingBtn";
import axios from "../../api/axios";
import endpoints from "../../api/endpoints";

import Danger from "../../components/alert/Danger";

// the hook
import { useTranslation } from "react-i18next";

const SignInComponent = ({ redirect = true, setOpen, setPage }) => {
  // i18n
  const { t } = useTranslation();

  const [warning, setWarning] = useState();
  const [rememberMe, setRememberMe] = useState(true);
  const { setAuth } = useAuth();

  // Get Answer Statistics
  const [loginInfo, setloginInfo] = useLocalStorage({
    key: `loginInfo`,
    defaultValue: { email: "", password: "" },
    serialize: superjson.stringify,
    deserialize: (obj) => (obj === undefined ? {} : superjson.parse(obj)),
    getInitialValueInEffect: true,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const loginHandler = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    try {
      const res = await axios.post(
        endpoints.LOGIN_URL,
        JSON.stringify(formik.values),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      // Set Auth Payload
      setAuth(res?.data);

      // if Remember Me
      if (rememberMe) {
        // Save to Local Storage
        setloginInfo({
          email: formik.values.email,
          password: formik.values.password,
        });
      } else {
        // Remove from Local Storage
        setloginInfo({});
      }

      if (redirect) {
        // Return to previous page
        navigate(from, { replace: true });
      } else {
        setOpen(false);
      }
    } catch (err) {
      // Scroll to top
      window.scrollTo({ top: 0, behavior: "smooth" });

      if (!err?.response) {
        console.error("No Server res");
      } else {
        // API Error
        let error = err?.response?.data?.error;

        setWarning(<Danger title={error.msg} />);
      }
    } finally {
      setLoading(false);
    }
  };

  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: loginInfo.email || "",
      password: loginInfo.password || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("This is not a valid email"))
        .required(t("This field is required")),
      password: Yup.string().trim().required(t("This field is required")),
    }),
    enableReinitialize: true,
    onSubmit: loginHandler,
  });

  const setGoogleAuth = async (credential) => {
    // Send credential to server
    const res = await axios.post(
      endpoints.GOOGLE_SSO_AUTH,
      {
        credential,
      },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    // Set Auth Payload
    setAuth(res?.data);

    if (redirect) {
      // Return to previous page
      navigate(from, { replace: true });
    } else {
      setOpen(false);
    }
  };

  const nativeHandler = (event) => {
    const data = JSON.parse(event.data);
    if (data.credential) {
      setGoogleAuth(data.credential);
    }
  };

  useEffect(() => {
    document.addEventListener("message", nativeHandler);
    // This is for iOS WebView
    window.addEventListener("message", nativeHandler);

    // Remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener("message", nativeHandler);
      window.removeEventListener("message", nativeHandler);
    };
  }, []);

  useEffect(() => {
    try {
      // Google SSO
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: async (response) => {
          if (response.credential) {
            await setGoogleAuth(response.credential);
          }
        },
      });

      google.accounts.id.renderButton(document.getElementById("googleSSOBtn"), {
        theme: "filled_blue",
        size: "large",
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="text-black dark:text-white">
      <h1 className="text-3xl font-bold mb-6">{t("Welcome back!")} ✨</h1>

      {/* Warning */}
      {warning}

      {/* Form */}
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="email">
              {t("Email")}
            </label>
            <input
              name="email"
              className="form-input w-full text-lg sm:text-base dark:bg-gray-800 dark:border-gray-600 dark:text-white"
              type="string"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p className="text-red-600 text-sm">
              {formik.errors.email && formik.touched.email
                ? `${t("Error")}: ${formik.errors.email}`
                : null}
            </p>
          </div>
          <div>
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="password"
            >
              {t("Password")}
            </label>
            <input
              name="password"
              className="form-input w-full text-lg sm:text-base dark:bg-gray-800 dark:border-gray-600 dark:text-white"
              type="password"
              autoComplete="on"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p className="text-red-600 text-sm">
              {formik.errors.password && formik.touched.password
                ? `${t("Error")}: ${formik.errors.password}`
                : null}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between mt-6">
          <div className="mr-1">
            <label className="flex items-center">
              <input
                name="rememberMe"
                type="checkbox"
                className="form-checkbox"
                checked={rememberMe}
                onChange={(e) => {
                  setRememberMe(e.target.checked);
                }}
              />
              <span className="text-sm ml-2">{t("Remember me")}</span>
            </label>
          </div>
        </div>
        <div className="flex items-center justify-end mt-6">
          <div className="mr-1">
            {redirect === true ? (
              <Link
                className="text-sm underline hover:no-underline"
                to="/reset-password"
              >
                {t("Forgot Password?")}
              </Link>
            ) : (
              <button
                type="button"
                className="text-sm underline hover:no-underline"
                onClick={() => {
                  setPage("forgot");
                }}
              >
                {t("Forgot Password?")}
              </button>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between mt-6">
          {!isLoading && (
            <button
              type="submit"
              className="btn bg-emerald-500 hover:bg-emerald-600 text-white w-full"
            >
              {t("Sign In")}
            </button>
          )}
          {isLoading && <ProcessingBtn title={t("Sign In")} />}
        </div>
      </form>

      {/* Footer */}
      <div className="pt-5 mt-6 border-t border-gray-200 space-y-6">
        <div className="flex items-center justify-between">
          {window.isNative ? (
            <button
              onClick={() => {
                window.ReactNativeWebView.postMessage("googleLoginClicked");
              }}
              className="btn bg-[#4285F4] text-white w-full"
            >
              {t("Sign In with Google")}
            </button>
          ) : (
            <div
              className="w-full flex items-center justify-center"
              id="googleSSOBtn"
            />
          )}
        </div>
        <div className="flex justify-center text-sm gap-x-2">
          {t("Don’t you have an account?")}{" "}
          {redirect === true ? (
            <Link
              className="font-medium text-emerald-500 hover:text-emerald-600"
              to="/signup"
            >
              {t("Sign Up")}
            </Link>
          ) : (
            <button
              className="font-medium text-emerald-500 hover:text-emerald-600"
              onClick={() => {
                setPage("signup");
              }}
            >
              {t("Sign Up")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignInComponent;
