import ModalBlank from "../../components/ModalBlank";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@mantine/hooks";
import { Link } from "react-router-dom";
import superjson from "superjson";

const QuestionListModal = (props) => {
  // i18n
  const { t } = useTranslation();

  // Get Answer Statistics
  const [answerStatistics] = useLocalStorage({
    key: `practice-${props.quizId}-answer-statistics`,
    defaultValue: new Map(),
    serialize: superjson.stringify,
    deserialize: (map) =>
      map === undefined ? new Map() : superjson.parse(map),
    getInitialValueInEffect: false,
  });

  const cancelHandler = (e) => {
    e.stopPropagation();
    props.setImportModalOpen(false);
  };

  return (
    <ModalBlank
      id="question-list-modal"
      modalOpen={props.importModalOpen}
      setModalOpen={props.setImportModalOpen}
    >
      <div className="relative p-5 flex flex-col w-full h-full">
        {/* Content */}
        {/* Modal header */}
        <div className="mb-2 flex justify-between items-center">
          <div className="text-lg font-semibold text-black dark:text-white">
            {t("Question List")}
          </div>

          <button
            onClick={cancelHandler}
            className="block rounded-full bg-gray-100 text-gray-500 hover:text-black"
          >
            <span className="sr-only">Back</span>
            <svg width="32" height="32" viewBox="0 0 32 32">
              <path
                className="fill-current"
                d="M15.95 14.536l4.242-4.243a1 1 0 111.415 1.414l-4.243 4.243 4.243 4.242a1 1 0 11-1.415 1.415l-4.242-4.243-4.243 4.243a1 1 0 01-1.414-1.415l4.243-4.242-4.243-4.243a1 1 0 011.414-1.414l4.243 4.243z"
              />
            </svg>
          </button>
        </div>

        <div className="py-6">
          <div className="flex justify-start items-center">
            <div className="w-full gap-x-4 grid gap-y-8 grid-cols-5 sm:grid-cols-10">
              {[...answerStatistics].map((question, idx) => (
                <Link
                  onClick={cancelHandler}
                  to={`/practice/${props.quizId}/${props.setId}?questionId=${question[0]}`}
                  key={question[0]}
                  className={`relative ${
                    question[1] === "correct" &&
                    "bg-emerald-500 dark:bg-emerald-600"
                  } ${
                    question[1] === "wrong" && "bg-rose-500 dark:bg-rose-600"
                  } ${
                    (question[1] === "touched" ||
                      question[1] === "untouched") &&
                    "bg-gray-300 dark:bg-gray-400"
                  } rounded-md w-14 h-14 flex justify-center items-center`}
                >
                  <div className="font-bold text-white truncate pointer-events-none">
                    Q{idx + 1}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ModalBlank>
  );
};

export default QuestionListModal;
