import React, { useState, useEffect } from "react";
import BannerItem from "./BannerTableItem";

export default function BannerTable({ banners }) {
  return (
    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {banners.map((banner) => {
        return <BannerItem key={banner.id} banner={banner} />;
      })}
    </div>
  );
}
