/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SignInComponent from "./SignInComponent";
import SignUpComponent from "./SignUpComponent";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import MFAComponent from "./MFAComponent";

export default function SignInModal(props) {
  const cancelButtonRef = useRef(null);
  const [page, setPage] = useState("signin");
  const [token, setToken] = useState("");

  const renderPage = () => {
    switch (page) {
      case "signin":
        return (
          <SignInComponent
            redirect={false}
            setOpen={props.setOpen}
            setPage={setPage}
          />
        );
      case "signup":
        return (
          <SignUpComponent
            redirect={false}
            setOpen={props.setOpen}
            setToken={setToken}
            setPage={setPage}
          />
        );
      case "forgot":
        return (
          <ForgotPasswordComponent
            redirect={false}
            setOpen={props.setOpen}
            setPage={setPage}
          />
        );
      case "mfa":
        return (
          <MFAComponent
            redirect={false}
            setOpen={props.setOpen}
            setPage={setPage}
            mfaToken={token}
          />
        );
      default:
        return (
          <SignInComponent
            redirect={false}
            setOpen={props.setOpen}
            setPage={setPage}
          />
        );
    }
  };

  useEffect(() => {
    if (props.open) {
      setPage("signin");
      setToken("");
    }
  }, [props.open]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-white dark:bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full p-6">
              {renderPage()}
              <button
                onClick={() => {
                  props?.setOpen(false);
                }}
                className="absolute top-5 right-5 block rounded-full bg-gray-100 text-gray-500 hover:text-black"
              >
                <span className="sr-only">Back</span>
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <path
                    className="fill-current"
                    d="M15.95 14.536l4.242-4.243a1 1 0 111.415 1.414l-4.243 4.243 4.243 4.242a1 1 0 11-1.415 1.415l-4.242-4.243-4.243 4.243a1 1 0 01-1.414-1.415l4.243-4.242-4.243-4.243a1 1 0 011.414-1.414l4.243 4.243z"
                  />
                </svg>
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
