import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Transition from "../utils/Transition";
import { useTranslation } from "react-i18next";
import EmptyProfile from "../images/EmptyProfile.webp";
import { useLocalStorage } from "@mantine/hooks";

function DropdownProfile(props) {
  const { t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // Dark Mode
  const [theme, setTheme] = useLocalStorage({
    key: "theme",
    defaultValue: "",
  });

  const setThemeMode = (mode) => {
    setTheme(mode);

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme.includes("dark") ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const renderUserIcon = (user) => {
    return (
      <div className="w-12 h-12 aspect-square">
        <img
          className="w-full h-full object-cover rounded-full"
          src={user?.avatar || EmptyProfile}
          width="32"
          height="32"
          alt="User"
        />
      </div>
    );
  };

  return (
    <div className="relative inline-flex items-center gap-x-2">
      <button
        ref={trigger}
        className="inline-flex gap-x-2 py-2 px-4 items-center w-full rounded-md text-sm font-medium text-black hover:bg-gray-200 dark:text-white dark:hover:bg-gray-900"
        aria-haspopup="true"
        onClick={() => {
          if (props?.customToggle) {
            props.customToggle();
          } else {
            setDropdownOpen(!dropdownOpen);
          }
        }}
        aria-expanded={dropdownOpen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 dark:text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white dark:bg-gray-900 dark:border-gray-600 rounded-lg shadow-lg overflow-hidden mt-1 ${
          props.align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out no-duration transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="py-2 px-3 border-b border-gray-200 w-72">
            <div className="flex items-center gap-x-4">
              {renderUserIcon(props?.user)}
              <div>
                <div className="font-bold text-lg text-black dark:text-white">
                  {props?.user?.name}
                </div>
              </div>
            </div>
          </div>
          <ul>
            <li>
              <Link
                className="font-medium text-md text-black hover:text-white hover:bg-emerald-600 dark:text-white flex items-center py-1.5 px-4"
                to="/library"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {t("Library")}
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-md text-black hover:text-white hover:bg-emerald-600 dark:text-white flex items-center py-1.5 px-4"
                to="/settings"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {t("Settings")}
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  setThemeMode(theme !== "dark" ? "dark" : "light");
                }}
                className="w-full font-medium text-md text-black hover:text-white hover:bg-emerald-600 dark:text-white flex items-center py-1.5 px-4"
              >
                {/* Sun */}
                {theme !== "dark" && t("Dark mode")}

                {/* Moon */}
                {theme === "dark" && t("Light mode")}
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  if (i18n.language.includes("en")) {
                    i18n.changeLanguage("zh-Hant");
                  } else {
                    i18n.changeLanguage("en");
                  }
                  setDropdownOpen(!dropdownOpen);
                }}
                className="w-full font-medium text-md text-black hover:text-white hover:bg-emerald-600 dark:text-white flex items-center py-1.5 px-4"
              >
                {!i18n.language.includes("en") && <>Switch English</>}

                {!i18n.language.includes("zh") && <>設換語言至中文</>}
              </button>
            </li>
            <li>
              <Link
                className="font-medium text-md text-black hover:text-white hover:bg-emerald-600 dark:text-white flex items-center py-1.5 px-4"
                to="/signout"
                state={{ from: location }}
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {t("Sign Out")}
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default DropdownProfile;
