/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function RedeemModal(props) {
  const cancelButtonRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  // i18n
  const { t } = useTranslation();

  const onClose = () => {
    props.setOpen(false);

    // Reset searchParams
    searchParams.delete("paymentStatus");
    searchParams.delete("stripeSessionId");
    setSearchParams(searchParams);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-white dark:bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full p-6">
              <div>
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-center h3 font-bold text-black dark:text-white"
                  >
                    {t("Redeem Successfully")}
                  </Dialog.Title>
                </div>

                {props?.redeemInfo?.plans && props?.redeemInfo?.length > 0 && (
                  <div className="mt-2 space-y-4">
                    <div
                      role="list"
                      className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2"
                    >
                      {props?.redeemInfo?.plans?.map((plan, idx) => (
                        <Link
                          to={`/practice/${plan.quiz.id}`}
                          target="_blank"
                          key={plan.id}
                          className="col-span-1 flex shadow-sm bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-md hover:scale-105 transition-all"
                        >
                          <div className="flex items-center justify-center h-full w-16 text-white text-lg font-extrabold rounded-l-md">
                            {idx + 1}
                          </div>
                          <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800 rounded-r-md truncate">
                            <div className="text-left flex-1 px-4 py-2 text-sm truncate">
                              <div className="font-medium text-black dark:text-white">
                                {plan.quiz.title} - {plan.title}
                              </div>
                              <p className="text-black dark:text-gray-400">
                                {plan.expiredOn === undefined
                                  ? t("Lifetime access")
                                  : `${t("Expired On")} ${moment(
                                      plan.expiredOn
                                    ).format("DD-MM-YYYY")}`}
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-5 sm:mt-6 sm:grid grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-black hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => onClose()}
                  ref={cancelButtonRef}
                >
                  {t("Close")}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
