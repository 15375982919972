import React, { Fragment, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useAuth from "../../hooks/useAuth";
import { useLocalStorage } from "@mantine/hooks";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import UserMenu from "../../components/DropdownProfile";
import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";

const PracticeHeader = (props) => {
  // i18n
  const { t, i18n } = useTranslation();

  const { auth } = useAuth();
  const user = auth?.user;
  const location = useLocation();

  // Dark Mode
  const [theme, setTheme] = useLocalStorage({
    key: "theme",
    defaultValue: "",
  });

  const setThemeMode = (mode) => {
    setTheme(mode);

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme.includes("dark") ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  const share = async () => {
    const url = `https://app.practtice.com/practice/${props.quizId}`;
    try {
      await navigator.share({ url });
    } catch {
      navigator.clipboard.writeText(url);
    }

    toast.success(`${t("Copied to clipboard")}!`);
  };

  return (
    <header className="fixed w-full top-0 bg-white dark:bg-black transition-all z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          <div className="flex items-center space-x-4">
            <div className="flex gap-x-2">
              {/* Exit */}
              {props.question && (
                <Link
                  to={`/practice/${props.quizId}`}
                  className="inline-flex justify-center w-full rounded-md px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-white dark:hover:text-gray-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                    />
                  </svg>
                </Link>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-2">
            {/* Share */}
            {!props.question && (
              <button
                onClick={() => share()}
                className="inline-flex gap-x-2 py-2 px-4 items-center w-full rounded-md text-sm font-medium text-black hover:bg-gray-200 dark:text-white dark:hover:bg-gray-800"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
                  />
                </svg>
              </button>
            )}

            {/* Menu */}
            {props.question && (
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-center w-full rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-white dark:hover:text-gray-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {props?.autoNext !== null && props.setAutoNext !== null && (
                      <Menu.Item>
                        <button
                          onClick={() => {
                            props.setAutoNext(!props.autoNext);
                          }}
                          className="w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 block px-4 py-2 text-sm text-center rounded-t-md"
                        >
                          {t("Auto Next")}:{" "}
                          {props.autoNext ? t("On") : t("Off")}
                        </button>
                      </Menu.Item>
                    )}

                    <Menu.Item>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setQuestionListModalOpen(true);
                        }}
                        className="w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 block px-4 py-2 text-sm text-center rounded-t-md"
                      >
                        {t("Question List")}
                      </button>
                    </Menu.Item>
                    {props?.mode !== "redo" && (
                      <Menu.Item>
                        <Link
                          to={`/practice/${props.quizId}/${props.quiz.id}/results`}
                          className="w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 block px-4 py-2 text-sm text-center rounded-t-md"
                        >
                          {t("See Result")}
                        </Link>
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      <button
                        onClick={() => {
                          setThemeMode(theme !== "dark" ? "dark" : "light");
                        }}
                        className="w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 block px-4 py-2 text-sm text-center rounded-t-md"
                      >
                        {t("Theme")}:{" "}
                        {theme !== "dark" ? t("Dark") : t("Light")}
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      <button
                        onClick={() => {
                          if (i18n.language.includes("en")) {
                            i18n.changeLanguage("zh-Hant");
                          } else {
                            i18n.changeLanguage("en");
                          }
                        }}
                        className="w-full text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 block px-4 py-2 text-sm text-center rounded-t-md"
                      >
                        {t("Language")}:{" "}
                        {!i18n.language.includes("zh") &&
                          !i18n.language !== "" &&
                          "En"}
                        {!i18n.language.includes("en") &&
                          !i18n.language !== "" &&
                          "中"}
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to={`/practice/${props.quizId}`}
                        className="bg-rose-500 hover:bg-rose-600 dark:bg-rose-600 dark:hover:bg-rose-500 text-white block px-4 py-2 text-sm text-center rounded-b-md"
                      >
                        {t("Exit")}
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}

            {/* User */}
            {!props.question &&
              (user ? (
                <UserMenu
                  align="right"
                  user={
                    user !== null
                      ? user
                      : {
                          name: "User",
                          role: "N/A",
                          credit: "N/A",
                          plan: "N/A",
                        }
                  }
                  practiceObj={props}
                />
              ) : (
                <Link
                  to="/signin"
                  state={{ from: location }}
                  className="inline-flex justify-center w-full rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-white dark:hover:text-gray-100 whitespace-nowrap"
                >
                  {t("Sign In")}
                </Link>
              ))}
          </div>
        </div>
      </div>
      {/* Dynamic Title */}
      <HelmetProvider>
        <Helmet>
          {props.question && (
            <title>
              {`Q${
                props.quiz.questions
                  .map((question) => question.id)
                  .indexOf(props.question.id) + 1
              }. ${props.question.title}`}{" "}
              | Practtice
            </title>
          )}
          {!props.question && !props.result && (
            <title>{props.quiz.title} | Practtice</title>
          )}
          {!props.question && props.result && (
            <title>
              {`${props.quiz.title} ${t("Practice Result")}`} | Practtice
            </title>
          )}
          <meta
            property="og:image"
            content={
              props.quiz.coverImg ||
              "https://practtice.com/images/seo/seo-banner.jpg"
            }
          />
        </Helmet>
      </HelmetProvider>
    </header>
  );
};

export default PracticeHeader;
