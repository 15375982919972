import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "../../api/axios";
import endpoints from "../../api/endpoints";

import Message from "../message/Message";
import Loader from "../../partials/Loader";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import BottomAppBar from "../../partials/ui/BottomAppBar";

// the hook
import { useTranslation } from "react-i18next";

const Verify = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  let { verify_token } = useParams();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const res = await axios.post(endpoints.VERIFY_URL, {
          id: verify_token,
        });
        console.log(res);
      } catch (err) {
        console.error(err.response);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    verifyToken();
  }, []);

  if (isLoading) return <Loader />;
  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header title="Verify" />

        <main>
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 max-w-md mx-auto space-y-10 text-black dark:text-white">
            {hasError ? (
              <Message message={t("Invalid verification token")} />
            ) : (
              <Message message={t("Successfully verified")} />
            )}
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
};

export default Verify;
