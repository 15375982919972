import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const QuestionsNavDropdown = ({ quiz, set, questionId = "" }) => {
  const { t } = useTranslation();

  const questionIdx = set.questions.map((item) => item.id).indexOf(questionId);

  if (set.questions.length === 0)
    return (
      <>
        <span className="dark:text-white">
          {t("Question")}. {set.questions.length + 1} ({t("of")}{" "}
          {set.questions.length + 1}){" "}
        </span>
      </>
    );
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className=" hover:text-emerald-600 text-emerald-500">
          <div className="flex items-center gap-x-2">
            {questionIdx !== -1 && (
              <span>
                {t("Question")}. {questionIdx + 1} ({t("of")}{" "}
                {set.questions.length}){" "}
              </span>
            )}
            {questionIdx === -1 && (
              <span>
                {t("Question")}. {set.questions.length + 1} ({t("of")}{" "}
                {set.questions.length + 1}){" "}
              </span>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 w-full left-0 mt-2 whitespace-nowrap text-center rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-medium">
          <div className="">
            <Menu.Item key={set.questions.length + 1}>
              <Link
                to={`/quizzes/${quiz.id}/${set.id}/new`}
                className="bg-indigo-500 dark:bg-indigo-600 text-white block px-4 py-2 text-sm rounded-t-md"
              >
                {t("New Question")}
              </Link>
            </Menu.Item>
            <div className="max-h-48 overflow-auto">
              {set.questions.map((item, idx) => {
                return (
                  <Menu.Item key={idx}>
                    {
                      <Link
                        to={`/quizzes/${quiz.id}/${set.id}/edit/${item.id}`}
                        className={classNames(
                          questionId === item.id
                            ? "bg-gray-100 text-emerald-500"
                            : "text-emerald-600 hover:bg-gray-100",
                          "w-full text-left block px-4 py-2 text-sm truncate"
                        )}
                      >
                        Q{idx + 1}. {item.title}
                      </Link>
                    }
                  </Menu.Item>
                );
              })}
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default QuestionsNavDropdown;
