import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import BottomAppBar from "../../partials/ui/BottomAppBar";
import SignUpComponent from "../../partials/auths/SignUpComponent";

function Signup() {
  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/* Header */}
        <Header title="Sign Up" />

        <main>
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 space-y-10 max-w-md mx-auto text-black dark:text-white">
            <SignUpComponent />
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}

export default Signup;
