import { useTranslation } from "react-i18next";

const BottomPracticeBar = ({ quiz, start, bookmark, like }) => {
  const { t } = useTranslation();

  return (
    <div className="pb-safe md:hidden fixed bottom-0 w-full bg-gray-100 dark:bg-gray-900">
      <nav className="flex items-center w-full py-2 px-2 gap-x-1">
        {/* Bookmark Button */}
        <button
          type="button"
          onClick={() => like()}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md dark:text-white text-black gap-x-1"
        >
          {quiz.isLiked ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 dark:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                className={quiz.isLiked ? "fill-current" : ""}
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 dark:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                className={quiz.isLiked ? "fill-current" : ""}
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
              />
            </svg>
          )}
          {quiz.likeCount > 0 && quiz.likeCount}
        </button>

        <div className="flex space-x-2 items-center w-full">
          {/* Bookmark Practice */}
          <button
            type="button"
            onClick={() => bookmark()}
            className="flex w-full items-center justify-center px-4 py-2 border border-gray-200 shadow-sm font-medium rounded-md text-white bg-black"
          >
            {quiz.isBookmarked ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
                />
              </svg>
            )}
            {quiz.isBookmarked ? t("Bookmarked") : t("Bookmark")}
          </button>

          {/* Start Practice */}
          <button
            type="button"
            onClick={() => {
              // Get the first set from quiz
              const set = quiz.sections[0].sets[0];
              start(set, "quiz");
            }}
            className="flex w-full items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-emerald-500 dark:bg-emerald-600"
          >
            {t("Start quiz")}
          </button>
        </div>
      </nav>
    </div>
  );
};

export default BottomPracticeBar;
