import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function QuizTableItem(props) {
  const { t } = useTranslation();

  return (
    <tr>
      {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input
              id={props.quiz.id}
              className="form-checkbox"
              type="checkbox"
              onChange={props.quiz.handleClick}
              checked={props.quiz.isChecked}
            />
          </label>
        </div>
      </td> */}
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <div className="text-left">{props.quiz.title}</div>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => {
              props.setUserHandler(props.quiz);
            }}
            className="dark:text-white hover:bg-black/10 block px-4 py-1 text-sm rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>{" "}
          {props.quiz?.user?.name}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        {props.quiz.isPremium ? (
          <div className="text-xs inline-flex font-extrabold bg-indigo-100 text-indigo-600 rounded-full text-center px-2.5 py-1">
            {t("Premium")}
          </div>
        ) : (
          <div className="text-xs inline-flex font-extrabold bg-emerald-100 dark:bg-emerald-200 text-emerald-600 rounded-full text-center px-2.5 py-1">
            {t("Free")}
          </div>
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => {
              props.setThemeHandler(props.quiz);
            }}
            className="dark:text-white hover:bg-black/10 block px-4 py-1 text-sm rounded-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>
          {props.quiz?.themes?.map((theme) => {
            return (
              <div className="w-fit inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                {theme}
              </div>
            );
          })}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <div className="flex items-center space-x-2">
          <Link
            to={`/practice/${props.quiz.id}`}
            className="bg-black text-white block px-4 py-1 text-sm rounded-md"
          >
            {t("View")}
          </Link>
          <button
            onClick={() => {
              props.deleteQuizHandler(props.quiz);
            }}
            className="bg-rose-500 text-white block px-4 py-1 text-sm rounded-md"
          >
            {t("Delete")}
          </button>
        </div>
      </td>
    </tr>
  );
}

export default QuizTableItem;
