import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebouncedValue, usePagination } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";
import NewQuizModal from "../../partials/quizzes/NewQuizModal";
import PaginationNumeric from "../../components/PaginationNumeric";
import QuizTable from "../../partials/quizzes/QuizTable";
import BottomAppBar from "../../partials/ui/BottomAppBar";

function QuizHome() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { t } = useTranslation();

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth?.user;

  // Get Quizs
  const [quizzes, setQuizs] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [page, onChange] = useState(1);
  const [totalPage, setTotalPage] = useState(10);
  const pagination = usePagination({ total: totalPage, page, onChange });

  // Render Quizs
  const renderQuizs = async () => {
    try {
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        console.log("Search String: " + debouncedSearchString);
        params = { filter: debouncedSearchString, fields: "title,content" };
      }

      params = { ...params, page: page, limit: 12 };

      const res = await axiosPrivate.get(endpoints.QUESTIONSETS_URL, {
        signal: controller.signal,
        params: params,
      });

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages);
      }

      setQuizs(res?.data?.docs);
      setTotalPage(res?.data?.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Render Quizs
    renderQuizs();
  }, [debouncedSearchString, page]);

  const createQuiz = async () => {
    try {
      setCreateModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          title="Library"
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto">
            {/* <WelcomeBanner user={user} /> */}

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-black dark:text-white font-bold">
                  {t("Library")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2">
                {/* Search form */}
                <SearchForm value={searchString} setValue={setSearchString} />
                {/* Filter button */}
                {/* <FilterButton align="right" /> */}
                <div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      createQuiz();
                    }}
                    className="btn group bg-emerald-500 hover:bg-emerald-600 dark:bg-emerald-600 dark:hover:bg-emerald-500 text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mr-2 stroke-white-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    {t("Create")}
                  </button>
                </div>
              </div>
            </div>

            {/* Modal */}
            <NewQuizModal open={createModalOpen} setOpen={setCreateModalOpen} />

            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 sm:mb-0">
                {/* <ul className="flex flex-wrap -m-1">
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-emerald-500 text-white duration-150 ease-in-out">
                      All <span className="ml-1 text-emerald-200">67</span>
                    </button>
                  </li>
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-gray-200 hover:border-gray-300 shadow-sm bg-white text-gray-500 duration-150 ease-in-out">
                      Draft <span className="ml-1 text-gray-400">14</span>
                    </button>
                  </li>
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-gray-200 hover:border-gray-300 shadow-sm bg-white text-gray-500 duration-150 ease-in-out">
                      Published <span className="ml-1 text-gray-400">34</span>
                    </button>
                  </li>
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-gray-200 hover:border-gray-300 shadow-sm bg-white text-gray-500 duration-150 ease-in-out">
                      Premium <span className="ml-1 text-gray-400">19</span>
                    </button>
                  </li>
                </ul> */}
              </div>

              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Delete button */}
                {/* <DeleteButton
                  selectedItems={selectedItems}
                  deleteQuiz={moveToTrashMany}
                /> */}
                {/* Filter button */}
                {/* <FilterButton align="right" /> */}
              </div>
            </div>

            {/* Table */}
            <QuizTable quizzes={quizzes} isLoading={isLoading} />

            {!isLoading && quizzes.length === 0 && (
              <div className="text-center py-10">
                <h3 className="mt-2 text-xl font-medium text-black dark:text-white">
                  {searchString ? "Not found" : "No Quiz"}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t("No result. Get started by creating a new quiz")}.
                </p>
                <div className="mt-6">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      createQuiz();
                    }}
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                  >
                    {t("Create Quiz")}
                  </button>
                </div>
              </div>
            )}

            {/* Pagination */}
            <div className="mt-8">
              <PaginationNumeric
                pagination={pagination}
                page={page}
                onChange={onChange}
              />
            </div>
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}

export default QuizHome;
