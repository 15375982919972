import { ToastContainer } from "react-toastify";

const ToastMessage = () => {
  // Toastify
  const contextClass = {
    success: "bg-emerald-600",
    error: "bg-rose-600",
    info: "bg-gray-600",
    warning: "bg-amber-400",
    default: "bg-indigo-600",
    dark: "bg-white-600 font-gray-300",
  };

  return (
    <ToastContainer
      toastClassName={({ type }) =>
        contextClass[type || "default"] +
        " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
      }
      bodyClassName={() =>
        "text-sm flex items-center font-white font-med block p-3 top-5"
      }
      position="top-center"
      autoClose={1500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="colored"
    />
  );
};

export default ToastMessage;
