import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../images/logo/Logo.svg";
import { ReactComponent as FullLogo } from "../images/logo/MergedDark.svg";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@mantine/hooks";
import SignInModal from "../partials/auths/SignInModal";

function Sidebar({ user }) {
  const location = useLocation();
  const { pathname } = location;
  const { t, i18n } = useTranslation();

  // Dark Mode
  const [theme, setTheme] = useLocalStorage({
    key: "theme",
    defaultValue: "",
  });

  const [open, setOpen] = useState(false);

  const setThemeMode = (mode) => {
    setTheme(mode);

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme.includes("dark") ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  return (
    <div>
      {/* Redeem Modal */}
      <SignInModal open={open} setOpen={setOpen} />
      <div className="hidden md:block">
        {/* Sidebar */}
        <div
          id="sidebar"
          className="flex flex-col z-40 left-0 top-0 transform h-screen overflow-y-scroll no-scrollbar w-20 lg:!w-64 shrink-0 bg-black transition-allborder-r border-gray-700 p-4 transition-all no-duration ease-in-out translate-x-0"
        >
          {/* Sidebar header */}
          <div className="flex justify-between mb-10 pr-3 sm:px-2">
            {/* Logo */}
            <NavLink end to="/" className="block mt-5">
              <Logo className="h-8 lg:hidden" />
              <FullLogo className="h-6 hidden lg:block" />
            </NavLink>
          </div>

          {/* Links */}
          <div className="h-full flex justify-between flex-col">
            {/* Pages group */}
            <div>
              <ul className="mt-3">
                {/* Home */}
                <li
                  className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${
                    pathname === "/" && "bg-black"
                  }`}
                >
                  <NavLink
                    end
                    to="/"
                    className={`px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150 ${
                      pathname === "/" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 group-hover:scale-110"
                        >
                          <path
                            className={`${
                              pathname === "/" && "text-emerald-500"
                            }`}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                          />
                        </svg>

                        <span
                          className={`${
                            pathname === "/" && "text-emerald-500"
                          } hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                        >
                          {t("Home")}
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>
                {/* Search */}
                <li
                  className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${
                    pathname.includes("/search") && "bg-black"
                  }`}
                >
                  <NavLink
                    end
                    to="/search"
                    className={`px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150 ${
                      pathname.includes("/search") && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 group-hover:scale-110"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className={`${
                              pathname.includes("/search") && "text-emerald-500"
                            }`}
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>

                        <span
                          className={`${
                            pathname.includes("/search") && "text-emerald-500"
                          } hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                        >
                          {t("Search")}
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>
                {/* Profile */}
                <li
                  className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${
                    pathname.includes("library") && "bg-black"
                  }`}
                >
                  <NavLink
                    end
                    to="/library"
                    className={`px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150 ${
                      pathname.includes("/library") && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 group-hover:scale-110"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className={`${
                              pathname.includes("/library") &&
                              "text-emerald-500"
                            }`}
                            d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125z"
                          />
                        </svg>

                        <span
                          className={`${
                            pathname.includes("/library") && "text-emerald-500"
                          } hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                        >
                          {t("Library")}
                        </span>
                      </div>
                    </div>
                  </NavLink>
                </li>
              </ul>
              {user && user?.role === "admin" && (
                <>
                  <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
                    <span
                      className="hidden md:block lg:hidden text-center w-6"
                      aria-hidden="true"
                    >
                      •••
                    </span>
                    <span className="hidden lg:block">{t("Admin")}</span>
                  </h3>
                  <ul className="mt-3">
                    {/* All Users */}
                    <li
                      className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${
                        pathname.includes("/allusers") && "bg-black"
                      }`}
                    >
                      <NavLink
                        end
                        to="/allusers"
                        className={`px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes("/allusers") &&
                          "hover:text-gray-200"
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 group-hover:scale-110"
                            >
                              <path
                                className={`${
                                  pathname.includes("/allusers") &&
                                  "text-emerald-500"
                                }`}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                              />
                            </svg>

                            <span
                              className={`${
                                pathname.includes("/allusers") &&
                                "text-emerald-500"
                              } hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                            >
                              {t("All Users")}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    {/* All Quizzes */}
                    <li
                      className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${
                        pathname.includes("/allquizzes") && "bg-black"
                      }`}
                    >
                      <NavLink
                        end
                        to="/allquizzes"
                        className={`px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes("/allquizzes") &&
                          "hover:text-gray-200"
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 group-hover:scale-110"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className={`${
                                  pathname.includes("/allquizzes") &&
                                  "text-emerald-500"
                                }`}
                                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                              />
                            </svg>

                            <span
                              className={`${
                                pathname.includes("/allquizzes") &&
                                "text-emerald-500"
                              } hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                            >
                              {t("All Quizzes")}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    {/* All Coupons */}
                    <li
                      className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${
                        pathname.includes("/allcoupons") && "bg-black"
                      }`}
                    >
                      <NavLink
                        end
                        to="/allcoupons"
                        className={`px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes("/allcoupons") &&
                          "hover:text-gray-200"
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 group-hover:scale-110"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className={`${
                                  pathname.includes("/allcoupons") &&
                                  "text-emerald-500"
                                }`}
                                d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
                              />
                            </svg>

                            <span
                              className={`${
                                pathname.includes("/allcoupons") &&
                                "text-emerald-500"
                              } hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                            >
                              {t("All Coupons")}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    {/* All Banners */}
                    <li
                      className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${
                        pathname.includes("/allbanners") && "bg-black"
                      }`}
                    >
                      <NavLink
                        end
                        to="/allbanners"
                        className={`px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes("/allbanners") &&
                          "hover:text-gray-200"
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className={`${
                                  pathname.includes("/allbanners") &&
                                  "text-emerald-500"
                                }`}
                                d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5"
                              />
                            </svg>

                            <span
                              className={`${
                                pathname.includes("/allbanners") &&
                                "text-emerald-500"
                              } hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                            >
                              {t("All Banners")}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    {/* All Themes */}
                    <li
                      className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0 ${
                        pathname.includes("/allthemes") && "bg-black"
                      }`}
                    >
                      <NavLink
                        end
                        to="/allthemes"
                        className={`px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes("/allthemes") &&
                          "hover:text-gray-200"
                        }`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className={`${
                                  pathname.includes("/allthemes") &&
                                  "text-emerald-500"
                                }`}
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              />
                            </svg>

                            <span
                              className={`${
                                pathname.includes("/allthemes") &&
                                "text-emerald-500"
                              } hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                            >
                              {t("All Themes")}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </>
              )}
            </div>
            {/* More group */}
            <div>
              <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
                <span
                  className="hidden md:block lg:hidden text-center w-6"
                  aria-hidden="true"
                >
                  •••
                </span>
                <span className="hidden lg:block">{t("Others")}</span>
              </h3>
              <ul className="mt-3">
                {/* Theme */}
                <li
                  className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0`}
                >
                  <button
                    className={`px-3 py-2 block group w-full text-gray-200 hover:text-white truncate transition duration-150`}
                    onClick={() => {
                      setThemeMode(theme !== "dark" ? "dark" : "light");
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        {/* Sun */}
                        {theme.includes("light") && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 group-hover:scale-110"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                              />
                            </svg>
                            <span className="hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200">
                              {t("Light mode")}
                            </span>
                          </>
                        )}

                        {/* Moon */}
                        {theme.includes("dark") && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 group-hover:scale-110"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                              />
                            </svg>
                            <span className="hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200">
                              {t("Dark mode")}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </button>
                </li>

                {/* Language */}
                <li
                  className={`hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0`}
                >
                  <button
                    className={`px-3 py-2 block group w-full text-gray-200 hover:text-white truncate transition duration-150`}
                    onClick={() => {
                      if (i18n.language === "en") {
                        i18n.changeLanguage("zh-Hant");
                      } else {
                        i18n.changeLanguage("en");
                      }
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 group-hover:scale-110"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
                          />
                        </svg>

                        <span className="hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200">
                          {!i18n.language.includes("en") && "English"}
                          {!i18n.language.includes("zh") && "繁體中文"}
                        </span>
                      </div>
                    </div>
                  </button>
                </li>
                {/* Logout */}
                {user ? (
                  <li className="hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0">
                    <NavLink
                      to="/settings"
                      className={`px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 group-hover:scale-110"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>

                          <span
                            className={`hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                          >
                            {t("Settings")}
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                ) : (
                  <li className="hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0">
                    <button
                      onClick={() => {
                        setOpen(true);
                      }}
                      className={`w-full h-full px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 group-hover:scale-110"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                            />
                          </svg>

                          <span
                            className={`hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                          >
                            {t("Sign In")}
                          </span>
                        </div>
                      </div>
                    </button>
                  </li>
                )}

                {user && (
                  <li className="hover:bg-white/10 transition-all rounded-md mb-0.5 last:mb-0">
                    <NavLink
                      to="/signout"
                      className={`px-3 py-2 block group text-gray-200 hover:text-white truncate transition duration-150`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                            />
                          </svg>

                          <span
                            className={`hidden lg:block text-sm font-medium ml-3 lg:opacity-100 duration-200`}
                          >
                            {t("Sign Out")}
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
