import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import LoadingBtn from "../../components/LoadingBtn";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Loader from "../../partials/Loader";
import LanguageSelectBar from "../../partials/banners/LanguageSelectBar";
import Spinner from "../../partials/Spinner";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

import { handleFileUpload } from "../../utils/FileUpload";

const imageTypes = ["JPG", "JPEG", "PNG"];

export default function CreateBanner(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const { auth } = useAuth();
  const user = auth?.user;

  const { t } = useTranslation();

  const [banner, setBanner] = useState({
    languages: [],
    type: "link",
    url: "",
    ordering: 0,
    isEnabled: true,
    isDraft: false,
  });
  const [languages, setLanguages] = useState({});

  // Params
  const { id } = useParams();

  const getLanguages = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.LANGS_URL, {
        signal: controller.signal,
      });

      setLanguages(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteImage = (langIdx, idx, field) => {
    // deleteImage(idx, mediaIdx, "medias");
    if (idx !== null) {
      formik.setFieldValue(
        `languages[${langIdx}].${field}`,
        formik.values.languages[langIdx][field].filter(
          (item, index) => index !== idx
        )
      );
    } else {
      formik.setFieldValue(`languages[${langIdx}].${field}`, "");
    }
  };

  const main = async () => {
    try {
      await getLanguages();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    main();
  }, []);

  const submitHandler = async () => {
    setIsSaving(true);
    try {
      if (formik.values.languages.length === 0) {
        toast.error("Please add at least one language");
      } else {
        await axiosPrivate.post(endpoints.BANNERS_URL, formik.values, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });

        toast.success("Create Successfully!");
      }

      setIsSaving(false);
    } catch (err) {
      console.log(err);

      toast.error("Error occurred!");

      if (!err?.response) {
        console.error("No Server res");
      } else {
        let error = err?.response.data.errors.msg;
        // setWarning(<Warning title="Invalid Token" />);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: banner.id,
      languages: banner.languages.map((item) => {
        return {
          title: item?.title,
          coverImg: item?.coverImg,
          s3BucketKey: item?.s3BucketKey,
          locale: item?.locale,
        };
      }),
      ordering: banner.ordering,
      type: banner.type,
      url: banner.url,
      isEnabled: banner.isEnabled,
      isDraft: banner.isDraft,
    },
    validationSchema: Yup.object({
      languages: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().optional(),
          coverImg: Yup.string().optional(),
          s3BucketKey: Yup.string().required(t("This field is required")),
        })
      ),
      type: Yup.string().required(t("This field is required")),
      url: Yup.string().when("type", (type, schema) => {
        if (type === "link") {
          return schema.required("This field is required");
        }
        return schema;
      }),
      ordering: Yup.number().required("This field is required"),
      isEnabled: Yup.boolean().required("This field is required"),
      isDraft: Yup.boolean().required("This field is required"),
    }),
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const copyToOtherLanguages = (idx, field) => {
    const value = formik.values.languages[idx][field];
    const languages = formik.values.languages;
    languages.forEach((item, i) => {
      if (i !== idx) {
        formik.setFieldValue(`languages[${i}].${field}`, value);
      }
    });

    toast.success("Copied to other languages!");
  };

  const renderLanguageForm = (item, idx) => {
    if (item.locale !== selectedLocale) return;
    return (
      <div key={item.locale}>
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:py-5">
          <label
            htmlFor="title"
            className="block text-sm font-medium  sm:mt-px sm:pt-2"
          >
            Title
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="title"
              name={`languages[${idx}].title`}
              rows={3}
              value={formik.values.languages[idx].title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-400 dark:border-gray-600 rounded-md dark:bg-gray-800 dark:text-white"
            />
            <p className="text-red-600 text-sm">
              {formik.errors.languages &&
              formik.touched.languages &&
              formik.errors.languages[idx]?.title
                ? `Error: ${formik.errors.languages[idx]?.title}`
                : null}
            </p>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-gray-200 py-5">
          <div className="flex justify-between items-center sm:block sm:space-y-4">
            <label
              htmlFor="coverImg"
              className="block text-sm font-medium  sm:mt-px sm:pt-2"
            >
              Cover image <span className="text-rose-500">*</span>
            </label>
            <button
              type="button"
              onClick={() => {
                copyToOtherLanguages(idx, "coverImg");
              }}
              className="btn btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              Copy to other language(s)
            </button>
          </div>

          <div className="mt-1 sm:mt-0 sm:col-span-2">
            {formik.values.languages[idx].coverImg &&
              (formik.values.languages[idx].coverImg === "loader" ? (
                <div className="relative h-24 w-full">
                  <Spinner />
                </div>
              ) : (
                <div className="relative">
                  <img
                    src={formik.values.languages[idx].coverImg}
                    alt="Cover Image"
                    className="w-full mb-2"
                  />
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      // TODO: Delete Cover Images
                      deleteImage(idx, null, "coverImg");
                    }}
                    className="group flex justify-center items-center absolute h-5 w-5 top-0 right-0 -mt-1 -mr-1 z-20"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="flex absolute h-4 w-4 z-10 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span className="relative inline-flex rounded-full h-5 w-5 bg-rose-500 group-hover:bg-rose-600"></span>
                  </button>
                </div>
              ))}
            <FileUploader
              multiple={false}
              handleChange={(file) =>
                handleFileUpload(
                  formik,
                  axiosPrivate,
                  file,
                  idx,
                  "coverImg",
                  "image"
                )
              }
              name={`languages[${idx}].coverImg`}
              classes="h-48 max-w-lg flex justify-center px-6 py-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
              types={imageTypes}
            />
            <p className="mt-2 text-sm text-gray-500">
              Optimal aspect ratio: 12:5{" "}
            </p>
            <p className="text-red-600 text-sm">
              {formik.errors.languages &&
              formik.touched.languages &&
              formik.errors.languages[idx]?.coverImg
                ? `Error: ${formik.errors.languages[idx]?.coverImg}`
                : null}
            </p>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) return <Loader />;
  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main className="text-black dark:text-white">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
            <form onSubmit={formik.handleSubmit}>
              <div className="max-w-5xl mx-auto flex flex-col lg:flex-row lg:space-x-8 xl:space-x-16 space-y-8 lg:space-y-0">
                {/* Sidebar */}
                <LanguageSelectBar
                  languages={languages}
                  selectedLocale={selectedLocale}
                  setSelectedLocale={setSelectedLocale}
                  formik={formik}
                />

                {/* Language */}
                <div className="w-full">
                  {/* Page content */}
                  <div>
                    <h3 className="text-lg leading-6 font-medium">
                      {t("Create Banner")}
                    </h3>
                  </div>
                  <div className="space-y-6 sm:space-y-5">
                    {formik.values.languages.map((item, idx) => {
                      return renderLanguageForm(item, idx);
                    })}
                  </div>
                </div>
              </div>

              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                {/*  Global Setting */}
                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div>
                    <h3 className="text-lg leading-6 font-medium">
                      Global Settings
                    </h3>
                  </div>
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                      <label
                        htmlFor="ordering"
                        className="block text-sm font-medium  sm:mt-px sm:pt-2"
                      >
                        Ordering <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="sm:col-span-2">
                          <div className="mt-4 space-y-4">
                            <div className="relative w-96">
                              <input
                                type="number"
                                placeholder="e.g. 10"
                                name="ordering"
                                value={formik.values.ordering}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-400 dark:border-gray-600 rounded-md dark:bg-gray-800 dark:text-white"
                              />
                            </div>

                            <p className="text-red-600 text-sm">
                              {formik.errors.ordering && formik.touched.ordering
                                ? `Error: ${formik.errors.ordering}`
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-5">
                      <label
                        htmlFor="type"
                        className="block text-sm font-medium  sm:mt-px sm:pt-2"
                      >
                        Type <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="sm:col-span-2">
                          <div className="mt-4 space-y-4">
                            <select
                              id="type"
                              name="type"
                              value={formik.values.type}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-400 dark:border-gray-600 rounded-md dark:bg-gray-800 dark:text-white"
                            >
                              <option value="link">Url</option>
                              <option value="blog">Blog</option>
                            </select>
                            <p className="text-red-600 text-sm">
                              {formik.errors.type && formik.touched.type
                                ? `Error: ${formik.errors.type}`
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {formik.values.type === "link" && (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="url"
                          className="block text-sm font-medium  sm:mt-px sm:pt-2"
                        >
                          URL <span className="text-rose-500">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="URL: https://example.com"
                          name="url"
                          value={formik.values.url}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="max-w-lg shadow-sm block w-full focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border border-gray-400 dark:border-gray-600 rounded-md dark:bg-gray-800 dark:text-white"
                        />
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <p className="text-red-600 text-sm">
                            {formik.errors.url && formik.touched.type
                              ? `Error: ${formik.errors.url}`
                              : null}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Admin Settings */}
                {user.role === "admin" && (
                  <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg leading-6 font-medium">
                        Admin Settings
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium sm:text-sm sm:"
                                id="label-notifications"
                              >
                                Publish Status{" "}
                                <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-gray-500">
                                Whether this banner is published
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <select
                                    id="isDraft"
                                    name="isDraft"
                                    value={formik.values.isDraft}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-400 dark:border-gray-600 rounded-md dark:bg-gray-800 dark:text-white"
                                  >
                                    <option value="true">Draft</option>
                                    <option value="false">Published</option>
                                  </select>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.isDraft &&
                                    formik.touched.isDraft
                                      ? `Error: ${formik.errors.isDraft}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium sm:text-sm sm:"
                                id="label-notifications"
                              >
                                Enable Status{" "}
                                <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-gray-500">
                                Whether this banner is visible to normal user
                                (User are not able to see this banner even if
                                they are the user)
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <select
                                    id="isEnabled"
                                    name="isEnabled"
                                    value={formik.values.isEnabled}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="max-w-lg block focus:ring-emerald-500 focus:border-emerald-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-400 dark:border-gray-600 rounded-md dark:bg-gray-800 dark:text-white"
                                  >
                                    <option value="true">Enabled</option>
                                    <option value="false">Disabled</option>
                                  </select>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.isEnabled &&
                                    formik.touched.isEnabled
                                      ? `Error: ${formik.errors.isEnabled}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="py-5">
                <div className="flex justify-end items-center space-x-4">
                  <Link
                    to={props.fallback || "/allbaners"}
                    className="bg-white py-2 px-4 border border-gray-400 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-black hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                  >
                    {t("Back")}
                  </Link>
                  <div>
                    {isSaving ? (
                      <LoadingBtn text="Saving" />
                    ) : Object.keys(formik.errors).length > 0 ? (
                      <button
                        type="button"
                        disabled
                        className="btn bg-emerald-400 text-white cursor-not-allowed"
                      >
                        {t("Create")}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                      >
                        {t("Create")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
            {/* Content */}
          </div>
        </main>
      </div>
    </div>
  );
}
