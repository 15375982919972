import { Link } from "react-router-dom";

export default function BannerTableItem({ banner }) {
  return (
    <Link to={`/allbanners/${banner.id}/edit`}>
      <img
        className="rounded-lg object-cover max-h-72 w-full" 
        src={banner?.coverImg ? banner.coverImg : ""}
        alt={banner.title}
      />
    </Link>
  );
}
