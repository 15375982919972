import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";
import endpoints from "../../api/endpoints";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import BottomAppBar from "../../partials/ui/BottomAppBar";
import RedeemModal from "../../partials/redeem/RedeemModal";

import SignInModal from "../../partials/auths/SignInModal";
import { toast } from "react-toastify";

export default function RedeemHome() {
  const { t } = useTranslation();

  const axiosTryPrivate = useAxiosTryPrivate();
  const controller = new AbortController();

  // Query Params
  const { code } = useParams();

  const [loading, setLoading] = useState(true);
  const [redeemCode, setRedeemCode] = useState(code);
  const [info, setInfo] = useState();
  const [redeemOpen, setRedeemOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [redeemInfo, setRedeemInfo] = useState();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth?.user;

  const redeem = async () => {
    try {
      setLoading(true);
      if (redeemCode) {
        // Check if debouncedSearchString
        const res = await axiosTryPrivate.post(
          `${endpoints.REDEEM_URL}/${redeemCode}`,
          {
            signal: controller.signal,
          }
        );

        setRedeemInfo(res?.data);
        setRedeemOpen(true);
      }
    } catch (error) {
      const errorMsg = error.response.data.error;

      if (errorMsg.code === "LOGIN_REQUIRED") {
        setLoginOpen(true);
      } else {
        toast.error(errorMsg.msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const getRedemptionInfo = async () => {
    try {
      if (redeemCode) {
        setLoading(true);
        // Check if debouncedSearchString
        const res = await axiosTryPrivate.get(
          `${endpoints.REDEEM_URL}/${redeemCode}`,
          {
            signal: controller.signal,
          }
        );

        setInfo(res?.data);
        setLoading(false);
      }
    } catch (error) {
      const errorMsg = error.response.data.error;

      if (errorMsg.code === "LOGIN_REQUIRED") {
        setLoginOpen(true);
      } else {
        toast.error(errorMsg.msg);
      }
    }
  };

  useEffect(() => {
    getRedemptionInfo();
  }, []);

  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar user={user} />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header title={info?.title || t("Redeem")} user={user} />

        {/* Redeem Modal */}
        <RedeemModal
          open={redeemOpen}
          setOpen={setRedeemOpen}
          redeemInfo={redeemInfo}
        />

        {/* Redeem Modal */}
        <SignInModal open={loginOpen} setOpen={setLoginOpen} />

        <main>
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto">
            {info !== null && info !== undefined && (
              <div className="dark:text-white relative max-w-2xl mx-auto space-y-4">
                <div className="sm:text-center">
                  {/* CoverImg */}
                  {info.coverImg && (
                    <img
                      src={info.coverImg}
                      alt="Cover"
                      className="w-full max-h-72 object-cover rounded-lg mb-4"
                    />
                  )}
                  <h2 className="text-sky-500 dark:text-sky-500 text-3xl text-left font-extrabold tracking-tight sm:text-4xl">
                    {info.title}
                  </h2>
                  <p className="mt-6 text-lg whitespace-pre-line text-left">
                    {info.description}
                  </p>
                </div>
                <div>
                  <div className="h3 leading-snug text-black dark:text-white font-bold mb-4">
                    {t("Coupon includes")}
                  </div>
                  <div
                    role="list"
                    className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2"
                  >
                    {info.plans &&
                      info.plans.length > 0 &&
                      info.plans.map((plan, idx) => (
                        <Link
                          to={`/practice/${plan.quiz.id}`}
                          target="_blank"
                          key={plan.id}
                          className="col-span-1 flex shadow-sm bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-md hover:scale-105 transition-all"
                        >
                          <div className="flex items-center justify-center h-full w-16 text-white text-lg font-extrabold rounded-l-md">
                            {idx + 1}
                          </div>
                          <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800 rounded-r-md truncate">
                            <div className="text-left flex-1 px-4 py-2 text-sm truncate">
                              <div className="font-medium text-black dark:text-white">
                                {plan.quiz.title} - {plan.title}
                              </div>
                              <p className="text-black dark:text-gray-400">
                                {plan.validForDays === -1
                                  ? t("Lifetime access")
                                  : `${plan.validForDays}${t(" days access")}`}
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
                <div>
                  {loading ? (
                    <button
                      disabled
                      className="cursor-not-allowed block w-full rounded-md border border-transparent px-5 py-3 bg-sky-500/20 text-base font-medium text-white shadowsm:px-10"
                    >
                      {`${t("Redeem")} ${info.code}`}...
                    </button>
                  ) : info.hasRedeemed ? (
                    <button
                      disabled
                      className="cursor-not-allowed block w-full rounded-md border border-transparent px-5 py-3 bg-sky-500/20 text-base font-medium text-white shadowsm:px-10"
                    >
                      {`${t("You have redeemed")} ${info.code}`}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        redeem();
                      }}
                      className="block w-full rounded-md border border-transparent px-5 py-3 bg-sky-500 text-base font-medium text-white shadow hover:bg-sky-400 sm:px-10"
                    >
                      {`${t("Redeem")} ${info.code}`}
                    </button>
                  )}
                </div>
              </div>
            )}

            {(info === null || info === undefined) && !code && (
              <div className="dark:text-white relative max-w-2xl mx-auto">
                <div className="sm:text-center">
                  <h2 className="text-2xl font-extrabold tracking-tight sm:text-4xl">
                    {t("Redeem")}
                  </h2>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    window.location.href = `/redeem/${redeemCode}`;
                  }}
                  className="mt-12 sm:mx-auto sm:max-w-lg sm:flex"
                >
                  <div className="min-w-0 flex-1">
                    <label htmlFor="cta-email" className="sr-only">
                      {t("Code")}
                    </label>
                    <input
                      id="code"
                      type="text"
                      defaultValue={redeemCode}
                      onChange={(e) => setRedeemCode(e.target.value)}
                      className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-black dark:text-white dark:bg-gray-800 dark:border-gray-600 shadow-sm"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-3">
                    <button
                      type="submit"
                      className="block w-full rounded-md border border-transparent px-5 py-3 bg-sky-500 text-base font-medium text-white shadow hover:bg-sky-400 sm:px-10"
                    >
                      {`${t("Submit")}`}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}
