import React from "react";
import EmptyProfile from "../../images/EmptyProfile.webp";

const RenderUserProfileAvatar = ({ user }) => {
  if (user?.avatar) {
    return (
      <img
        className={`w-14 h-14 sm:w-24 sm:h-24 rounded-full object-cover`}
        src={user.avatar}
        alt="User"
      />
    );
  } else {
    return (
      <img
        className={`w-14 h-14 sm:w-24 sm:h-24 rounded-full object-cover`}
        src={EmptyProfile}
        alt="User"
      />
    );
  }
};

export default RenderUserProfileAvatar;
