import React, { useState, useEffect } from "react";
import QuizTableItem from "./QuizTableItem";
import { useTranslation } from "react-i18next";

function QuizTable({
  selectedItems,
  handleSelectedItems,
  isFetching,
  quizzes,
  setThemeHandler,
  setUserHandler,
  deleteQuizHandler,
}) {
  const { t } = useTranslation();
  return (
    <div className="bg-white dark:bg-gray-800 dark:border-gray-600 dark:text-white shadow-lg rounded-sm border border-gray-200 relative">
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-gray-200 dark:text-white dark:bg-gray-800 dark:border-gray-600 border-b">
              <tr>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select all</span>
                      <input
                        className="form-checkbox"
                        type="checkbox"
                        onChange={handleSelectAll}
                      />
                    </label>
                  </div>
                </th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Title")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("User")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Type")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Themes")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <span className="sr-only">Menu</span>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="table-auto text-sm divide-y divide-gray-200">
              {quizzes.map((quiz) => {
                return (
                  <QuizTableItem
                    key={quiz.id}
                    quiz={quiz}
                    handleClick={() => handleSelectedItems(quiz.id)}
                    isChecked={selectedItems.includes(quiz.id)}
                    setThemeHandler={setThemeHandler}
                    setUserHandler={setUserHandler}
                    deleteQuizHandler={deleteQuizHandler}
                  />
                );
              })}
              {!isFetching && quizzes.length === 0 && (
                <tr>
                  <td colSpan="6">
                    <div className="col-span-12 flex items-center justify-center py-5 text-xl">
                      {/* Show Empty */}
                      {t("No Found")}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default QuizTable;
