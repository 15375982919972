import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import QuestionListItem from "../../partials/quizzes/QuestionListItem";
import { toast } from "react-toastify";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { handleFileUploadMin } from "../../utils/FileUpload";

import { useTranslation } from "react-i18next";

import Loader from "../../partials/Loader";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/quizzes/QuizDetailHeader";

import DeleteQuizModal from "../../partials/quizzes/DeleteQuizModal";
import DeleteSectionModal from "../../partials/quizzes/DeleteSectionModal";
import DeleteQuizSetModal from "../../partials/quizzes/DeleteQuizSetModal";
import EditQuizModal from "../../partials/quizzes/EditQuizModal";
import EditSectionModal from "../../partials/quizzes/EditSectionModal";
import EditQuizSetModal from "../../partials/quizzes/EditQuizSetModal";
import BatchImportQuestionModal from "../../partials/quizzes/BatchImportQuestionModal";
import ImportQuestionModal from "../../partials/quizzes/ImportQuestionModal";
import NewSectionModal from "../../partials/quizzes/NewSectionModal";
import NewQuizSetModal from "../../partials/quizzes/NewQuizSetModal";
import PageNotFound from "../error/PageNotFound";
import VisibilityStatus from "../../partials/quizzes/VisibilityStatus";
import DraftStatus from "../../partials/quizzes/DraftStatus";

const imageTypes = ["JPG", "JPEG", "PNG"];

function QuizDetail() {
  // Above is the original code
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);

  // Modal States
  const [importSetModalOpen, setImportSetModalOpen] = useState(false);
  const [batchImportModalOpen, setBatchImportModalOpen] = useState(false);
  const [newSectionModalOpen, setNewSectionModalOpen] = useState(false);
  const [newQuizSetModalOpen, setNewQuizSetModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editSectionModalOpen, setEditSectionModalOpen] = useState(false);
  const [editSetModalOpen, setEditSetModalOpen] = useState(false);
  const [deleteSectionModalOpen, setDeleteSectionModalOpen] = useState(false);
  const [deleteSetModalOpen, setDeleteSetModalOpen] = useState(false);

  const [editTitle, setEditTitle] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const titleInputRef = useRef();
  const descriptionRef = useRef();

  // i18n
  const { t } = useTranslation();

  // Get User Profile
  const [user, setUser] = useState();

  // Warning
  const { id } = useParams();
  const [quiz, setQuiz] = useState();
  const [oldQuiz, setOldQuiz] = useState();
  const [targetSection, setTargetSection] = useState({});
  const [targetSet, setTargetSet] = useState({});
  const [isImageLoading, setIsImageLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const init = async () => {
    try {
      // Quiz
      const resQuiz = await axiosPrivate.get(
        endpoints.QUESTIONSETS_URL + "/" + id,
        {
          signal: controller.signal,
        }
      );

      setQuiz(resQuiz.data);
      // User
      const res = await axiosPrivate.get(endpoints.PROFILE_URL, {
        signal: controller.signal,
      });

      setUser(res.data);
    } catch (error) {
      // Go to the new question
      navigate(`/quizzes/${id}`);
    }

    setLoading(false);
  };

  // Import Set
  const importSet = (section, set) => {
    setTargetSection(section);
    setTargetSet(set);
    setImportSetModalOpen(true);
  };

  // Edit Set
  const editSet = (section, set) => {
    setTargetSection(section);
    setTargetSet(set);
    setEditSetModalOpen(true);
  };

  // Delete Set Modal
  const deleteSet = (section, set) => {
    setTargetSection(section);
    setTargetSet(set);
    setDeleteSetModalOpen(true);
  };

  useEffect(() => {
    init();
  }, [id]);

  const renderAddBtn = () => {
    return (
      <button
        onClick={() => setNewSectionModalOpen(true)}
        tabIndex="-1"
        className="w-full h-20 border-4 border-dotted flex items-center justify-center px-3 py-2 border-transparent text-lg leading-4 font-medium rounded-md shadow-sm text-indigo-600 hover:text-white  dark:text-white border-indigo-600 hover:bg-indigo-600"
      >
        {t("New Section")}
      </button>
    );
  };

  const updateQuizField = async (obj) => {
    try {
      // Update quiz
      axiosPrivate.patch(endpoints.QUESTIONSETS_URL + "/" + id, obj);

      // Update state
      setQuiz({ ...quiz, ...obj });

      toast.success(t("Quiz updated"));
    } catch (error) {
      toast.error(t("Error updating quiz"));
    }
  };

  // Focus
  useEffect(() => {
    if (editTitle) {
      titleInputRef.current.focus();
    }
    if (editDescription) {
      descriptionRef.current.focus();
    }
  }, [editTitle, editDescription]);

  if (isLoading) return <Loader />;
  if (!quiz) {
    return <PageNotFound />;
  } else {
    return (
      <div className="flex overflow-hidden">
        {/* Sidebar */}
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        {/* Content area */}
        <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            setEditModalOpen={setEditModalOpen}
            quiz={quiz}
            user={user}
          />

          <main>
            <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-8 w-full">
              {/* Page content */}
              {/* Modal */}
              <EditQuizModal
                refetch={init}
                quiz={quiz}
                open={editModalOpen}
                setOpen={setEditModalOpen}
              />
              <DeleteQuizModal
                refetch={init}
                quiz={quiz}
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
              />
              <BatchImportQuestionModal
                refetch={init}
                quiz={quiz}
                section={targetSection}
                set={targetSet}
                open={batchImportModalOpen}
                setOpen={setBatchImportModalOpen}
              />
              <ImportQuestionModal
                refetch={init}
                quiz={quiz}
                section={targetSection}
                set={targetSet}
                open={importSetModalOpen}
                setOpen={setImportSetModalOpen}
              />
              <NewSectionModal
                refetch={init}
                quiz={quiz}
                open={newSectionModalOpen}
                setOpen={setNewSectionModalOpen}
              />
              <NewQuizSetModal
                refetch={init}
                quiz={quiz}
                section={targetSection}
                open={newQuizSetModalOpen}
                setOpen={setNewQuizSetModalOpen}
              />
              <EditSectionModal
                refetch={init}
                quiz={quiz}
                section={targetSection}
                open={editSectionModalOpen}
                setOpen={setEditSectionModalOpen}
              />
              <EditQuizSetModal
                refetch={init}
                quiz={quiz}
                section={targetSection}
                set={targetSet}
                open={editSetModalOpen}
                setOpen={setEditSetModalOpen}
              />
              <DeleteSectionModal
                refetch={init}
                quiz={quiz}
                section={targetSection}
                open={deleteSectionModalOpen}
                setOpen={setDeleteSectionModalOpen}
              />
              <DeleteQuizSetModal
                refetch={init}
                quiz={quiz}
                section={targetSection}
                set={targetSet}
                open={deleteSetModalOpen}
                setOpen={setDeleteSetModalOpen}
              />
              {/* CoverImg */}
              {quiz.coverImg && (
                <div className="relative flex items-center h-80 justify-center">
                  <div className="aspect-square h-72">
                    <img
                      src={quiz.coverImg}
                      alt="Cover"
                      className="h-full w-full object-cover shadow-lg"
                    />
                  </div>
                  {/* Blurred background image */}
                  <img
                    src={quiz.coverImg}
                    alt="Cover"
                    className="-z-10 absolute w-full blur-sm h-80 object-cover brightness-50"
                  />
                  <div className="absolute right-5 top-5 flex items-center space-x-2">
                    <label
                      htmlFor="coverImgUpload"
                      className="cursor-pointer w-fit flex whitespace-nowrap gap-x-2 items-center rounded-md px-4 py-1 text-sm font-medium text-white bg-black"
                    >
                      {t("Change Image")}
                    </label>
                    <button
                      type="button"
                      onClick={async (e) => {
                        e.preventDefault();
                        // Update Quiz Field
                        await updateQuizField({
                          s3BucketKey: "",
                          coverImg: "",
                        });
                      }}
                      className="cursor-pointer w-fit flex whitespace-nowrap gap-x-2 items-center rounded-md px-4 py-1 text-sm font-medium text-white bg-rose-600"
                    >
                      {t("Remove Image")}
                    </button>
                  </div>
                </div>
              )}

              <input
                id="coverImgUpload"
                type="file"
                onChange={async (event) => {
                  const meta = await handleFileUploadMin(
                    axiosPrivate,
                    event.target.files[0],
                    "coverImg",
                    "image"
                  );

                  // Update Quiz Field
                  await updateQuizField({
                    s3BucketKey: meta.s3BucketKey,
                    coverImg: meta.url,
                  });
                }}
                multiple={false}
                types={imageTypes}
                className="hidden"
              />

              {/* Content */}
              <div className="mt-4">
                <div className="flex items-center justify-between space-x-4">
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setEditModalOpen(true);
                    }}
                    className="cursor-pointer flex items-center gap-x-2 text-sm text-gray-500  max-w-fit whitespace-nowrap"
                  >
                    <VisibilityStatus status={quiz.visibility} />
                    <DraftStatus status={quiz.isDraft} />
                    {quiz.isPremium && (
                      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium text-white bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
                        {t("Premium Quiz")}
                      </span>
                    )}
                  </div>
                  <div className="flex items-center space-x-2 overflow-x-scroll"></div>
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setEditTitle(true);
                }}
                className="mb-4 flex justify-between items-center"
              >
                {/* Title */}
                <h1 className="w-full flex items-center text-2xl md:text-3xl text-black dark:text-white font-bold">
                  <input
                    ref={titleInputRef}
                    value={quiz.title}
                    disabled={!editTitle}
                    placeholder={"Aa..."}
                    onChange={(e) => {
                      setQuiz({ ...quiz, title: e.target.value });
                    }}
                    className="w-full bg-transparent border-transparent border-none focus:outline-none appearance-none"
                  />
                </h1>
                {/* Menu */}
                <div className="flex items-center space-x-2">
                  {!quiz.coverImg && (
                    <label
                      htmlFor="coverImgUpload"
                      className="cursor-pointer w-fit flex whitespace-nowrap gap-x-2 items-center rounded-md px-4 py-1 text-sm font-medium text-white bg-black"
                    >
                      {t("Add Cover Image")}
                    </label>
                  )}

                  {/* Edit */}
                  {!editTitle ? (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditTitle(true);
                        setOldQuiz(quiz);
                      }}
                      className="w-fit flex whitespace-nowrap gap-x-2 items-center rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 dark:text-white dark:hover:bg-gray-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </button>
                  ) : (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={(e) => {
                          setEditTitle(false);
                          updateQuizField({ title: quiz.title });
                        }}
                        className="w-fit flex whitespace-nowrap gap-x-2 items-center rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 dark:text-white dark:hover:bg-gray-800"
                      >
                        {t("Save")}
                      </button>
                      <button
                        type="button"
                        onClick={(e) => {
                          setEditTitle(false);

                          // Reset
                          setQuiz({ ...quiz, title: oldQuiz.title });
                        }}
                        className="w-fit flex whitespace-nowrap gap-x-2 items-center rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 dark:text-white dark:hover:bg-gray-800"
                      >
                        {t("Cancel")}
                      </button>
                    </div>
                  )}

                  {/* Import */}
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setBatchImportModalOpen(true);
                    }}
                    className="inline-flex gap-x-2 items-center w-full rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 dark:text-white dark:hover:bg-gray-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                      />
                    </svg>
                  </button>

                  {/* Delete */}
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteModalOpen(true);
                    }}
                    className="inline-flex gap-x-2 items-center w-full rounded-md px-4 py-2 text-sm font-medium text-rose-600 hover:bg-gray-100 dark:text-rose-600 dark:hover:bg-gray-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </div>
              </form>
              <hr className="mb-6 border-t border-gray-200" />
              {/* The Role */}
              <div>
                <div className="flex justify-between items-center">
                  <h2 className="text-xl leading-snug text-black dark:text-white font-bold mb-2">
                    {t("Description")}
                  </h2>
                  {/* Edit */}
                  {!editDescription ? (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditDescription(true);
                        setOldQuiz(quiz);
                      }}
                      className="w-fit flex whitespace-nowrap gap-x-2 items-center rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 dark:text-white dark:hover:bg-gray-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </button>
                  ) : (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={(e) => {
                          setEditDescription(false);
                          updateQuizField({ description: quiz.description });
                        }}
                        className="w-fit flex whitespace-nowrap gap-x-2 items-center rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 dark:text-white dark:hover:bg-gray-800"
                      >
                        {t("Save")}
                      </button>
                      <button
                        type="button"
                        onClick={(e) => {
                          setEditDescription(false);

                          // Reset
                          setQuiz({
                            ...quiz,
                            description: oldQuiz.description,
                          });
                        }}
                        className="w-fit flex whitespace-nowrap gap-x-2 items-center rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 dark:text-white dark:hover:bg-gray-800"
                      >
                        {t("Cancel")}
                      </button>
                    </div>
                  )}
                </div>
                <div className="text-black dark:text-white space-y-6 whitespace-pre-line">
                  <textarea
                    ref={descriptionRef}
                    value={quiz.description}
                    disabled={!editDescription}
                    placeholder={t("No description")}
                    onChange={(e) => {
                      setQuiz({ ...quiz, description: e.target.value });
                    }}
                    rows={5}
                    className="p-0 h-full border-transparent focus:border-transparent focus:ring-0 appearance-none w-full relative text-gray-900 dark:text-white bg-transparent resize-none"
                  />
                </div>
              </div>
              <hr className="my-6 border-t border-gray-200" />
              {/* Questions */}
              <div>
                <div className="space-y-2">
                  {quiz.sections.map((section, idx) => {
                    return (
                      <div key={idx} className="space-y-4">
                        <div className="flex justify-between items-center">
                          <h2 className="text-xl leading-snug text-black dark:text-white font-bold">
                            {section.title}{" "}
                            {section.count === 0 && `(${t("Empty")})`}
                            {section.count > 0 && `(${section.count})`}
                          </h2>

                          <div className="flex items-center space-x-2">
                            {/* Edit */}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setTargetSection(section);
                                setEditSectionModalOpen(true);
                              }}
                              className="px-2 py-1 text-gray-500 hover:text-black dark:text-white whitespace-nowrap hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                              </svg>
                            </button>
                            {/* Delete */}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setTargetSection(section);
                                setDeleteSectionModalOpen(true);
                              }}
                              className="px-2 py-1 text-rose-500 hover:text-rose-600 dark:text-rose-600 whitespace-nowrap hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>

                        {section.sets.length > 0 &&
                          section.sets.map((set, idx) => (
                            <QuestionListItem
                              key={idx}
                              quiz={quiz}
                              set={set}
                              section={section}
                              idx={idx}
                              importSet={importSet}
                              editSet={editSet}
                              deleteSet={deleteSet}
                            />
                          ))}
                        <div className="w-full flex flex-col items-center justify-center">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setTargetSection(section);
                              setNewQuizSetModalOpen(true);
                            }}
                            className="inline-flex items-center px-4 py-1 text-sm font-medium border border-transparent rounded-full shadow-sm text-white bg-sky-600 hover:bg-sky-700"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                            {t("New Quiz Set")}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="w-full flex flex-col items-center justify-center mt-5">
                  {renderAddBtn()}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default QuizDetail;
