import React, { useEffect, useState } from "react";

import UserMenu from "../components/DropdownProfile";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SignInModal from "../partials/auths/SignInModal";
import { ReactComponent as Logo } from "../images/logo/Logo.svg";

function Header(props) {
  // i18n
  const { t, i18n } = useTranslation();
  const [title, setTitle] = useState(props.title);
  const [open, setOpen] = useState(false);
  const [backPath, setBackPath] = useState(props?.backPath);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  const location = useLocation();

  useEffect(() => {
    if (!props?.backPath) {
      // Get URL from browser
      const path = location.pathname;

      // Set back path
      switch (path) {
        case "/":
          // Set Path to null
          setBackPath();
          break;
        case "/search":
          // Set Path to null
          setBackPath();
          break;
        case "/library":
          setBackPath();
          break;
        case "/signin":
          setBackPath();
          break;
        case "/signup":
          setBackPath();
          break;
        case "/reset-password":
          setBackPath();
          break;
        default:
          // Set Path to /
          setBackPath("/");
      }
    }
  }, [location]);

  return (
    <div>
      {/* Redeem Modal */}
      <SignInModal open={open} setOpen={setOpen} />
      <header className="lg:left-[256px] md:left-[80px] duration-0 fixed top-0 left-0 right-0 bg-white dark:bg-black backdrop-blur-xl dark:backdrop-brightness-75 transition-all z-30">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 -mb-px">
            {/* Header: Left side */}
            <div className="flex items-center space-x-4">
              {backPath === "/" && (
                <Link to="/">
                  <Logo className="h-8 w-8" />
                </Link>
              )}

              {backPath && backPath !== "/" && (
                <Link
                  to={backPath}
                  className="inline-flex justify-center w-full rounded-md px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-white dark:hover:text-gray-100 whitespace-nowrap"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                    />
                  </svg>
                </Link>
              )}
            </div>

            {/* Header: Right side */}
            <div className="flex items-center space-x-4">
              {/* Other btn */}
              {props.rightBtn && props.rightBtn}

              {/* User */}
              {props.user !== undefined && props.user !== null ? (
                <UserMenu align="right" user={props.user} />
              ) : (
                <button
                  onClick={() => {
                    setOpen(true);
                  }}
                  state={{ from: location }}
                  className="inline-flex justify-center w-full rounded-md px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-white dark:hover:text-gray-100 whitespace-nowrap"
                >
                  {t("Sign In")}
                </button>
              )}
            </div>
          </div>
        </div>
        {/* Dynamic Title */}
        <HelmetProvider>
          <Helmet>{title && <title>{t(title)} | Practtice</title>}</Helmet>
        </HelmetProvider>
      </header>
    </div>
  );
}

export default Header;
