import { Link } from "react-router-dom";

export default function ThemeTableItem({ theme, isLoading }) {
  if (isLoading) {
    return (
      <div className="h-20 shadow-md rounded-md animate-pulse bg-gray-600"></div>
    );
  } else {
    return (
      <Link to={`/allthemes/${theme.id}/edit`}>
        <div className="flex items-center gap-x-4 shadow-md rounded-md px-4 py-2 dark:text-white">
          {theme?.coverImg && (
            <div className="aspect-square h-14">
              <img
                className="h-full w-full object-cover rounded-lg"
                src={theme?.coverImg ? theme.coverImg : ""}
                alt={theme.title}
              />
            </div>
          )}
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
            {theme.value}
          </span>{" "}
          {theme?.title}
        </div>
      </Link>
    );
  }
}
