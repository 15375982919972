import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDebouncedValue, usePagination } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import useAxiosTryPrivate from "../../hooks/useAxiosTryPrivate";
import endpoints from "../../api/endpoints";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import PaginationNumeric from "../../components/PaginationNumeric";
import QuizTable from "../../partials/quizzes/QuizTable";
import BottomAppBar from "../../partials/ui/BottomAppBar";

function ThemeHome() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const axiosTryPrivate = useAxiosTryPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth?.user;

  // Query Params
  const { name } = useParams();

  // Get Quizs
  const [quizzes, setQuizzes] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [page, onChange] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const pagination = usePagination({ total: totalPage, page, onChange });
  const [theme, setTheme] = useState({});

  // Render Quizs
  const renderQuizs = async () => {
    try {
      const res = await axiosTryPrivate.get(
        `${endpoints.EXPLORE_URL}/themes/${name}/quizzes`,
        {
          signal: controller.signal,
        }
      );

      setQuizzes(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const renderTheme = async () => {
    try {
      // Check if debouncedSearchString
      const res = await axiosTryPrivate.get(`${endpoints.EXPLORE_URL}/themes`, {
        signal: controller.signal,
      });

      // Get theme.value === name
      const theme = res?.data.find((theme) => theme.value === name);

      setTheme(theme);
    } catch (error) {
      console.error(error);
    }
  };

  const main = async () => {
    try {
      setLoading(true);
      await renderTheme();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    main();
  }, []);

  useEffect(() => {
    // Render Quizs
    renderQuizs();
  }, [debouncedSearchString, page]);

  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        <main>
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto">
            {/* <WelcomeTheme user={user} /> */}

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-black dark:text-white font-bold">
                  {theme?.title}
                </h1>
              </div>
            </div>

            {/* Table */}
            <QuizTable
              quizzes={quizzes}
              isLoading={isLoading}
              showStatus={false}
            />

            {quizzes.length === 0 && (
              <div className="text-center py-10">
                <p className="mt-1 text-sm text-gray-500">{t("No result")}.</p>
              </div>
            )}

            {/* Pagination */}
            <div className="mt-8">
              <PaginationNumeric
                pagination={pagination}
                page={page}
                onChange={onChange}
              />
            </div>
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}

export default ThemeHome;
