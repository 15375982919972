import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const BottomAppBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="pb-safe md:hidden fixed bottom-0 left-0 right-0 bg-white dark:bg-black">
      <nav className="flex justify-around mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 md:max-w-4xl md:px-8">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          className="group h-full w-full flex flex-col items-center py-3 text-black dark:text-white"
        >
          <span className="material-icons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 group-hover:scale-110 transition-all"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`${pathname === "/" && "text-emerald-500"}`}
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </span>
          <span className={`${pathname === "/" && "text-emerald-500"} text-xs`}>
            {t("Home")}
          </span>
        </Link>
        <Link
          to="/search"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          className="group h-full w-full flex flex-col items-center py-3 text-black dark:text-white"
        >
          <span className="material-icons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 group-hover:scale-110 transition-all"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`${
                  pathname.includes("/search") && "text-emerald-500"
                }`}
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </span>
          <span
            className={`${
              pathname.includes("/search") && "text-emerald-500"
            } text-xs`}
          >
            {t("Search")}
          </span>
        </Link>
        <Link
          to="/library"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          className="group h-full w-full flex flex-col items-center py-3 text-black dark:text-white"
        >
          <span className="material-icons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 group-hover:scale-110 transition-all"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`${
                  pathname.includes("/library") && "text-emerald-500"
                }`}
                d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125z"
              />
            </svg>
          </span>
          <span
            className={`${
              pathname.includes("/library") && "text-emerald-500"
            } text-xs`}
          >
            {t("Library")}
          </span>
        </Link>
      </nav>
    </div>
  );
};

export default BottomAppBar;
