import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import { useTranslation } from "react-i18next";

import Loader from "../../partials/Loader";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import PageNotFound from "../error/PageNotFound";

function QuizMonetize() {
  // Above is the original code
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);

  // i18n
  const { t } = useTranslation();

  // Get User Profile
  const [user, setUser] = useState();
  const [isSaving, setIsSaving] = useState(false);

  // Warning
  const { id } = useParams();
  const [quiz, setQuiz] = useState({
    id: "",
    plans: [{ title: "", price: "", validForDays: "" }],
    isPremium: false,
  });

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const plans = [
    {
      id: "123321",
      title: "7 Days Pass",
      price: 9.99,
      validForDays: 7,
    },
    {
      id: "123213",
      title: "Lifetime Access Pass",
      price: 19.99,
      validForDays: -1,
    },
  ];

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setIsSaving(true);
    try {
      await axiosPrivate.patch(
        endpoints.STORIES_URL + `/${formik.values.id}`,
        formik.values,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      console.log(values);

      toast.success("Edit Successfully!");

      setIsSaving(false);
    } catch (err) {
      console.log(err);

      toast.error("Error occurred!");
    } finally {
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: quiz.id,
      plans: quiz?.plans?.map((item) => {
        return {
          title: item.title || "",
          price: item.price || "",
          validForDays: item.validForDays || "",
        };
      }),
      isPremium: quiz.isPremium,
    },
    validationSchema: Yup.object({
      languages: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required("This field is required"),
          address: Yup.string().required("This field is required"),
          description: Yup.string().optional(),
          transcript: Yup.string().optional(),
          audioUrl: Yup.string().required("This field is required"),
          coverImg: Yup.string().required("This field is required"),
          medias: Yup.array().of(Yup.string()).optional(),
          locale: Yup.string().required("This field is required"),
        })
      ),
      owner: Yup.string().required("This field is required"),
      location: Yup.object().shape({
        coordinates: Yup.array()
          .of(Yup.number())
          .required("This field is required")
          .min(2, "This field is required"),
      }),
      visibility: Yup.number()
        .integer()
        .min(0)
        .max(2)
        .required("This field is required"),
      themes: Yup.array().of(Yup.string()).optional(),
      // tags: Yup.array().of(Yup.string()).optional(),
      isEnabled: Yup.boolean().required("This field is required"),
      isDraft: Yup.boolean().required("This field is required"),
      isPremium: Yup.boolean().required("This field is required"),
    }),
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const init = async () => {
    try {
      // Quiz
      const resQuiz = await axiosPrivate.get(
        endpoints.QUESTIONSETS_URL + "/" + id,
        {
          signal: controller.signal,
        }
      );

      setQuiz(resQuiz.data);
      // User
      const res = await axiosPrivate.get(endpoints.PROFILE_URL, {
        signal: controller.signal,
      });

      setUser(res.data);
    } catch (error) {
      // Go to the new question
      navigate(`/quizzes/${id}`);
    }

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, [id]);

  if (isLoading) return <Loader />;
  if (!quiz) {
    return <PageNotFound />;
  } else {
    return (
      <div className="flex overflow-hidden">
        {/* Sidebar */}
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        {/* Content area */}
        <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            user={user}
          />

          <main>
            <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-8 w-full">
              <header className="mb-4 flex justify-between items-center">
                {/* Title */}
                <h1 className="text-2xl md:text-3xl text-black dark:text-white font-bold">
                  {t("Monetize")} - {quiz.title}
                </h1>
              </header>

              <hr className="mb-6 border-t border-gray-200" />

              <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                      <h3 className="text-lg font-medium leading-6 text-black dark:text-white">
                        {t("Global Settings")}
                      </h3>
                      <p className="mt-1 text-sm text-black dark:text-white">
                        Decide which communications you'd like to receive and
                        how.
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <form action="#" method="POST">
                      <div className="shadow overflow-hidden bg-white dark:bg-gray-900 sm:rounded-md">
                        <div className="px-4 py-5 space-y-6 sm:p-6">
                          <fieldset>
                            <div>
                              <legend className="text-base font-medium text-black dark:text-white">
                                Set Quiz to premium
                              </legend>
                              <p className="text-sm text-gray-500">
                                This will set the quiz to premium and will only
                                be available to user who purchased the quiz.
                              </p>
                            </div>
                            <div className="mt-4 space-y-4">
                              <div className="flex items-center">
                                <input
                                  id="push-everything"
                                  name="push-notifications"
                                  type="radio"
                                  className="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 dark:border-gray-600 dark:bg-gray-800"
                                />
                                <label
                                  htmlFor="push-everything"
                                  className="ml-3 block text-sm font-medium text-black dark:text-white"
                                >
                                  Premium
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="push-email"
                                  name="push-notifications"
                                  type="radio"
                                  className="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 dark:border-gray-600 dark:bg-gray-800"
                                />
                                <label
                                  htmlFor="push-email"
                                  className="ml-3 block text-sm font-medium text-black dark:text-white"
                                >
                                  Free
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset>
                            <div>
                              <legend className="text-base font-medium text-black dark:text-white">
                                Free Access Sections
                              </legend>
                              <p className="text-sm text-gray-500">
                                You can set some quiz sections to be free access
                                for users to try out.
                              </p>
                            </div>

                            <div className="mt-4 space-y-4">
                              <div className="flex items-start">
                                <div className="flex items-center h-5">
                                  <input
                                    id="comments"
                                    name="comments"
                                    type="checkbox"
                                    className="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 dark:border-gray-600 dark:bg-gray-800 rounded"
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="comments"
                                    className="font-medium text-black dark:text-white"
                                  >
                                    Section 1
                                  </label>
                                  <p className="text-gray-500">
                                    10 quizzes under this section
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-start">
                                <div className="flex items-center h-5">
                                  <input
                                    id="candidates"
                                    name="candidates"
                                    type="checkbox"
                                    className="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 dark:border-gray-600 dark:bg-gray-800 rounded"
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="candidates"
                                    className="font-medium text-black dark:text-white"
                                  >
                                    Section 2
                                  </label>
                                  <p className="text-gray-500">
                                    10 quizzes under this section
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-start">
                                <div className="flex items-center h-5">
                                  <input
                                    id="offers"
                                    name="offers"
                                    type="checkbox"
                                    className="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 dark:border-gray-600 dark:bg-gray-800 rounded"
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="offers"
                                    className="font-medium text-black dark:text-white"
                                  >
                                    Section 3
                                  </label>
                                  <p className="text-gray-500">
                                    10 quizzes under this section
                                  </p>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="px-4 py-3 text-right sm:px-6">
                          <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                      <h3 className="text-lg font-medium leading-6 text-black dark:text-white">
                        Plans
                      </h3>
                      <p className="mt-1 text-sm text-black dark:text-white">
                        Set the plans for the quiz.
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <form action="#" method="POST">
                      <div className="shadow overflow-hidden bg-white dark:bg-gray-900 sm:rounded-md">
                        <div className="px-4 py-5 space-y-6 sm:p-6">
                          <div className="px-4 py-5 sm:p-6 space-y-8">
                            <ul
                              role="list"
                              className="-my-5 divide-y divide-gray-200"
                            >
                              {plans.map((plan, idx) => (
                                <li
                                  key={plan.id}
                                  className="py-4 text-black dark:text-white"
                                >
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                      <span className="h1">{idx + 1}</span>
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium truncate">
                                        {plan.title}
                                      </p>
                                      <p className="text-sm truncate">
                                        {"HKD$" + plan.price} for{" "}
                                        {plan.validForDays !== -1
                                          ? `${plan.validForDays} days`
                                          : "lifetime access"}
                                      </p>
                                    </div>
                                    <div className="space-x-2">
                                      <button
                                        type="button"
                                        className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-600 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                      >
                                        Edit
                                      </button>
                                      <button
                                        type="button"
                                        className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-rose-700 text-sm leading-5 font-medium rounded-full text-white bg-rose-600 hover:bg-rose-700"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>

                            {/* New Plan */}

                            <div className="rounded-md ring-2 ring-black dark:ring-white p-4">
                              <legend className="text-base font-medium text-black dark:text-white">
                                Create new plan
                              </legend>
                              <div className="grid md:grid-cols-8 gap-3">
                                <div className="col-span-4">
                                  <label
                                    htmlFor="city"
                                    className="block text-sm font-medium text-black dark:text-white"
                                  >
                                    Plan Title
                                  </label>
                                  <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    className="mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:border-gray-600 dark:text-white dark:bg-gray-800 rounded-md"
                                  />
                                </div>

                                <div className="col-span-2">
                                  <label
                                    htmlFor="state"
                                    className="block text-sm font-medium text-black dark:text-white"
                                  >
                                    Price
                                  </label>
                                  <input
                                    type="text"
                                    name="price"
                                    id="price"
                                    className="mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:border-gray-600 dark:text-white dark:bg-gray-800 rounded-md"
                                  />
                                </div>

                                <div className="col-span-2">
                                  <label
                                    htmlFor="state"
                                    className="block text-sm font-medium text-black dark:text-white"
                                  >
                                    Valid for day(s)
                                  </label>
                                  <input
                                    type="text"
                                    name="price"
                                    id="price"
                                    className="mt-1 focus:ring-emerald-500 focus:border-emerald-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:border-gray-600 dark:text-white dark:bg-gray-800 rounded-md"
                                  />
                                  <p className="mt-1 text-xs text-gray-500">
                                    Put "-1" for unlimited days
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-3 text-right sm:px-6">
                          <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default QuizMonetize;
