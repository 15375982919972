import { useNavigate, Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

import { ReactComponent as Logo } from "../../images/logo/Logo.svg";
import NotFoundImage from "../../images/404-illustration.svg";
import useLogout from "../../hooks/useLogout";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import BottomAppBar from "../../partials/ui/BottomAppBar";
import { useTranslation } from "react-i18next";

function Signout() {
  // i18n
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();
  const from = location.state?.from?.pathname || "/";

  const signOut = async () => {
    await logout();

    // Return to previous page
    navigate(from, { replace: true });
  };

  useEffect(() => {
    signOut();
  }, []);

  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header title="Sign Out" />

        <main>
          <div className="pt-24 pb-28 px-4 sm:px-6 lg:px-8 py-20 max-w-md mx-auto space-y-10 text-black dark:text-white">
            <div className="text-center px-4">
              <div className="inline-flex mb-8">
                <img
                  src={NotFoundImage}
                  width="176"
                  height="176"
                  alt="404 illustration"
                />
              </div>
              <div className="mb-6">{t("You are logged out!")}</div>
              <Link
                to="/"
                className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
              >
                {t("Back to home")}
              </Link>
            </div>
          </div>

          {/* Bottom App Nav bar */}
          <BottomAppBar />
        </main>
      </div>
    </div>
  );
}

export default Signout;
